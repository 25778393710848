import {ARHIJoinState} from '../rootReducer';
import {createSelector} from 'reselect';
import {CommonPPCConfig} from './types';

export const selectFeatureToggleData = (state: ARHIJoinState) => state.featureToggles;
export const selectFeatureToggles = (state: ARHIJoinState) => selectFeatureToggleData(state).featureToggles;

export const getLoadingFeatureToggles = (state: ARHIJoinState) => state.featureToggles.loadingFeatureToggles;
export const getFetchedFeatureToggles = (state: ARHIJoinState) => state.featureToggles.fetchedFeatureToggles;
export const getFeatureTogglesError = (state: ARHIJoinState) => state.featureToggles.error;
export const selectIsArhiPPCEffective = (state: ARHIJoinState) => selectFeatureToggles(state)?.isArhiPPCEffective;
export const selectShowArhiPPCInformation = (state: ARHIJoinState) => selectFeatureToggles(state)?.showArhiPPCInformation;

export const selectUseContinuousCover = (state: ARHIJoinState) => selectFeatureToggles(state)?.useContinuousCover || false;
export const selectCommonPPCConfig = createSelector<ARHIJoinState, boolean, boolean, boolean | undefined, boolean | undefined, boolean | undefined, CommonPPCConfig>(
  getLoadingFeatureToggles,
  getFetchedFeatureToggles,
  getFeatureTogglesError,
  selectIsArhiPPCEffective,
  selectShowArhiPPCInformation,
  (loadingFeatures, fetchedFeatureToggles, featureToggleError, isArhiPPCEffective, showArhiPPCInformation) => ({
    loadingFeatureToggles: loadingFeatures,
    fetchedFeatureToggles: fetchedFeatureToggles,
    error: featureToggleError,
    isArhiPPCEffective: Boolean(isArhiPPCEffective),
    showArhiPPCInformation: Boolean(showArhiPPCInformation),
    showLoader: loadingFeatures && !fetchedFeatureToggles && !featureToggleError
  })
);
