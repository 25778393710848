import type {Person, PreviousFundDetails, Session, Dependant as SessionDependant, ContinuousCoverType} from '@nib/types-session-api';
import {Gender, Title, Selected} from '@nib/phi-constants';
import {format} from 'date-fns';
import {produce} from 'immer';
import {DependantValues, FamilyDetailsValues} from '../../../components/Forms/FamilyDetails/types';
import {SelectedToNullableBoolean} from '../../../components/Forms/utils';
import {Dictionary} from '../../../types/common';
import {ARHIPageList, ARHIPageIndex, ISO_DATE_FORMAT} from '../../../constants';
import {STUDENT_DEPENDANT_MIN_AGE, STUDENT_EDUCATION_INSTITUTION, DEPENDANT_MAX_AGE} from '../../../components/Forms/FamilyDetails/constants';
import {getAge} from '@nib/form-fields';

export const mapPartnerToSession = (values: FamilyDetailsValues): Person => ({
  title: Title[values.partnerTitle || ''] || null,
  firstName: values.partnerFirstName || null,
  lastName: values.partnerLastName || null,
  gender: Gender[values.partnerSex || ''] || null,
  // If dob is empty it be should be defaulted so that pricing observer can watch values correctly.
  dateOfBirth: values.partnerDateOfBirth || null,
  email: values.partnerEmail || null,
  mobile: values.partnerPhone || null
});

const mapDependantPreviousFundToSession = (dependant: DependantValues, funds): PreviousFundDetails | void => {
  if (dependant.childTransferringFunds !== 'YesDifferentFund') {
    return;
  }
  return {
    code: funds ? funds[dependant['childTransferringFunds--currentProvider'] || ''] : null,
    description: dependant['childTransferringFunds--currentProvider'] || null,
    policyNumber: dependant['childTransferringFunds--memberNumber'] || null,
    ageBasedDiscountAssessmentDate: format(new Date(), ISO_DATE_FORMAT),
    previouslyHadHealthInsurance: null // not used
  };
};

export const mapDependantToSession = (dependant: DependantValues, funds: Dictionary<string>): SessionDependant => {
  const currentDate = format(new Date(), ISO_DATE_FORMAT);
  const dependantAge = dependant.childDateOfBirth ? getAge(dependant.childDateOfBirth, currentDate) : null;
  const isDependantAgeBetweenStudentAgeRange = dependantAge ? dependantAge >= STUDENT_DEPENDANT_MIN_AGE && dependantAge < DEPENDANT_MAX_AGE : null;
  const isDependantAStudent = dependant.isStudentDependant === Selected.yes && isDependantAgeBetweenStudentAgeRange;
  const previousFundDetails = mapDependantPreviousFundToSession(dependant, funds);
  const sessionDependant: SessionDependant = {
    title: Title[dependant.childTitle || ''] || null,
    firstName: dependant.childFirstName || null,
    lastName: dependant.childLastName || null,
    gender: Gender[dependant.childSexOf || ''] || null,
    dateOfBirth: dependant.childDateOfBirth || null,
    dependantTransferringFunds: dependant.childTransferringFunds || null,
    isStudentDependant: (isDependantAgeBetweenStudentAgeRange && dependant.isStudentDependant) || null,
    graduationDate: (isDependantAStudent && dependant.graduationDate) || null,
    educationInstitution: isDependantAStudent ? STUDENT_EDUCATION_INSTITUTION : null
  };

  if (previousFundDetails) {
    sessionDependant.dependantPreviousFundDetails = previousFundDetails;
  }

  return sessionDependant;
};

export const mapPartnerPreviousFundToSession = (values?: FamilyDetailsValues, funds?: Dictionary<string>): PreviousFundDetails => {
  const previousFund: PreviousFundDetails = {
    ageBasedDiscountAssessmentDate: null,
    code: null,
    description: null,
    policyNumber: null,
    previouslyHadHealthInsurance: null,
    continuousCover: null
  };

  if (values) {
    if (values.partnerTransferringFunds === Selected.yes) {
      previousFund.policyNumber = values['partnerTransferringFunds--memberNumber'];
      previousFund.code = funds ? funds[values['partnerTransferringFunds--currentProvider'] || ''] : null;
      previousFund.ageBasedDiscountAssessmentDate = format(new Date(), ISO_DATE_FORMAT);
      previousFund.previouslyHadHealthInsurance = SelectedToNullableBoolean(values['partnerTransferringFunds'] || Selected.unselected) || null;
      previousFund.description = values['partnerTransferringFunds--currentProvider'] || '';
      previousFund.continuousCover = (values['partnerTransferringFunds--continuousCover'] as ContinuousCoverType) || null;
    } else {
      previousFund.previouslyHadHealthInsurance = false;
      previousFund.ageBasedDiscountAssessmentDate = format(new Date(), ISO_DATE_FORMAT);
    }
  }

  return previousFund;
};

export const mapFamilyDetailsToSession = (values: FamilyDetailsValues, session: Session, funds: Dictionary<string>, isPageComplete?: boolean): Session =>
  produce(session, (draftSession: Session) => {
    const scale = values.scale;

    draftSession.personalDetails.scale = scale;

    if (session.personalDetails.hasPartner === Selected.yes) {
      draftSession.personalDetails.partner = mapPartnerToSession(values);
      if (draftSession.governmentDetails) {
        draftSession.governmentDetails.partnerPreviousFundDetails = mapPartnerPreviousFundToSession(values, funds);
      }
    }

    if (session.personalDetails.hasDependants) {
      draftSession.personalDetails.dependants = [];
      values.dependants.forEach((dependant) => {
        draftSession.personalDetails.dependants.push(mapDependantToSession(dependant, funds));
      });
    }

    if (isPageComplete && draftSession.funnelProgress) {
      draftSession.funnelProgress.lastCompletedPage = ARHIPageList[ARHIPageIndex.FAMILY_DETAILS];
    }
  });
