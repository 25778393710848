import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {FailedRequestPayload} from '../types';
import {ResumeQuoteState, FetchQuoteResponse} from './types';

export const initialState: ResumeQuoteState = {
  fetchingQuote: false,
  fetchedQuote: false,
  fetchQuoteError: null,
  metadata: null
};

const resumeQuoteSlice = createSlice({
  name: 'resumeQuote',
  initialState,
  reducers: {
    fetchQuotePending: (state) => {
      state.fetchingQuote = true;
      state.fetchedQuote = false;
      state.fetchQuoteError = null;
    },
    fetchQuoteRejected: (state, action: PayloadAction<FailedRequestPayload>) => {
      const {error} = action.payload;
      state.fetchingQuote = false;
      state.fetchedQuote = false;
      state.fetchQuoteError = error;
    },
    fetchQuoteExpired: (state, action: PayloadAction<FetchQuoteResponse>) => {
      state.fetchingQuote = false;
      state.fetchedQuote = false;
      state.fetchQuoteError = null;
      state.metadata = action.payload.metadata;
    },
    fetchQuoteSuccess: (state, action: PayloadAction<FetchQuoteResponse>) => {
      state.fetchingQuote = false;
      state.fetchedQuote = true;
      state.fetchQuoteError = null;
      state.metadata = action.payload.metadata;
    }
  }
});

export const {fetchQuotePending, fetchQuoteRejected, fetchQuoteExpired, fetchQuoteSuccess} = resumeQuoteSlice.actions;

export default resumeQuoteSlice.reducer;
