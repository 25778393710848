import type {Session, OfferDetails} from '@nib/types-session-api';
import {PayloadAction} from '@reduxjs/toolkit';
import {FailedRequestPayload} from '../../types';
import {SessionState} from '../sessionSlice';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const saveOfferDetailsPendingReducer = (draftState: SessionState, action: PayloadAction<OfferDetails>) => {
  draftState.updatingSession = true;
  draftState.sessionError = null;
};

export const saveOfferDetailsRejectedReducer = (draftState: SessionState, action: PayloadAction<FailedRequestPayload>) => {
  const {error} = action.payload;
  draftState.sessionError = error;
  draftState.updatingSession = false;
};

export const saveOfferDetailsSuccessReducer = (draftState: SessionState, action: PayloadAction<Session>) => {
  const {offerDetails} = action.payload;

  draftState.updatingSession = false;
  if (draftState.session) {
    if (draftState.session.offerDetails && offerDetails) {
      draftState.session.offerDetails.offerId = offerDetails.offerId;
      draftState.session.offerDetails.campaignDescription = offerDetails.campaignDescription;
      draftState.session.offerDetails.whicsCampaignCode = offerDetails.whicsCampaignCode;
      draftState.session.offerDetails.welcomeEmailOfferTemplate = offerDetails.welcomeEmailOfferTemplate;
      draftState.session.offerDetails.campaignParameters = offerDetails.campaignParameters;
    }
  }
};
