import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axios from 'axios';
import {LookupUrl} from '../../constants';
import {ErrorResponse, LookupBsbResponse} from './types';

export interface BsbLookupState {
  bsbInfo?: LookupBsbResponse;
}

export const initialState: BsbLookupState = {
  bsbInfo: {
    financialInstitutionData: [],
    statusCode: undefined,
    message: undefined
  }
};

export const fetchBsbInfo = createAsyncThunk('bsb/lookup', async (bsb: string): Promise<LookupBsbResponse> => {
  try {
    const res = await axios.get(`${LookupUrl}/financialInstitution/bsb/${bsb}`);
    return {
      financialInstitutionData: res.data.data,
      statusCode: res.status
    };
  } catch (err) {
    const error: ErrorResponse = err;
    return {
      financialInstitutionData: [],
      statusCode: error.response.status,
      message: error.response.data.err?.errors?.body?.message ?? error.response.statusText
    };
  }
});

const bsbLookupSlice = createSlice({
  name: 'bsbLookup',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBsbInfo.fulfilled, (state, action) => {
      state.bsbInfo = action.payload as LookupBsbResponse;
    });
  }
});

export const {actions} = bsbLookupSlice;

export default bsbLookupSlice.reducer;
