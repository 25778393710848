import type {GovernmentDetails, PreviousFundDetails} from '@nib/types-session-api';
import {ARHIJoinState} from '../../rootReducer';
import _get from 'lodash/get';
export const selectGovernmentData = (state: ARHIJoinState): GovernmentDetails => _get(state, 'session.session.governmentDetails', {} as GovernmentDetails);
export const getPreviousFundDetails = (state: ARHIJoinState): PreviousFundDetails => selectGovernmentData(state).policyHolderPreviousFundDetails || ({} as PreviousFundDetails);
export const getPreviousFundName = (state: ARHIJoinState): string | null => getPreviousFundDetails(state).description;
export const getPreviousFundCode = (state: ARHIJoinState): string | null => getPreviousFundDetails(state).code;
export const getPreviousFundPolicyNumber = (state: ARHIJoinState): string | null | undefined => getPreviousFundDetails(state).policyNumber;
export const getHasPreviousFund = (state: ARHIJoinState): boolean | null => getPreviousFundDetails(state).previouslyHadHealthInsurance;
export const getHasPreExistingCondition = (state: ARHIJoinState): boolean | null => selectGovernmentData(state).hasExistingPreExistingCondition;
