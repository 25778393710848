import type {Session} from '@nib/types-session-api';

import {ARHIPage} from './../../../types/pages/index';
import {PayloadAction} from '@reduxjs/toolkit';
import {FailedRequestPayload} from '../../types';
import {SessionState} from '../sessionSlice';
import {ARHIPageList, ARHIPageIndex} from '../../../constants';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const saveFunnelProgressPendingReducer = (draftState: SessionState, action: PayloadAction<{activePage?: ARHIPage; lastCompletedPage?: ARHIPage}>) => {
  draftState.updatingSession = true;
  draftState.sessionError = null;
};

export const saveFunnelProgressRejectedReducer = (draftState: SessionState, action: PayloadAction<FailedRequestPayload>) => {
  const {error} = action.payload;
  draftState.sessionError = error;
  draftState.updatingSession = false;
};

export const completeFunnelProgressReducer = (draftState: SessionState) => {
  if (draftState.session && draftState.session.funnelProgress) {
    draftState.session.funnelProgress.lastCompletedPage = ARHIPageList[ARHIPageIndex.PAYMENT_DETAILS];
  }
};

export const saveFunnelProgressSuccessReducer = (draftState: SessionState, action: PayloadAction<Session>) => {
  draftState.updatingSession = false;

  const {funnelProgress} = action.payload;

  if (!draftState.session) return;

  if (draftState.session.funnelProgress && funnelProgress) {
    draftState.session.funnelProgress.activePage = funnelProgress.activePage;
    draftState.session.funnelProgress.lastCompletedPage = funnelProgress.lastCompletedPage;
  }
};

export const completeQuoteFunnelProgressReducer = (draftState: SessionState) => {
  if (draftState.session && draftState.session.funnelProgress) {
    draftState.session.funnelProgress.activePage = ARHIPageList[ARHIPageIndex.COVER_SUMMARY];
    draftState.session.funnelProgress.lastCompletedPage = ARHIPageList[ARHIPageIndex.EXTRAS];
  }
};
