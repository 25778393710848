import {PaymentFrequency} from '@nib/phi-constants';
import {isWeekend} from 'date-fns';
import {FormikProps} from 'formik';
import {produce} from 'immer';
import {isOfType} from '../../../services/utils';
import {isWeeklyOrFortnightlyFrequency} from '../utils';
import {FIELD_NAMES} from './constants';
import {PaymentDetailsValues} from './types';

export const saveFormOnBlur = (formikBag: FormikProps<PaymentDetailsValues>, updateFunc: UpdateFunc) => (event) => {
  if (event && event.target && event.target.name) {
    updateFunc(formikBag.values, formikBag.touched, event.target.name);
  }
};

/* Radio buttons / text input fields / selects
 *  Any field / component that passes up a event object when calling the onChange function
 */
export const saveFormOnChange = (formikBag: FormikProps<PaymentDetailsValues>, updateFunc: UpdateFunc) => (event) => {
  if (event && event.target && event.target.name) {
    const value = event.target.value;
    const updatedValue = {
      [event.target.name]: value
    };
    updateFunc({...formikBag.values, ...updatedValue}, formikBag.touched, event.target.name);
  }
};

/* date fields */
export const saveFormOnDateChange = (formikBag: FormikProps<PaymentDetailsValues>, updateFunc: UpdateFunc, dateFieldName: string) => (date) => {
  updateFunc({...formikBag.values, [dateFieldName]: date}, formikBag.touched, dateFieldName);
};

export const coverStartDateOnChangeHandler = (formikBag: FormikProps<PaymentDetailsValues>, updateFunc: UpdateFunc, dateFieldName: string) => (date) => {
  return saveFormOnDateChange(formikBag, updateFunc, dateFieldName)(date);
};

const clearPaymentStartDate = (formikBag: FormikProps<PaymentDetailsValues>) => {
  formikBag.setFieldValue(FIELD_NAMES.PAYMENT_START_DATE, null);
  formikBag.setFieldTouched(FIELD_NAMES.PAYMENT_START_DATE, false);

  const newValues = produce(formikBag.values, (draftFormikValues) => {
    draftFormikValues.paymentStartDate = null;
  });

  const newTouched = produce(formikBag.touched, (draftFormikTouched) => {
    draftFormikTouched.paymentStartDate = false;
  });

  return {...formikBag, values: newValues, touched: newTouched};
};

export const paymentFrequencyOnChangeHandler = (formikBag: FormikProps<PaymentDetailsValues>, updateFunc: UpdateFunc) => (event) => {
  if (event && event.target && event.target.name) {
    const frequencyValue = event.target.value;
    const paymentStartDate = formikBag.values.paymentStartDate;
    let writableFormikBag = formikBag;

    if (paymentStartDate && isOfType<Date>(paymentStartDate, 'getDate')) {
      // Reset payment start date if previously was a weekend and has now frequency of either weekly or fortnightly
      if (isWeeklyOrFortnightlyFrequency(PaymentFrequency[frequencyValue]) && isWeekend(paymentStartDate)) {
        writableFormikBag = clearPaymentStartDate(formikBag);
      }

      // If payment start date is > 28th of a month but < 1st of next month and is not a weekly or fortnightly payment - clear date
      if (!isWeeklyOrFortnightlyFrequency(PaymentFrequency[frequencyValue]) && paymentStartDate.getDate() >= 28) {
        writableFormikBag = clearPaymentStartDate(formikBag);
      }
    }

    return saveFormOnChange(writableFormikBag, updateFunc)(event);
  }
};

export type UpdateFunc = (values: PaymentDetailsValues, touched: object, fieldName: string) => void;
