export const FIELD_NAMES = {
  EXCESS: 'excess',
  SCALE: 'scale',
  HAS_PARTNER: 'hasPartner',
  ANNUAL_INCOME: 'incomeTier',
  ANNUAL_INCOME_MODAL: 'incomeTierModal',
  DATE_OF_BIRTH: 'dateOfBirth',
  DATE_OF_BIRTH_DAY: 'dateOfBirthDay',
  DATE_OF_BIRTH_YEAR: 'dateOfBirthYear',
  DATE_OF_BIRTH_MONTH: 'dateOfBirthMonth',
  PARTNER_DATE_OF_BIRTH: 'partnerDateOfBirth',
  PARTNER_DATE_OF_BIRTH_DAY: 'partnerDateOfBirthDay',
  PARTNER_DATE_OF_BIRTH_YEAR: 'partnerDateOfBirthYear',
  PARTNER_DATE_OF_BIRTH_MONTH: 'partnerDateOfBirthMonth'
} as const;
