/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable no-case-declarations */
import reactMetricsMiddleware from '@nib/react-metrics-redux';
import {createReduxEnhancer} from '@sentry/gatsby';
import {metrics} from '../metrics';
import {configureStore, Action, AnyAction, createAction} from '@reduxjs/toolkit';
import {ThunkAction} from 'redux-thunk';
import rootReducer, {ARHIJoinState} from './rootReducer';
import {JoinResult} from './join/joinSlice';
import {observe} from 'redux-observers';
import observers from './observers';

export const resetAppAction = createAction('store/reset');
export const resetUserData = createAction('store/resetUserData');

const resettableRootReducer = (state, action: AnyAction) => {
  switch (action.type) {
    case resetAppAction.type:
      // reset all slices back to original initial values
      return rootReducer(undefined, action);
    case resetUserData.type:
      // destructure what we want to keep (non user data related slices) and place back on the state
      const {previousFunds, featureToggles} = state;
      state = {previousFunds, featureToggles};
      return rootReducer(state, action);
    default:
      return rootReducer(state, action);
  }
};

export const sentryReduxEnhancer = createReduxEnhancer({
  actionTransformer: ({type}) => ({type}),
  stateTransformer: () => null
});

const store = configureStore({
  reducer: resettableRootReducer,
  middleware: (getDefaultMiddleware) => [...getDefaultMiddleware({serializableCheck: false}), reactMetricsMiddleware(metrics)],
  enhancers: [sentryReduxEnhancer]
});

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./rootReducer', () => {
    const newRootReducer = require('./rootReducer').default;
    store.replaceReducer(newRootReducer);
  });
}

observe(store, observers);

export type AppDispatch = typeof store.dispatch;

export type AppThunk = ThunkAction<void, ARHIJoinState, null, Action<string>>;
export type SubmitJoinThunk = ThunkAction<Promise<string | JoinResult>, ARHIJoinState, null, Action<string>>;

export default store;
