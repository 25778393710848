import type {ExtrasProduct} from '@nib/types-session-api';
import {PayloadAction} from '@reduxjs/toolkit';
import {ARHIPageIndex, ARHIPageList} from '../../../constants';
import {FailedRequestPayload, SessionAndIsPageComplete} from '../../types';
import {SessionState} from '../sessionSlice';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const saveExtrasDetailsPendingReducer = (draftState: SessionState, action: PayloadAction<ExtrasProduct>) => {
  draftState.updatingSession = true;
  draftState.sessionError = null;
};

export const saveExtrasDetailsRejectedReducer = (draftState: SessionState, action: PayloadAction<FailedRequestPayload>) => {
  const {error} = action.payload;
  draftState.sessionError = error;
  draftState.updatingSession = false;
};

export const saveExtrasDetailsSuccessReducer = (draftState: SessionState, action: PayloadAction<SessionAndIsPageComplete>) => {
  draftState.updatingSession = false;
  if (!draftState.session) {
    return;
  }

  draftState.session.productSelection.extras = action.payload.session.productSelection.extras;

  if (action.payload.isPageComplete && draftState.session.funnelProgress) {
    draftState.session.funnelProgress.lastCompletedPage = ARHIPageList[ARHIPageIndex.EXTRAS];
  }
};
