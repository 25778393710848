export const FIELD_NAMES = {
  EXCESS: 'excess',
  SCALE: 'scale',
  HAS_PARTNER: 'hasPartner',
  ANNUAL_INCOME: 'incomeTier'
} as const;

export const hospitalModalTitle = 'Hospital Excess';

export const incomeModalHeader = 'How your age and annual income affect your Australian Government Rebate';
