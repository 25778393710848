import type {Nullable} from '@nib/types-session-api';

import {
  batchFetchProductPricesSuccessReducer,
  fetchProductPricesPartiallyRejectedReducer,
  fetchProductPricesPendingReducer,
  fetchProductPricesRejectedReducer,
  fetchProductPricesSuccessReducer
} from './reducers/productPricing';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AxiosError, FailedRequestPayload} from '../types';
import {PricingParams} from './types';
import {fetchFuturePricePendingReducer, fetchFuturePriceRejectedReducer, fetchFuturePriceSuccessReducer} from './reducers/futurePricing';

export interface PricingProduct {
  id: number;
  productType: string;
  baseRate: number;
  price: number;
  futurePrice?: number;
  risk: number;
  productCode: number;
  discountPercentApplicable: string;
}

export interface PriceResult {
  total: number;
  extras: PricingProduct;
  hospital: PricingProduct;
  premiumForPeriod: number;
}

export interface PricingStatusState {
  loadingPrice?: boolean;
  fetchedPrice?: boolean;
  priceError?: AxiosError | null;
}

export interface PricingData {
  totalPrice: number;
  hospitalPrice: Nullable<number>;
  extrasPrice: Nullable<number>;
  premiumForPeriod?: number;
  discountPercentApplicable: string;
}

export interface FuturePricingData {
  futurePrice: Nullable<PricingData & PricingStatusState>;
}

export interface PricingTriggers {
  pricingTriggerFields: string[];
}

export interface PriceFactorGroup {
  factors: PricingParams;
  pricingInformation: PricingProduct;
}

export interface PricesByFactors {
  pricesByFactors: Array<PriceFactorGroup>;
}

export interface PricingProductError {
  productId: number;
  error?: AxiosError | null;
}

export interface PricingProductsState {
  loadingProductPrices?: boolean;
  fetchedProductPrices?: boolean;
  productPricingError?: AxiosError | null;
  productPricingErrors?: PricingProductError[] | null;
  products: {[key: number]: PricesByFactors};
}

export type PricingState = PricingData & PricingStatusState & PricingTriggers & PricingProductsState & FuturePricingData;

export const initialState: PricingState = {
  loadingPrice: false,
  fetchedPrice: false,
  totalPrice: 0,
  priceError: null,
  futurePrice: null,
  pricingTriggerFields: [],
  extrasPrice: null,
  hospitalPrice: null,
  premiumForPeriod: undefined,
  discountPercentApplicable: '0.0',

  fetchedProductPrices: false,
  loadingProductPrices: false,
  products: {}
};

const pricingSlice = createSlice({
  name: 'pricing',
  initialState,
  reducers: {
    fetchPricePending: (state) => {
      state.loadingPrice = true;
      state.fetchedPrice = false;
      state.priceError = null;
    },
    fetchPriceSuccess: (state, action: PayloadAction<PriceResult>) => {
      const {total, extras, hospital, premiumForPeriod} = action.payload;

      state.totalPrice = total;
      state.extrasPrice = extras ? extras.price : null;
      state.hospitalPrice = hospital ? hospital.price : null;
      state.premiumForPeriod = premiumForPeriod;

      state.loadingPrice = false;
      state.fetchedPrice = true;
      state.priceError = null;
      state.discountPercentApplicable = hospital ? hospital.discountPercentApplicable : '0.0';
    },
    fetchPriceRejected: (state, action: PayloadAction<FailedRequestPayload | any>) => {
      const {error} = action.payload;
      state.loadingPrice = false;
      state.fetchedPrice = false;
      state.priceError = error;
      state.pricingTriggerFields = [];
    },
    setPricingTriggerFields: (state, action: PayloadAction<string[]>) => {
      state.pricingTriggerFields = action.payload;
    },
    fetchFuturePricePending: fetchFuturePricePendingReducer,
    fetchFuturePriceSuccess: fetchFuturePriceSuccessReducer,
    fetchFuturePriceRejected: fetchFuturePriceRejectedReducer,
    fetchProductPricesPending: fetchProductPricesPendingReducer,
    fetchProductPricesSuccess: fetchProductPricesSuccessReducer,
    fetchProductPricesRejected: fetchProductPricesRejectedReducer,
    fetchProductPricesPartiallyRejected: fetchProductPricesPartiallyRejectedReducer,
    batchFetchProductPricesSuccess: batchFetchProductPricesSuccessReducer
  }
});

export const {
  fetchPricePending,
  fetchPriceSuccess,
  fetchPriceRejected,
  setPricingTriggerFields,
  fetchFuturePricePending,
  fetchFuturePriceSuccess,
  fetchFuturePriceRejected,
  fetchProductPricesPending,
  fetchProductPricesSuccess,
  fetchProductPricesRejected,
  fetchProductPricesPartiallyRejected,
  batchFetchProductPricesSuccess
} = pricingSlice.actions;

export default pricingSlice.reducer;
