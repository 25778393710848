import type {Nullable, PaymentFrequency as TPaymentFrequency} from '@nib/types-session-api';
import {PaymentFrequency, Selected} from '@nib/phi-constants';
import {produce, setAutoFreeze} from 'immer';
import _isArray from 'lodash/isArray';
import _isPlainObject from 'lodash/isPlainObject';
import {ValidationContextState} from '../ValidationContext';
import {FormikProps} from 'formik';
import {endOfMonth} from 'date-fns';

export const UnselectedToUndefined = (value: string | undefined) => {
  if (value === Selected.unselected) {
    return undefined;
  }
  return value;
};

export const SelectedToNullableBoolean = (value: string | undefined) => {
  return value === Selected.unselected ? null : value === Selected.yes;
};

export const SelectedToBoolean = (value: string) => {
  return value === Selected.yes;
};

export const nullableBooleanToSelectedString = (value: Nullable<boolean>): string => {
  if (value === null || value === undefined) {
    return Selected.unselected;
  } else {
    return value ? Selected.yes : Selected.no;
  }
};

// take date like 12/22 and return 2022-12
export const formatExpiryDate = (expiryDate) => {
  let formattedDate = '';
  const yearPrefix = '20';
  const month = expiryDate.substring(0, 2);
  const year = expiryDate.substring(3, 5);

  if (month.length === 2 && year.length === 2) {
    formattedDate = `${yearPrefix}${year}-${month}`;
  }

  return formattedDate;
};

export const CreditCardExpiryToDate = (expiryDate) => {
  let date;
  const yearPrefix = '20';
  const month = expiryDate.substring(0, 2);
  const year = expiryDate.substring(3, 5);

  if (month.length === 2 && year.length === 2) {
    date = endOfMonth(new Date(Number(`${yearPrefix}${year}`), month - 1, 1));
  }

  return date;
};

export const isWeeklyOrFortnightlyFrequency = (frequency: TPaymentFrequency) => {
  return frequency === PaymentFrequency.Weekly || frequency === PaymentFrequency.Fortnightly;
};

export const connectValidationContext = <T>(validationContext: ValidationContextState, formikBag: FormikProps<T>) => {
  validationContext.registerTouchedFunction(formikBag.setTouched);
  validationContext.registerValidationFunction(formikBag.validateForm);
  validationContext.setErrors(formikBag.errors);
};

const preSaveMapperStep = <T, TInitials>(draftValues: T, errors: object, touched: object, initialValues: TInitials) => {
  Object.keys(errors).forEach((key) => {
    if (_isPlainObject(errors[key])) {
      preSaveMapperStep(draftValues[key], errors[key], touched[key] || {}, initialValues[key] || {});
    } else if (_isArray(errors[key])) {
      preSaveMapperStep(draftValues[key], errors[key], touched[key] || [], initialValues[key] || []);
    } else if (errors[key] && touched[key]) {
      draftValues[key] = initialValues[key];
    }
  });
};

export const preSaveMapper = <T, TInitials>(values: T, errors: object, touched: object, initialValues: TInitials) => {
  // As of v8.0.0, Immer has autoFreeze set to true by default for both dev and prod.
  // We switch that off here otherwise it's going to freeze nested objects from formik.values.
  setAutoFreeze(false);
  const mapped = produce<T>(values, (draftValues) => {
    preSaveMapperStep(draftValues, errors, touched, initialValues);
  });
  setAutoFreeze(true);
  return mapped;
};
