import type {Company} from '@nib/types-session-api';

import {QuoteCookie} from '../../../services/cookies';

export const mapCorporateDataToSession = ({CompanyCode, CompanyName, GroupId, SectionId, CorporateRateCode}: QuoteCookie): Company => ({
  code: CompanyCode || null,
  name: CompanyName || null,
  group: GroupId || null,
  section: SectionId || null,
  rateCode: CorporateRateCode,
  rateCodeDesc: null,
  logoUrl: null
});
