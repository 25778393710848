import {captureMessage} from '@sentry/gatsby';
import {AppThunk} from '../store';
import {isAxiosError} from '../types';
import * as api from './api';
import {fetchFeatureTogglesPending, fetchFeatureTogglesRejected, fetchFeatureTogglesSuccess} from './featureToggleSlice';
import {mapFeatureToggles} from './mappers';

export const fetchFeatureToggles = (): AppThunk => async (dispatch) => {
  dispatch(fetchFeatureTogglesPending());
  try {
    const featureTogglesResponse = await api.fetchFeatureToggles();
    if (isAxiosError(featureTogglesResponse)) {
      dispatch(fetchFeatureTogglesRejected({error: featureTogglesResponse.data}));
    } else {
      const mappedFeatureToggles = mapFeatureToggles(featureTogglesResponse.data);
      dispatch(fetchFeatureTogglesSuccess(mappedFeatureToggles));
    }
  } catch (exception) {
    dispatch(fetchFeatureTogglesRejected({error: exception}));
    captureMessage(`error fetching feature toggles. Exception - ${exception}`, 'error');
  }
};
