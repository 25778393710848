import {PayloadAction} from '@reduxjs/toolkit';
import {FailedRequestPayload} from '../../types';
import {PriceResult, PricingData, PricingState, PricingStatusState} from '../pricingSlice';
import {PricingParams} from '../types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const fetchFuturePricePendingReducer = (state: PricingState, action: PayloadAction<PricingParams>) => {
  if (state.futurePrice === null) {
    state.futurePrice = {} as PricingData & PricingStatusState;
  }

  state.futurePrice.loadingPrice = true;
  state.futurePrice.fetchedPrice = false;
  state.futurePrice.priceError = null;
};

export const fetchFuturePriceSuccessReducer = (state, action: PayloadAction<PriceResult>) => {
  const {total, extras, hospital, premiumForPeriod} = action.payload;

  if (state.futurePrice === null) {
    state.futurePrice = {} as PricingData & PricingStatusState;
  }

  state.futurePrice.totalPrice = total;
  state.futurePrice.extrasPrice = extras ? extras.price : null;
  state.futurePrice.hospitalPrice = hospital ? hospital.price : null;
  state.futurePrice.premiumForPeriod = premiumForPeriod;

  state.futurePrice.loadingPrice = false;
  state.futurePrice.fetchedPrice = true;
  state.futurePrice.priceError = null;
  state.futurePrice.discountPercentApplicable = hospital ? hospital.discountPercentApplicable : '0.0';
};

export const fetchFuturePriceRejectedReducer = (state, action: PayloadAction<FailedRequestPayload | any>) => {
  const {error} = action.payload;
  if (state.futurePrice === null) {
    state.futurePrice = {} as PricingData & PricingStatusState;
  }

  state.futurePrice.loadingPrice = false;
  state.futurePrice.fetchedPrice = false;
  state.futurePrice.priceError = error;
};
