import type {ProductSelection, HospitalProduct, Nullable, ExtrasProduct, CombinedProduct} from '@nib/types-session-api';
import {ARHIJoinState} from '../../rootReducer';
import _get from 'lodash/get';

export const selectData = (state: ARHIJoinState): ProductSelection => _get(state, 'session.session.productSelection') || ({} as ProductSelection);

// Needed to default to an empty object here as the contract states that the object will never be null but the session
// does indeed return us an combinedProduct: null value. Also the same for Hospital and Extras product.
export const getHospital = (state: ARHIJoinState): HospitalProduct => selectData(state).hospital || ({} as HospitalProduct);
export const getExtras = (state: ARHIJoinState): ExtrasProduct => selectData(state).extras || ({} as ExtrasProduct);

export const getCombinedProduct = (state: ARHIJoinState): CombinedProduct => selectData(state).combined || ({} as CombinedProduct);

export const getHospitalId = (state: ARHIJoinState): Nullable<number> => getHospital(state).id;
export const getExtrasId = (state: ARHIJoinState): Nullable<number> => getExtras(state).id;
export const getCombinedProductId = (state: ARHIJoinState): Nullable<number> => getCombinedProduct(state).id;
export const getExcess = (state: ARHIJoinState) => getHospital(state).excess;
