import type {CampaignParameters, Nullable, OfferDetails} from '@nib/types-session-api';
import {ARHIJoinState} from '../../rootReducer';
import _get from 'lodash/get';

export const selectData = (state: ARHIJoinState): OfferDetails => _get(state, 'session.session.offerDetails') || ({} as OfferDetails);

export const getOfferId = (state: ARHIJoinState): number | null => selectData(state).offerId;
export const getOfferDescription = (state: ARHIJoinState): string | null => selectData(state).campaignDescription;
export const getCampaignParameters = (state: ARHIJoinState): Nullable<CampaignParameters> => selectData(state).campaignParameters || null;
export const getCampaignValue = (state: ARHIJoinState): Nullable<string> => selectData(state).campaignParameters?.utm_campaign || null;
export const getSourceValue = (state: ARHIJoinState): Nullable<string> => selectData(state).campaignParameters?.utm_source || null;
export const getContentValue = (state: ARHIJoinState): Nullable<string> => selectData(state).campaignParameters?.utm_content || null;
