import {FormikProps} from 'formik';
import {produce} from 'immer';
import _set from 'lodash/set';
import {FamilyDetailsValues} from './types';
import {FIELD_NAMES} from './constants';
import {getItemsFromDate} from '../../../services/utils';

export const saveFormOnBlur = (formikBag: FormikProps<FamilyDetailsValues>, updateFunc: UpdateFunc, optionalFieldName?) => (e) => {
  // 'optionalFieldName' accommodates for optional field name or when onBlur does not pass in event with a target name.
  if (optionalFieldName || (e && e.target && e.target.name)) {
    const fieldName = optionalFieldName || (e && e.target && e.target.name);
    updateFunc(formikBag.values, formikBag.touched, fieldName);
  }
};

export const saveFormOnDOBChange = (formikBag: FormikProps<FamilyDetailsValues>, updateFunc: UpdateFunc) => (_e, newValue) => {
  // DOB event handlers send latest Date but not the last change from input, e.g. '1999' for year is sent '199'
  const newDateOfBirth = {
    [FIELD_NAMES.DATE_OF_BIRTH]: newValue,
    [`${FIELD_NAMES.DATE_OF_BIRTH}Day`]: getItemsFromDate(newValue, 'day'),
    [`${FIELD_NAMES.DATE_OF_BIRTH}Month`]: getItemsFromDate(newValue, 'month'),
    [`${FIELD_NAMES.DATE_OF_BIRTH}Year`]: getItemsFromDate(newValue, 'year')
  };
  updateFunc({...formikBag.values, ...newDateOfBirth}, formikBag.touched, FIELD_NAMES.DATE_OF_BIRTH);
};

export const saveFormOnDependantsDOBChange = (formikBag: FormikProps<FamilyDetailsValues>, updateFunc: UpdateFunc) => (e, newValue) => {
  const fieldName = e.target.name;
  const path = fieldName.match(/dependants\.\d+\.childDateOfBirth/)[0];
  const index: number = parseInt(fieldName.match(/\d+/));
  const dependants = formikBag.values.dependants;

  // DOB event handlers send latest Date but not the last change from input, e.g. '1999' for year is sent '199'
  if (!isNaN(index)) {
    const dependant = {
      ...formikBag.values.dependants[index],
      childDateOfBirth: newValue,
      childDateOfBirthDay: getItemsFromDate(newValue, 'day'),
      childDateOfBirthMonth: getItemsFromDate(newValue, 'month'),
      childDateOfBirthYear: getItemsFromDate(newValue, 'year')
    };
    dependants[index] = dependant;
  }

  updateFunc({...formikBag.values, ...dependants}, formikBag.touched, path);
};

export const saveFormOnDependantsGraduationDateChange = (formikBag: FormikProps<FamilyDetailsValues>, updateFunc: UpdateFunc) => (e, newValue) => {
  const fieldName = e.target.name;
  const path = fieldName.match(/dependants\.\d+\.graduationDate/)[0];
  const index: number = parseInt(fieldName.match(/\d+/));
  const dependants = formikBag.values.dependants;

  if (!isNaN(index)) {
    const dependant = {
      ...formikBag.values.dependants[index],
      graduationDate: newValue,
      graduationDateDay: getItemsFromDate(newValue, 'day'),
      graduationDateMonth: getItemsFromDate(newValue, 'month'),
      graduationDateYear: getItemsFromDate(newValue, 'year')
    };
    dependants[index] = dependant;
  }

  updateFunc({...formikBag.values, ...dependants}, formikBag.touched, path);
};

export const saveFormOnDependantRemoval = (formikBag: FormikProps<FamilyDetailsValues>, updateFunc: UpdateFunc) => (event) => {
  if (event && event.dependantRemoved && event.index >= 0) {
    const dependants = formikBag.values.dependants;
    dependants.splice(event.index, 1);
    updateFunc({...formikBag.values, ...dependants}, formikBag.touched, 'dependants', true);
  }
};

/* Radio buttons / text input fields / selects
 *  Any field / component that passes up a event object when calling the onChange function
 */
export const saveFormOnChange = (formikBag: FormikProps<FamilyDetailsValues>, updateFunc: UpdateFunc) => (event) => {
  let updatedValue = {};
  if (event && event.target && event.target.name) {
    const value = event.target.value;
    updatedValue = {
      [event.target.name]: value
    };
    updateFunc({...formikBag.values, ...updatedValue}, formikBag.touched, event.target.name);
  }
};

/* Radio buttons in dependant fields only */
export const saveFormDependantsOnChange = (formikBag: FormikProps<FamilyDetailsValues>, updateFunc: UpdateFunc) => (event) => {
  if (event && event.target && event.target.name) {
    const value = event.target.value;

    /* Avoid making direct changes to the formik bag values object (by reference) as this could impact formik lifecycle
       and cause unwanted side effects. */
    const updatedValues = produce(formikBag.values, (draftValues) => {
      _set(draftValues, event.target.name, value);
    });

    updateFunc({...updatedValues}, formikBag.touched, event.target.name);
  }
};

type UpdateFunc = (values: FamilyDetailsValues, touched: object, fieldName: string, forceSave?: boolean) => void;
