import type {PreviousFundDetails, Session} from '@nib/types-session-api';
import {PayloadAction} from '@reduxjs/toolkit';
import {FailedRequestPayload} from '../../types';
import {SessionState} from '../sessionSlice';
import {PersonalDetailsValues} from '../../../components/Forms/PersonalDetails/types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const savePersonalDetailsFormPendingReducer = (draftState: SessionState, action: PayloadAction<PersonalDetailsValues>) => {
  draftState.updatingSession = true;
  draftState.sessionError = null;
};

export const savePersonalDetailsFormRejectedReducer = (draftState: SessionState, action: PayloadAction<FailedRequestPayload>) => {
  const {error} = action.payload;
  draftState.sessionError = error;
  draftState.updatingSession = false;
};

export const savePersonalDetailsFormSuccessReducer = (draftState: SessionState, action: PayloadAction<Session>) => {
  const {policyHolder} = action.payload.personalDetails;
  const {contactDetails, governmentDetails, aboutYouDetails} = action.payload;

  draftState.updatingSession = false;
  if (!draftState.session) {
    return;
  }

  draftState.session.personalDetails.policyHolder.title = policyHolder.title;
  draftState.session.personalDetails.policyHolder.gender = policyHolder.gender;
  draftState.session.personalDetails.policyHolder.firstName = policyHolder.firstName;
  draftState.session.personalDetails.policyHolder.lastName = policyHolder.lastName;
  draftState.session.personalDetails.policyHolder.dateOfBirth = policyHolder.dateOfBirth;
  draftState.session.personalDetails.policyHolder.email = policyHolder.email;

  // contact details add / update
  if (draftState.session.contactDetails) {
    draftState.session.contactDetails.phoneNumbers = contactDetails.phoneNumbers;
    draftState.session.contactDetails.address = contactDetails.address;
  } else {
    draftState.session.contactDetails = {
      phoneNumbers: contactDetails.phoneNumbers,
      address: contactDetails.address
    };
  }

  if (draftState.session.offerDetails) {
    draftState.session.offerDetails.campaignParameters = action.payload.offerDetails?.campaignParameters;
  }

  const previousFundDetails = (action.payload.governmentDetails && action.payload.governmentDetails.policyHolderPreviousFundDetails) || ({} as PreviousFundDetails);
  // government details add / update
  if (draftState.session.governmentDetails) {
    draftState.session.governmentDetails.policyHolderPreviousFundDetails = previousFundDetails;
  } else {
    draftState.session.governmentDetails = {
      policyHolderPreviousFundDetails: previousFundDetails,
      policyHolderCertifiedAgeOfEntry: null,
      applyGovernmentRebate: false,
      governmentRebateAcknowledgement: null,
      hasExistingPreExistingCondition: false,
      medicareExpiry: null,
      medicareNumber: null,
      medicareCardType: null,
      partnerCertifiedAgeOfEntry: null,
      partnerPreviousFundDetails: null,
      incomeTier: undefined
    };
  }

  if (draftState.session.joinProgress) {
    draftState.session.joinProgress.hasAcceptedPrivacyPolicyAgreement = action.payload.joinProgress?.hasAcceptedPrivacyPolicyAgreement || false;
  }

  if (draftState.session.governmentDetails && governmentDetails) {
    draftState.session.governmentDetails.incomeTier = governmentDetails.incomeTier;
    draftState.session.governmentDetails.applyGovernmentRebate = governmentDetails.applyGovernmentRebate;
  }

  if (aboutYouDetails) {
    draftState.session.aboutYouDetails = aboutYouDetails;
  }
};
