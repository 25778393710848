import type {Session} from '@nib/types-session-api';
import {PayloadAction} from '@reduxjs/toolkit';
import {FailedRequestPayload} from '../../types';
import {SessionState} from '../sessionSlice';

export const saveSendQuoteFormPendingReducer = (draftState: SessionState) => {
  draftState.updatingSession = true;
  draftState.sessionError = null;
};

export const saveSendQuoteFormRejectedReducer = (draftState: SessionState, action: PayloadAction<FailedRequestPayload>) => {
  const {error} = action.payload;
  draftState.sessionError = error;
  draftState.updatingSession = false;
};

export const saveSendQuoteDetailsSuccessReducer = (draftState: SessionState, action: PayloadAction<Session>) => {
  draftState.updatingSession = false;
  draftState.sessionError = null;
  const {personalDetails, contactDetails} = action.payload;

  if (!draftState.session) {
    return;
  }

  draftState.session.personalDetails.policyHolder = personalDetails.policyHolder;
  draftState.session.personalDetails.partner = personalDetails.partner;

  if (draftState.session.contactDetails.phoneNumbers) {
    draftState.session.contactDetails.phoneNumbers = contactDetails.phoneNumbers;
  }
};
