/* eslint-disable @typescript-eslint/no-empty-function */
import type {Session, OfferDetails} from '@nib/types-session-api';
import {produce} from 'immer';

export const mapOfferDetailsToSession = (values: OfferDetails, session: Session): Session => {
  const mappedSession = produce(session, (draftSession) => {
    const offerDetails: OfferDetails = {
      campaignDescription: values.campaignDescription,
      offerId: values.offerId,
      welcomeEmailOfferTemplate: values.welcomeEmailOfferTemplate,
      whicsCampaignCode: values.whicsCampaignCode,
      campaignParameters: values.campaignParameters,
      // TODO: promoCode is here for type sake
      promoCode: null
    };

    if (draftSession.offerDetails) {
      draftSession.offerDetails.campaignDescription = offerDetails.campaignDescription;
      draftSession.offerDetails.campaignParameters = offerDetails.campaignParameters;
      draftSession.offerDetails.offerId = offerDetails.offerId;
      draftSession.offerDetails.welcomeEmailOfferTemplate = offerDetails.welcomeEmailOfferTemplate;
      draftSession.offerDetails.whicsCampaignCode = offerDetails.whicsCampaignCode;
      draftSession.offerDetails.campaignParameters = offerDetails.campaignParameters;
    } else {
      draftSession.offerDetails = offerDetails;
    }
  });

  return mappedSession;
};
