import {captureMessage} from '@sentry/gatsby';
import * as api from './api';
import {ARHIJoinState} from '../rootReducer';
import {fetchOfferPending, fetchOfferRejected, fetchOfferSuccess} from './offerSlice';
import {AxiosResponse, AxiosError, isAxiosError} from '../types';
import {OfferParams, Offer} from './types';
import {mapStoreToOfferRequest} from './mappers';
import {saveOfferDetails} from '../session/thunks';
import {getCookieSessionId} from '../../services/cookies';
import {getCampaignParameters} from '../session/selectors/offerDetails';

export const fetchOffer = (overrides?: FetchOfferParamOverrides) => async (dispatch, getState) => {
  const arhiJoinState: ARHIJoinState = getState();
  const reduxSession = arhiJoinState.session.session;
  const sessionCookie = getCookieSessionId();

  if (sessionCookie && reduxSession) {
    try {
      // use session to retrieve the latest params needed for the offer
      const offerParams: OfferParams = mapStoreToOfferRequest(arhiJoinState, overrides);

      dispatch(fetchOfferPending(offerParams));
      const offerResult: AxiosResponse<Offer> | AxiosResponse<AxiosError> = await api.fetch(offerParams);
      if (isAxiosError(offerResult)) {
        // The offerResult will most likely contain a non-serializable object containing functions,
        // so the following trick will exclude all non-serializable attributes
        const serialised = JSON.parse(JSON.stringify(offerResult));
        dispatch(fetchOfferRejected({error: serialised.data}));
      } else {
        const offerWithParams: Offer = {
          ...offerResult.data,
          campaignParameters: {
            utm_campaign: offerParams.utm_campaign || '',
            utm_content: offerParams.utm_content || '',
            utm_source: offerParams.utm_source || ''
          }
        };

        dispatch(
          fetchOfferSuccess({
            ...offerWithParams
          })
        );

        return {
          data: {
            ...offerWithParams
          },
          type: 'Offer'
        };
      }
      return offerResult;
    } catch (exception) {
      dispatch(fetchOfferRejected({error: exception}));
    }
  } else {
    const error = 'Error retrieving offer - invalid store or session cookie.';
    captureMessage(error, 'error');
    dispatch(fetchOfferRejected({error: error}));
  }
  return;
};

export interface FetchOfferParamOverrides {
  hasHospitalProduct: boolean;
  hasExtrasProduct: boolean;
}

export const fetchAndSaveOffer = (overrides?: FetchOfferParamOverrides) => async (dispatch, getState) => {
  try {
    const offerResponse = (await dispatch(fetchOffer(overrides))) as AxiosResponse<Offer> | AxiosResponse<AxiosError> | undefined;
    if (offerResponse) {
      if (!isAxiosError(offerResponse)) {
        // We have an offer returned based on session data in fetchOffer
        await dispatch(
          saveOfferDetails({
            offerId: offerResponse.data.whicsOffer ? parseInt(offerResponse.data.whicsOffer) : null,
            campaignDescription: offerResponse.data.description || offerResponse.data.name,
            whicsCampaignCode: null,
            welcomeEmailOfferTemplate: null,
            campaignParameters: offerResponse.data.campaignParameters || null,
            promoCode: null
          })
        );
      } else if (isAxiosError(offerResponse) && offerResponse.data.response.status === 404) {
        // status 404 means no offer found -- reset offer related data
        const campaignParameters = getCampaignParameters(getState());
        await dispatch(
          saveOfferDetails({
            campaignDescription: null,
            offerId: null,
            welcomeEmailOfferTemplate: null,
            whicsCampaignCode: null,
            campaignParameters,
            promoCode: null
          })
        );
      }
      return offerResponse;
    } else {
      const error = `Error retrieving and saving offer -- ${offerResponse}`;
      captureMessage(error, 'error');
      dispatch(fetchOfferRejected({error: error}));
    }
  } catch (exception) {
    dispatch(fetchOfferRejected({error: exception}));
    const error = 'Error retrieving and saving offer to session';
    captureMessage(error, 'error');
  }
  return;
};

export type FetchOfferThunk = () => Promise<AxiosResponse<Offer> | AxiosResponse<AxiosError> | undefined>;
export type fetchAndSaveOffer = () => Promise<AxiosResponse<Offer> | AxiosResponse<AxiosError> | undefined>;
