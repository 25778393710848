import {format, subYears} from 'date-fns';
import {Scale, PaymentFrequency, State} from '@nib/phi-constants';
import {DefaultInitialParameters, ISO_DATE_FORMAT} from '../../constants';
import {createServerDate} from '../../services/utils';
import {getMockCurrentDate} from '../../utils';

const mockedCurrentDate = getMockCurrentDate();
const nowAest = mockedCurrentDate ? createServerDate(new Date(mockedCurrentDate)) : createServerDate();

export const defaultPricingParams = {
  dob: format(subYears(new Date(), DefaultInitialParameters.YearsOfAge), ISO_DATE_FORMAT),
  excess: DefaultInitialParameters.Excess,
  scale: Scale[DefaultInitialParameters.Scale],
  state: State[DefaultInitialParameters.State],
  paymentFrequency: PaymentFrequency[DefaultInitialParameters.PaymentFrequency],
  previousCover: true,
  partnerPreviousCover: true,
  effectiveDate: format(nowAest, ISO_DATE_FORMAT),
  firstPaymentDate: format(nowAest, ISO_DATE_FORMAT),
  dateJoinedFund: format(nowAest, ISO_DATE_FORMAT)
};
