import type {FinancialDetails, JoinProgress, PaymentFrequency, CoverStartDate} from '@nib/types-session-api';
import {ARHIJoinState} from '../../rootReducer';
import _get from 'lodash/get';

export const selectData = (state: ARHIJoinState): FinancialDetails => _get(state, 'session.session.financialDetails') || ({} as FinancialDetails);

export const selectJoinProgressData = (state: ARHIJoinState): JoinProgress => _get(state, 'session.session.joinProgress') || ({} as JoinProgress);

export const getPaymentStartDate = (state: ARHIJoinState) => selectData(state).paymentStartDate;

export const getPaymentFrequency = (state: ARHIJoinState) => selectData(state).paymentFrequency || ('Weekly' as PaymentFrequency);
export const getPaymentMethod = (state: ARHIJoinState) => selectData(state).paymentMethod;
export const getCoverStartDateEntry = (state: ARHIJoinState) => selectData(state).coverStartDate || ({} as CoverStartDate);

export const getFundRulesJoinAgreement = (state: ARHIJoinState) => Boolean(selectJoinProgressData(state).hasAgreedToNibFundRules);
export const getHasAcceptedPrivacyPolicyAgreement = (state: ARHIJoinState) => Boolean(selectJoinProgressData(state).hasAcceptedPrivacyPolicyAgreement);
