import type {Session} from '@nib/types-session-api';
import {getTraceIdCookie} from '../../../services/cookies';
import {mapCorporateDataToSession} from './company';
export interface SessionInitialValues {
  company?: Session['company'];
  clientTimeStamp?: string;
  traceId?: string;
}

export const mapSessionInitialValues = (params, cookie): SessionInitialValues => {
  const initialValues: SessionInitialValues = {};
  if (!params.sourceCode && cookie && cookie.CompanyName && cookie.CorporateRateCode) {
    initialValues.company = mapCorporateDataToSession(cookie);
  }
  const traceId = getTraceIdCookie();
  if (traceId) {
    initialValues.traceId = traceId;
  }

  return initialValues;
};
