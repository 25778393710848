import type {Nullable} from '@nib/types-session-api';

import {ArhiSessionWithPayment} from '@nib/join-services-api';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {FailedRequestPayload, AxiosError} from '../types';

export interface JoinResult {
  joinId: string;
  policyNumber: number;
  kycUrl?: string;
  kycStatus?: string;
}

export interface JoinStatusState {
  submittingJoin?: boolean;
  fetchedJoin?: boolean;
}

export interface JoinState extends JoinStatusState {
  policyNumber?: Nullable<number>;
  joinId?: string;
  joinError?: Nullable<AxiosError | any>; //either axios response or custom message
  kycUrl?: string;
  kycStatus?: string;
}

export const initialState: JoinState = {
  policyNumber: undefined,
  joinId: undefined,
  submittingJoin: false,
  fetchedJoin: false,
  joinError: null
};

const joinSlice = createSlice({
  name: 'join',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    submitJoinPending: (state, action: PayloadAction<ArhiSessionWithPayment>) => {
      state.submittingJoin = true;
      state.fetchedJoin = false;
    },
    submitJoinSuccess: (state, action: PayloadAction<JoinResult>) => {
      const {joinId, policyNumber, kycUrl, kycStatus} = action.payload;
      state.submittingJoin = false;
      state.fetchedJoin = true;
      state.joinId = joinId;
      state.policyNumber = policyNumber ? policyNumber : null;
      state.kycUrl = kycUrl;
      state.kycStatus = kycStatus;
    },
    submitJoinRejected: (state, action: PayloadAction<FailedRequestPayload | any>) => {
      state.fetchedJoin = false;
      state.submittingJoin = false;
      state.joinError = action.payload;
    }
  }
});

export const {submitJoinPending, submitJoinSuccess, submitJoinRejected} = joinSlice.actions;

export default joinSlice.reducer;
