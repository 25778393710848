import type {Session} from '@nib/types-session-api';
import {produce} from 'immer';
import {AttributionDetails} from '../../types';

export const mapAttributionDetailsToSession = (values: AttributionDetails, session: Session): Session =>
  produce(session, (draftSession: Session) => {
    if (draftSession) {
      draftSession.campaignChannel = values.campaignChannel;
      draftSession.sourceCode = values.sourceCode;
      draftSession.company = undefined;
    }
  });
