import axios from 'axios';
import {stringify} from 'qs';
import {CampaignUrl} from '../../constants';
import {AxiosResponse, AxiosError} from '../types';
import {Offer, OfferParams} from './types';
import {ValidJoinServicesRequest, createAxiosError} from '../utils';
import {ImplementationDetail} from '@nib/phi-constants';
import {getCampaignPreviewCookie} from '../../services/cookies';

/**
 * Fetch the offer given offer params
 * @param   {object} parameters
 * @returns {Promise}
 */
export const fetch = (offerParams: OfferParams) => {
  const campaignPreviewCookie = getCampaignPreviewCookie();
  const requestParams: any = {
    validateStatus: ValidJoinServicesRequest
  };

  if (campaignPreviewCookie) {
    requestParams.headers = {
      [ImplementationDetail.CampaignPreviewCookieValueHeader]: campaignPreviewCookie
    };
  }

  return axios
    .get<Offer>(`${CampaignUrl}?${stringify(offerParams, {skipNulls: true})}`, requestParams)
    .then((res) => {
      const response: AxiosResponse<Offer> = {
        data: res.data,
        type: 'Offer'
      };
      return response;
    })
    .catch((error) => {
      const response: AxiosResponse<AxiosError> = {
        type: 'AxiosError',
        data: createAxiosError(error, 'GET')
      };
      return response;
    });
};
