import type {Nullable, Session} from '@nib/types-session-api';
import {add} from 'date-fns';

import Cookie, {CookieAttributes} from 'js-cookie';
import {isProd} from './sharedUtils';
import {CUSTOMER_COOKIE, NIB_BASE_COOKIE_DOMAIN, SESSION_COOKIE_VERSION, SESSION_NULL_ID, TRACE_ID_COOKIE} from '../constants';
import {isCorporateBrand, isDentalBrand, isLocal} from './utils';
import {ImplementationDetail} from '@nib/phi-constants';

export const encodeCookie = (value: unknown): string => {
  return btoa(JSON.stringify(value));
};

export const decodeCookie = (cookie: string): QuoteCookie => {
  return JSON.parse(atob(cookie.replace(/%3d/g, '')));
};

export interface QuoteCookie {
  QuoteId: Nullable<string>;
  Version?: Nullable<number>;
  CompanyCode?: Nullable<string>;
  CompanyName?: Nullable<string>;
  GroupId?: Nullable<string>;
  SectionId?: Nullable<string>;
  CorporateRateCode: string;
  CampaignCriteria?: Nullable<string>;
  ReferAFriend?: boolean;
}

export interface CustomerCookie {
  CustomerNumber: number;
  PolicyNumber: number;
  Fund: number;
}

export const getSessionCookieName = () => {
  if (isDentalBrand()) return 'DentalPassQuote';
  if (isCorporateBrand()) return 'CorporateQuote';
  if (isProd()) {
    return 'Quote';
  } else {
    return 'Quote_dev';
  }
};

export const getCookie = (name: string) => {
  return Cookie.get(name);
};

export const setCookie = (name: string, value: string, options: CookieAttributes) => {
  return Cookie.set(name, value, options);
};

export const getSessionCookie = (): QuoteCookie | void => {
  const cookie = getCookie(getSessionCookieName());
  if (cookie) {
    return decodeCookie(cookie);
  }
};

export const getTraceIdCookie = (): string | void => {
  const cookie = getCookie(TRACE_ID_COOKIE);
  if (cookie) {
    return cookie;
  }
};

export const getCampaignPreviewCookie = (): string | void => {
  const cookie = getCookie(ImplementationDetail.CampaignPreviewCookieName);
  if (cookie) {
    return cookie;
  }
};

export const getCookieSessionId = () => {
  const cookie = getSessionCookie();
  if (!cookie || !cookie.QuoteId || cookie.QuoteId === SESSION_NULL_ID) {
    return null;
  }
  return cookie.QuoteId;
};

export const removeSessionCookie = () => {
  // Dental and corporate cookie is set on parent domain, need to specify for removal operation
  if ((isDentalBrand() || isCorporateBrand()) && !isLocal()) {
    return Cookie.remove(getSessionCookieName(), {domain: NIB_BASE_COOKIE_DOMAIN, path: '/'});
  } else {
    return Cookie.remove(getSessionCookieName());
  }
};

export const removeCustomerCookie = () => {
  return Cookie.remove(CUSTOMER_COOKIE);
};

/**
 * Session expiry is in UNIX time w/ seconds, so we convert to a date object
 * @param expiry session expiry timestamp
 * @returns date object reflecting the expiry, or defaults to 90 days from now
 */
function getCookieExpiry(expiry?: number): Date {
  if (expiry) {
    return new Date(Math.floor(expiry * 1000));
  }
  console.log('$$$ - no expiry found');
  return add(new Date(), {days: 90});
}

export const createQuoteCookie = (session: Session) => {
  let newCookie;
  // Don't create a cookie for dental & corporate brands.
  // Cookie is already set but to the parent domain *.nib.com.au which will differ from the cookies created here dentalpass.nib.com.au
  // However, we'll still check if a cookie exists and create one as usual if one does not.
  if (isDentalBrand() || isCorporateBrand()) {
    const existingCookieData = getSessionCookie();
    if (!existingCookieData) {
      newCookie = encodeCookie({
        QuoteId: session.id,
        Version: SESSION_COOKIE_VERSION
      });
    } else {
      return;
    }
  } else {
    const existingCookieData = getSessionCookie();

    // Use the existing expiry from session to sync timestamp
    if (existingCookieData) {
      newCookie = encodeCookie({
        ...existingCookieData,
        QuoteId: session.id,
        Version: SESSION_COOKIE_VERSION
      });
    } else {
      newCookie = encodeCookie({
        QuoteId: session.id,
        Version: SESSION_COOKIE_VERSION
      });
    }
  }

  console.log('$$$ - setting cookie', session.expiryTimestamp);

  // Probably could just rely on the session expiring, but this minimises the 404 errors we'd typically see.
  // Therefore we make an attempt to sync them up as best we can.
  Cookie.set(getSessionCookieName(), newCookie, {expires: getCookieExpiry(Number(session.expiryTimestamp))});
};

export const removeCorporateFromQuoteCookie = (days = 90) => {
  const existingCookieData: QuoteCookie | void = getSessionCookie();

  if (existingCookieData) {
    const {QuoteId, ...rest} = existingCookieData;
    const newCookie = encodeCookie({
      ...rest,
      QuoteId: QuoteId,
      Version: SESSION_COOKIE_VERSION
    });
    Cookie.set(getSessionCookieName(), newCookie, {expires: days});
  }
};

export const createCostumerCookie = (customer: CustomerCookie) => {
  const newCookie = encodeCookie(customer);
  Cookie.set(CUSTOMER_COOKIE, newCookie);
};

export function hasCookie() {
  return !!getCookieSessionId();
}
