import TealiumUniversalTag from '@nib/tealium-universal-tag';
import {tealiumConfig} from './config';

const nibMetricsConfig = {
  vendors: [
    {
      name: 'Tealium Universal Tag',
      api: new TealiumUniversalTag({
        ...tealiumConfig,
        manualPageView: true
      })
    }
  ]
};

export default nibMetricsConfig;
