import type {Nullable, Session} from '@nib/types-session-api';
import {PaymentMethod, Selected} from '@nib/phi-constants';
import {produce} from 'immer';
import {PaymentDetailsValues} from '../../../components/Forms/PaymentDetails/types';
import {dateToApiDateString} from '../../../services/utils';
import {ARHIPageList, ARHIPageIndex, ISO_DATE_FORMAT} from '../../../constants';
import {parseISO, format, addDays, addYears} from 'date-fns';

export const mapPaymentDetailsToSession = (values: PaymentDetailsValues, session: Session, studentDependantMaxAge: Nullable<number>, isPageComplete?: boolean): Session =>
  produce(session, (draftSession: Session) => {
    draftSession.financialDetails.coverStartDate.date = values.coverStartDate ? dateToApiDateString(values.coverStartDate) : null;
    draftSession.financialDetails.paymentFrequency = values.paymentFrequency;

    draftSession.financialDetails.paymentStartDate = values.paymentStartDate ? dateToApiDateString(values.paymentStartDate) : null;
    const paymentMethod = values.paymentMethod;
    if (paymentMethod === PaymentMethod.CreditCard) {
      draftSession.financialDetails.paymentMethod = PaymentMethod.Braintree;
    } else {
      draftSession.financialDetails.paymentMethod = values.paymentMethod;
    }

    if (draftSession.joinProgress) {
      draftSession.joinProgress.hasAgreedToNibFundRules = values.fundRulesJoinAgreement;
    }

    if (isPageComplete && draftSession.funnelProgress) {
      draftSession.funnelProgress.lastCompletedPage = ARHIPageList[ARHIPageIndex.PAYMENT_DETAILS];
    }
    if (studentDependantMaxAge) {
      if (draftSession.personalDetails.dependants) {
        draftSession.personalDetails.dependants.forEach((dependant) => {
          if (dependant.dateOfBirth) {
            const depDOB = parseISO(dependant.dateOfBirth);
            const depDOBWithMaxAge = addYears(addDays(depDOB, -1), studentDependantMaxAge);
            const studentGraduationDefaultDate = format(depDOBWithMaxAge, ISO_DATE_FORMAT);
            dependant.graduationDate = !dependant.graduationDate && dependant.isStudentDependant === Selected.yes ? studentGraduationDefaultDate : dependant.graduationDate;
          }
        });
      }
    }
  });
