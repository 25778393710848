import {HttpMethod, AxiosError} from './types';

// Only accept requests to the session that return 2XX
export const ValidJoinServicesRequest = (status) => {
  return status >= 200 && status < 300;
};

// If response --> Request was made and the server responded
// If request --> no response (timeout)
export const createAxiosError = (error, method: HttpMethod): AxiosError => {
  const res: any = error.response;
  const req: any = error.request;
  const isAxiosError: boolean = error.isAxiosError;
  return {
    message: error.message,
    response: res,
    request: req,
    isAxiosError: isAxiosError,
    method: method
  };
};
