import type {HospitalProduct} from '@nib/types-session-api';
import {PayloadAction} from '@reduxjs/toolkit';
import {ARHIPageIndex, ARHIPageList} from '../../../constants';
import {FailedRequestPayload, SessionAndIsPageComplete} from '../../types';
import {SessionState} from '../sessionSlice';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const saveHospitalDetailsPendingReducer = (draftState: SessionState, action: PayloadAction<HospitalProduct>) => {
  draftState.updatingSession = true;
  draftState.sessionError = null;
};

export const saveHospitalDetailsRejectedReducer = (draftState: SessionState, action: PayloadAction<FailedRequestPayload>) => {
  const {error} = action.payload;
  draftState.sessionError = error;
  draftState.updatingSession = false;
};

export const saveHospitalDetailsSuccessReducer = (draftState: SessionState, action: PayloadAction<SessionAndIsPageComplete>) => {
  draftState.updatingSession = false;
  if (!draftState.session) {
    return;
  }

  draftState.fetchedSession = true;
  draftState.session.productSelection.hospital = action.payload.session.productSelection.hospital;

  if (action.payload.isPageComplete && draftState.session.funnelProgress) {
    draftState.session.funnelProgress.lastCompletedPage = ARHIPageList[ARHIPageIndex.HOSPITAL];
  }
};
