import {FailedRequestPayload} from './../types';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {FeatureTogglesState, Features} from './types';

const initialState: FeatureTogglesState = {
  loadingFeatureToggles: false,
  fetchedFeatureToggles: false,
  featureToggles: undefined,
  error: null
};

const featureTogglesSlice = createSlice({
  name: 'featureToggles',
  initialState,
  reducers: {
    fetchFeatureTogglesPending: (state) => {
      state.loadingFeatureToggles = true;
      state.fetchedFeatureToggles = false;
    },
    fetchFeatureTogglesSuccess: (state, action: PayloadAction<Features>) => {
      state.loadingFeatureToggles = false;
      state.fetchedFeatureToggles = true;
      state.featureToggles = action.payload;
    },
    fetchFeatureTogglesRejected: (state, action: PayloadAction<FailedRequestPayload>) => {
      const {error} = action.payload;
      state.error = error;
      state.loadingFeatureToggles = false;
      state.fetchedFeatureToggles = false;
    }
  }
});

export const {fetchFeatureTogglesPending, fetchFeatureTogglesRejected, fetchFeatureTogglesSuccess} = featureTogglesSlice.actions;

export default featureTogglesSlice.reducer;
