import {isNibBrand} from '../../../services/utils';
import {saveFormOnBlur, saveFormOnChange} from './eventHandlers';

export const AMBULANCE_LEVY_LINK = 'https://www.nib.com.au/the-checkup/how-much-does-it-cost-to-call-an-emergency-ambulance-in-my-state';

export const FORM_PREFIX = 'personal-details';
export const TEALIUM_FORM_NAME = 'arhi_join_personal_details';

export const FIELD_HELP_TEXT = {};

export const FIELD_IDS = {
  FIRST_NAME: `${FORM_PREFIX}-first-name`,
  LAST_NAME: `${FORM_PREFIX}-last-name`,
  TITLE: `${FORM_PREFIX}-title`,
  SEX: `${FORM_PREFIX}-sex`,
  DATE_OF_BIRTH: `${FORM_PREFIX}-date-of-birth`,
  COUNTRY_OF_CITIZENSHIP: `${FORM_PREFIX}-country-of-citizenship`,
  EMAIL: `${FORM_PREFIX}-email`,
  PHONE_NUMBER: `${FORM_PREFIX}-phone-number`,
  HOME_ADDRESS: `${FORM_PREFIX}-home-address`,
  ADDRESS_LINE: `${FORM_PREFIX}-address-line`,
  SUBURB: `${FORM_PREFIX}-suburb`,
  STATE: `${FORM_PREFIX}-state`,
  POSTCODE: `${FORM_PREFIX}-postcode`,
  PRIVACY_POLICY_AGREEMENT: `${FORM_PREFIX}-privacy-policy-agreement`
};

export const FIELD_NAMES = {
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  TITLE: 'title',
  SEX: 'gender',
  DATE_OF_BIRTH: 'dateOfBirth',
  COUNTRY_OF_CITIZENSHIP: 'countryOfCitizenship',
  EMAIL: 'email',
  PHONE_NUMBER: 'phoneNumber',
  HOME_ADDRESS: 'homeAddress',
  ADDRESS_LINE: 'addressLine',
  SUBURB: 'suburb',
  STATE: 'state',
  POSTCODE: 'postcode',
  TRANSFERRING_FUNDS: 'transferringFunds',
  PRIVACY_POLICY_AGREEMENT: 'privacyPolicyAgreement',
  CONTINUOUS_COVER: 'transferringFunds--continuousCover'
} as const;

export const getMailingAddressProps = (formikBag, updateSession) => {
  return {
    values: {
      'homeAddress--ausAddressLookup': formikBag.values['homeAddress--ausAddressLookup'],
      'homeAddress--streetAddress': formikBag.values['homeAddress--streetAddress'],
      'homeAddress--suburb': formikBag.values['homeAddress--suburb'],
      'homeAddress--postcode': formikBag.values['homeAddress--postcode'],
      'homeAddress--states': formikBag.values['homeAddress--states']
    },
    groupOnBlurHandlers: {
      'homeAddress--ausAddressLookup': saveFormOnBlur(formikBag, updateSession, 'homeAddress--ausAddressLookup'),
      'homeAddress--streetAddress': saveFormOnBlur(formikBag, updateSession),
      'homeAddress--suburb': saveFormOnBlur(formikBag, updateSession),
      'homeAddress--postcode': saveFormOnBlur(formikBag, updateSession)
    },
    groupOnChangeHandlers: {
      'homeAddress--states': saveFormOnChange(formikBag, updateSession)
    }
  };
};

export const getTransferringfundsProps = (formikBag, updateSession, newPrice, offerDisclaimer, enableContinuousCover) => {
  return {
    values: {
      transferringFunds: formikBag.values.transferringFunds,
      'transferringFunds--currentProvider': formikBag.values['transferringFunds--currentProvider'],
      'transferringFunds--memberNumber': formikBag.values['transferringFunds--memberNumber'],
      'transferringFunds--continuousCover': formikBag.values['transferringFunds--continuousCover']
    },
    groupOnBlurHandlers: {
      'transferringFunds--currentProvider': saveFormOnBlur(formikBag, updateSession, 'transferringFunds--currentProvider'),
      'transferringFunds--memberNumber': saveFormOnBlur(formikBag, updateSession),
      'transferringFunds--continuousCover': saveFormOnBlur(formikBag, updateSession)
    },
    groupOnChangeHandlers: {
      transferringFunds: saveFormOnChange(formikBag, updateSession)
    },
    id: 'transferringFunds',
    name: 'transferringFunds',
    newPrice,
    currentOfferTerms: offerDisclaimer,
    enableContinuousCover,
    showMemberInfoBoxForNibFund: isNibBrand(),
    width: 40
  };
};
