import type {GovernmentDetails, Nullable, PreviousFundDetails, Session} from '@nib/types-session-api';
import {produce} from 'immer';
import {IncomeTier, PaymentMethod as PaymentMethods, Selected} from '@nib/phi-constants';
import {format} from 'date-fns';
import {SelectedToNullableBoolean} from '../../../components/Forms/utils';
import {ISO_DATE_FORMAT} from '../../../constants';
import {PricingFactorsModalValues} from '../../../components/Forms/PricingFactors/types';
import {FIELD_NAMES} from '../../../components/Forms/PricingFactors/constants';
import {dependantsRequired, partnerRequired} from '../../../services/utils';
import {getExtendedScale} from '../../../utils';

export const mapPricingFactorsModalDetailsToSession = (values: PricingFactorsModalValues, session: Session): Session => {
  return produce(session, (draftSession) => {
    draftSession.personalDetails.policyHolder.dateOfBirth = values[FIELD_NAMES.DATE_OF_BIRTH];

    if (values[FIELD_NAMES.SCALE] && draftSession.personalDetails) {
      draftSession.personalDetails.hasPartner = partnerRequired(values[FIELD_NAMES.SCALE]) ? Selected.yes : Selected.no;
      draftSession.personalDetails.hasDependants = dependantsRequired(values[FIELD_NAMES.SCALE]) ? Selected.yes : Selected.no;
      draftSession.personalDetails.scale = getExtendedScale(values[FIELD_NAMES.SCALE], session.personalDetails.dependants);
    }

    if (session.personalDetails.hasPartner === Selected.yes && draftSession.personalDetails.partner) {
      draftSession.personalDetails.partner.dateOfBirth = values[FIELD_NAMES.PARTNER_DATE_OF_BIRTH];
    }

    if (values[FIELD_NAMES.STATE]) {
      if (draftSession.contactDetails.address) draftSession.contactDetails.address.state = values[FIELD_NAMES.STATE];
      else {
        draftSession.contactDetails.address = {
          state: values[FIELD_NAMES.STATE]
        };
      }
    }

    if (values[FIELD_NAMES.INCOME_TIER]) {
      if (draftSession.governmentDetails) {
        draftSession.governmentDetails.incomeTier = values[FIELD_NAMES.INCOME_TIER];
        draftSession.governmentDetails.applyGovernmentRebate = values[FIELD_NAMES.INCOME_TIER] !== IncomeTier.NoRebate;
      } else {
        draftSession.governmentDetails = {
          incomeTier: values[FIELD_NAMES.INCOME_TIER],
          applyGovernmentRebate: values[FIELD_NAMES.INCOME_TIER] !== IncomeTier.NoRebate
        } as GovernmentDetails;
      }
    }

    if (draftSession.governmentDetails) {
      let policyHolderTransferringFunds: Nullable<PreviousFundDetails> = {} as PreviousFundDetails;
      if (values[FIELD_NAMES.TRANSFERRING_FUNDS]) {
        policyHolderTransferringFunds = {
          previouslyHadHealthInsurance: SelectedToNullableBoolean(values[FIELD_NAMES.TRANSFERRING_FUNDS] || undefined),
          code: '',
          description: '',
          policyNumber: '',
          continuousCover: null,
          ageBasedDiscountAssessmentDate: format(new Date(), ISO_DATE_FORMAT)
        };
        draftSession.governmentDetails.policyHolderPreviousFundDetails = policyHolderTransferringFunds;
      }

      let partnerPreviousFundDetails: Nullable<PreviousFundDetails> = {} as PreviousFundDetails;
      if (values[FIELD_NAMES.PARTNER_TRANSFERRING_FUNDS]) {
        partnerPreviousFundDetails = {
          previouslyHadHealthInsurance: SelectedToNullableBoolean(values[FIELD_NAMES.PARTNER_TRANSFERRING_FUNDS] || undefined),
          code: '',
          description: '',
          policyNumber: '',
          continuousCover: null,
          ageBasedDiscountAssessmentDate: format(new Date(), ISO_DATE_FORMAT)
        };
        draftSession.governmentDetails.partnerPreviousFundDetails = partnerPreviousFundDetails;
      }
    }

    if (values[FIELD_NAMES.PAYMENT_METHOD] === PaymentMethods.CreditCard) {
      draftSession.financialDetails.paymentMethod = PaymentMethods.Braintree;
    } else {
      draftSession.financialDetails.paymentMethod = values[FIELD_NAMES.PAYMENT_METHOD];
    }
  });
};
