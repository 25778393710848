import {Scale, TScales} from '@nib/phi-constants';
import {ARHIPageIndex, ARHIPageList} from '../../constants';
import {isDentalBrand} from '../../services/utils';
import {ARHIFunnelPage, ARHIPage} from '../../types/pages';
import {AssessmentGraphicIcon, PriceGraphicIcon, HospitalGraphicIcon, ExtrasGraphicIcon, CoupleGraphicIcon, FamilyGraphicIcon, SingleGraphicIcon, IconType} from '@nib/icons';
import {withPrefix} from 'gatsby';

export interface ProgressStep {
  title: string;
  isAccessed: boolean;
  isDisabled: boolean;
  isComplete: boolean;
  href: string;
  icon: IconType;
}

/**
 * Regardless of the scale value, the stepper will always have the same indexes for the following pages:
 */
export enum KnownStepperIndexes {
  HOSPITAL = 0,
  EXTRAS = 1,
  PERSONAL_DETAILS = 2
}

/**
 * Each of the potential steps
 */
const HOSPITAL_STEP: ProgressStep = {
  title: ARHIPageList[ARHIPageIndex.HOSPITAL].title,
  isAccessed: true,
  isDisabled: false,
  isComplete: false,
  href: withPrefix(ARHIPageList[ARHIPageIndex.HOSPITAL].link),
  icon: HospitalGraphicIcon
};

const EXTRAS_STEP: ProgressStep = {
  title: ARHIPageList[ARHIPageIndex.EXTRAS].title,
  isAccessed: true,
  isDisabled: false,
  isComplete: false,
  href: withPrefix(ARHIPageList[ARHIPageIndex.EXTRAS].link),
  icon: ExtrasGraphicIcon
};

const PERSONAL_DETAILS_STEP: ProgressStep = {
  title: ARHIPageList[ARHIPageIndex.PERSONAL_DETAILS].title,
  isAccessed: false,
  isDisabled: false,
  isComplete: false,
  href: withPrefix(ARHIPageList[ARHIPageIndex.PERSONAL_DETAILS].link),
  icon: SingleGraphicIcon
};

const FAMILY_STEP: ProgressStep = {
  title: ARHIPageList[ARHIPageIndex.FAMILY_DETAILS].title,
  isAccessed: false,
  isDisabled: false,
  isComplete: false,
  href: withPrefix(ARHIPageList[ARHIPageIndex.FAMILY_DETAILS].link),
  icon: FamilyGraphicIcon
};

const COUPLE_STEP: ProgressStep = {
  title: ARHIPageList[ARHIPageIndex.PARTNER_DETAILS].title,
  isAccessed: false,
  isDisabled: false,
  isComplete: false,
  href: withPrefix(ARHIPageList[ARHIPageIndex.PARTNER_DETAILS].link),
  icon: CoupleGraphicIcon
};

const REBATE_STEP: ProgressStep = {
  title: ARHIPageList[ARHIPageIndex.AUSTRALIAN_GOVERNMENT_REBATE].title,
  isAccessed: false,
  isDisabled: false,
  isComplete: false,
  href: withPrefix(ARHIPageList[ARHIPageIndex.AUSTRALIAN_GOVERNMENT_REBATE].link),
  icon: AssessmentGraphicIcon
};

const PAYMENT_STEP: ProgressStep = {
  title: ARHIPageList[ARHIPageIndex.PAYMENT_DETAILS].title,
  isAccessed: false,
  isDisabled: false,
  isComplete: false,
  href: withPrefix(ARHIPageList[ARHIPageIndex.PAYMENT_DETAILS].link),
  icon: PriceGraphicIcon
};

export const getPageStepIndex = (title: string, progressSteps: ProgressStep[]) => {
  let stepIndex = -1;

  for (let i = 0; i < progressSteps.length; i++) {
    if (progressSteps[i].title === title) {
      stepIndex = i;
      break;
    }
  }

  return stepIndex;
};

export const clone = (obj: any) => Object.assign({}, obj);

export const getProgressStepsForFamilyScale = () => {
  return [clone(HOSPITAL_STEP), clone(EXTRAS_STEP), clone(PERSONAL_DETAILS_STEP), clone(FAMILY_STEP), clone(REBATE_STEP), clone(PAYMENT_STEP)];
};

export const getProgressStepsForCoupleScale = () => {
  return [clone(HOSPITAL_STEP), clone(EXTRAS_STEP), clone(PERSONAL_DETAILS_STEP), clone(COUPLE_STEP), clone(REBATE_STEP), clone(PAYMENT_STEP)];
};

export const getProgressStepsForSingleScale = () => {
  return [clone(HOSPITAL_STEP), clone(EXTRAS_STEP), clone(PERSONAL_DETAILS_STEP), clone(REBATE_STEP), clone(PAYMENT_STEP)];
};

export const getProgressStepsForDentalPass = () => {
  return [clone(PERSONAL_DETAILS_STEP), clone(REBATE_STEP), clone(PAYMENT_STEP)];
};

export interface ProgressStepsArguments {
  scale: TScales;
  extrasRequired: boolean;
  currentPage: ARHIFunnelPage;
  lastCompletedPage: ARHIPage;
  hasSelectedHospitalProduct: boolean;
  hasSelectedExtrasProduct: boolean;
}

/**
 * Given the current scale, return the appropriate progress steps
 * @param scale
 * @param extrasRequired
 * @returns
 */
export const getProgressSteps = (scale?: TScales): ProgressStep[] => {
  if (isDentalBrand()) {
    return getProgressStepsForDentalPass();
  }
  switch (scale) {
    case Scale.Single:
      return getProgressStepsForSingleScale();
    case Scale.Couple:
      return getProgressStepsForCoupleScale();
    case Scale.Family:
    case Scale.ExtendedFamily:
    case Scale.SingleParentFamily:
      return getProgressStepsForFamilyScale();
    default:
      return getProgressStepsForSingleScale();
  }
};

export const enableStep = (step: ProgressStep) => {
  step.isDisabled = false;
  step.isAccessed = true;
};

export const disableStep = (step: ProgressStep) => {
  step.isDisabled = true;
  step.isAccessed = false;
};

/**
 * Given the current page and the last completed page, determine if the provided step should be marked as accessed
 */
export const shouldPageBeMarkedAccessed = (lastCompletedPageIndex: number, currentPageStepIndex: number, stepIndex: number) => {
  const lastCompletedPageIndexInclActive = lastCompletedPageIndex + 1;
  // Either a page is marked as accessed because the "LastCompletedPage" from the session indicates it or a dependancy was completed or
  // or in the absense of this, the current step index is a precursor page to the current page.
  return (lastCompletedPageIndexInclActive >= currentPageStepIndex && stepIndex <= lastCompletedPageIndexInclActive) || stepIndex <= currentPageStepIndex;
};

/**
 * Given the appropriate progress steps for the selected scale, impart the current context of the funnel (selected products, progress etc.) onto the steps
 * @param progressSteps
 * @param params
 */
export const transformProgressStepsForState = (progressSteps: ProgressStep[], params: ProgressStepsArguments): ProgressStep[] => {
  const {
    currentPage: {title},
    extrasRequired,
    hasSelectedHospitalProduct,
    hasSelectedExtrasProduct,
    lastCompletedPage
  } = params;
  const currentPageStepIndex = getPageStepIndex(title, progressSteps);
  const lastCompletedPageIndex = getPageStepIndex(lastCompletedPage.title || '', progressSteps);

  const structure = progressSteps.slice();
  for (let i = 0; i < structure.length; i++) {
    const step = structure[i];
    // Specialised behaviour for the products pages and personal details
    if (currentPageStepIndex < KnownStepperIndexes.PERSONAL_DETAILS && !isDentalBrand()) {
      const onProductSelectionPage = title === ARHIPageList[ARHIPageIndex.HOSPITAL].title || title === ARHIPageList[ARHIPageIndex.EXTRAS].title;

      const hasFulfilledProductRequirement = extrasRequired ? hasSelectedHospitalProduct && hasSelectedExtrasProduct : hasSelectedHospitalProduct || hasSelectedExtrasProduct;

      // if requirements not met - do not allow progression to personal details
      if (onProductSelectionPage && !hasFulfilledProductRequirement) {
        disableStep(structure[KnownStepperIndexes.PERSONAL_DETAILS]);
        // otherwise, allow progression to personal details/steps to be skipped
      } else {
        enableStep(structure[KnownStepperIndexes.PERSONAL_DETAILS]);
      }

      if (shouldPageBeMarkedAccessed(lastCompletedPageIndex, currentPageStepIndex, i)) {
        enableStep(step);
      }
    } else {
      // All other pages, apply stepper progress with respect to the current page and last completed page (if any)
      if (shouldPageBeMarkedAccessed(lastCompletedPageIndex, currentPageStepIndex, i)) {
        enableStep(step);
      } else {
        disableStep(step);
      }
    }
  }

  return structure;
};

/**
 * Derive the closest possible state of the Stepper without the available context of the funnel (selected products, progress etc.)
 * this information comes from the session, which is not available at build time.
 *
 * Performing this work reduces the jarring effect of the stepper updating once the session is available.
 *
 * @param progressSteps
 * @param currentPage
 * @returns
 */
export const transformProgressStepsForBuildTime = (progressSteps: ProgressStep[], currentPage: ARHIFunnelPage): ProgressStep[] => {
  const currentPageStepIndex = getPageStepIndex(currentPage.title, progressSteps);

  const structure = progressSteps.slice();
  for (let i = 0; i < structure.length; i++) {
    const step = structure[i];
    if (i > currentPageStepIndex) {
      disableStep(step);
    } else {
      enableStep(step);
    }
  }

  return structure;
};
