import {FIELD_NAMES as Rebate} from '../../../components/Forms/AustralianGovernmentRebate/constants';
import {FIELD_NAMES as Partner} from '../../../components/Forms/FamilyDetails/constants';
import {FIELD_NAMES as Payment} from '../../../components/Forms/PaymentDetails/constants';
import {FIELD_NAMES as Personal} from '../../../components/Forms/PersonalDetails/constants';
import {FIELD_NAMES as Welcome} from '../../../components/Forms/Welcome/constants';
import {FIELD_NAMES as Ribbon} from '../../../components/Ribbon/constants';
import {ARHIJoinState} from '../../rootReducer';
import {getState} from '../../session/selectors/contactDetails';
import {getCoverStartDate} from '../../session/selectors/financialDetails';
import {
  getIncomeTier,
  getPartnerPreviouslyHadHealthInsurance,
  getPolicyHolderPreviouslyHadHealthInsurance,
  getRebateIsRequested,
  getCoveredByPolicy,
  getAllEntitledToMedicare,
  getMedicareCardType,
  getPolicyHolderContinuousCover,
  getPartnerContinuousCover
} from '../../session/selectors/governmentDetails';
import {getPaymentFrequency, getPaymentMethod, getPaymentStartDate} from '../../session/selectors/paymentDetails';
import {getPartnerDob, getPolicyHolderDob, getScale, getHasPartner} from '../../session/selectors/personalDetails';
import {getExcess, getExtrasId, getHospitalId} from './productSelection';
import {getSessionId} from '.';

export const getPricingWatchedValues = (state: ARHIJoinState): PricingWatchedValues => ({
  sessionId: getSessionId(state),
  [Personal.DATE_OF_BIRTH]: getPolicyHolderDob(state),
  [Personal.HOME_ADDRESS]: getState(state),
  [Personal.TRANSFERRING_FUNDS]: getPolicyHolderPreviouslyHadHealthInsurance(state),
  [Personal.CONTINUOUS_COVER]: getPolicyHolderContinuousCover(state),
  [Welcome.SCALE]: getScale(state),
  [Ribbon.EXCESS]: getExcess(state),
  [Ribbon.HAS_PARTNER]: getHasPartner(state),
  [Partner.DATE_OF_BIRTH]: getPartnerDob(state),
  [Partner.TRANSFERRING_FUNDS]: getPartnerPreviouslyHadHealthInsurance(state),
  [Partner.CONTINUOUS_COVER]: getPartnerContinuousCover(state),
  [Rebate.ANNUAL_INCOME]: getRebateIsRequested(state) && getIncomeTier(state),
  [Rebate.COVERED_BY_POLICY]: getCoveredByPolicy(state),
  [Rebate.MEDICARE_ALL_COVERED_RADIO]: getAllEntitledToMedicare(state),
  [Rebate.MEDICARE_CARD_TYPE]: getMedicareCardType(state),
  [Payment.COVER_START_DATE]: getCoverStartDate(state),
  [Payment.PAYMENT_FREQUENCY]: getPaymentFrequency(state),
  [Payment.PAYMENT_METHOD]: getPaymentMethod(state),
  [Payment.PAYMENT_START_DATE]: getPaymentStartDate(state),
  hospitalProductId: getHospitalId(state),
  extrasProductId: getExtrasId(state)
});

export type PricingWatchedValues = {
  sessionId: ReturnType<typeof getSessionId>;
  [Personal.DATE_OF_BIRTH]: ReturnType<typeof getPolicyHolderDob>;
  [Personal.HOME_ADDRESS]: ReturnType<typeof getState>;
  [Personal.TRANSFERRING_FUNDS]: ReturnType<typeof getPolicyHolderPreviouslyHadHealthInsurance>;
  [Personal.CONTINUOUS_COVER]: ReturnType<typeof getPolicyHolderContinuousCover>;
  [Welcome.SCALE]: ReturnType<typeof getScale>;
  [Ribbon.EXCESS]: ReturnType<typeof getExcess>;
  [Ribbon.HAS_PARTNER]: ReturnType<typeof getHasPartner>;
  [Partner.DATE_OF_BIRTH]: ReturnType<typeof getPartnerDob>;
  [Partner.TRANSFERRING_FUNDS]: ReturnType<typeof getPartnerPreviouslyHadHealthInsurance>;
  [Partner.CONTINUOUS_COVER]: ReturnType<typeof getPartnerContinuousCover>;
  [Rebate.ANNUAL_INCOME]: ReturnType<typeof getRebateIsRequested> | ReturnType<typeof getIncomeTier>;
  [Rebate.COVERED_BY_POLICY]: ReturnType<typeof getCoveredByPolicy>;
  [Rebate.MEDICARE_ALL_COVERED_RADIO]: ReturnType<typeof getAllEntitledToMedicare>;
  [Rebate.MEDICARE_CARD_TYPE]: ReturnType<typeof getMedicareCardType>;
  [Payment.COVER_START_DATE]: ReturnType<typeof getCoverStartDate>;
  [Payment.PAYMENT_FREQUENCY]: ReturnType<typeof getPaymentFrequency>;
  [Payment.PAYMENT_METHOD]: ReturnType<typeof getPaymentMethod>;
  [Payment.PAYMENT_START_DATE]: ReturnType<typeof getPaymentStartDate>;
  hospitalProductId: ReturnType<typeof getHospitalId>;
  extrasProductId: ReturnType<typeof getExtrasId>;
};
