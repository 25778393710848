import _get from 'lodash/get';
import {createSelector} from 'reselect';
import {ARHIJoinState} from '../rootReducer';
import {mapPreviousFundsToDls} from './mappers';
import {DlsPreviousFund, PreviousFundsState, PreviousFund} from './previousFundsSlice';
import {Dictionary} from '../../types/common';

export const getPreviousFunds = (state: ARHIJoinState): PreviousFundsState => _get(state, 'previousFunds', {} as PreviousFundsState);
export const getPreviousFundsList = (state: ARHIJoinState): PreviousFund[] => getPreviousFunds(state).previousFunds;

export const getPreviousFundsForDls = createSelector<ARHIJoinState, PreviousFund[], DlsPreviousFund[]>(getPreviousFundsList, (list) => {
  return mapPreviousFundsToDls(list);
});

export const getPreviousFundsMap = createSelector<ARHIJoinState, PreviousFundsState, Dictionary<string>>(getPreviousFunds, (slice) => {
  return slice.previousFunds.reduce((map, fund) => {
    map[fund.Name] = fund.Code;
    return map;
  }, {});
});
