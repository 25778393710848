import * as api from './api';
import {fetchPreviousFundsPending, fetchPreviousFundsSuccess, fetchPreviousFundsRejected} from './previousFundsSlice';
import {AppThunk} from '../store';

export const fetchPreviousFunds = (): AppThunk => async (dispatch) => {
  // Fetch pending with default params or the session if the values exist
  dispatch(fetchPreviousFundsPending());

  // Call the api
  try {
    const results = await api.fetchPreviousFunds();

    if (results) {
      dispatch(fetchPreviousFundsSuccess(results));
    }
  } catch (exception) {
    dispatch(fetchPreviousFundsRejected(exception));
  }
};
