import type {Session, GovernmentRebateAcknowledgement, Nullable} from '@nib/types-session-api';

import {IncomeTier, MedicareCardType} from '@nib/phi-constants';
import {produce} from 'immer';
import {GovernmentRebateValues} from '../../../components/Forms/AustralianGovernmentRebate/types';
import {formatDateToISO, isIsoDateStringFormat, isOver18} from '../../../utils';
import {SelectedToNullableBoolean} from '../../../components/Forms/utils';
import {format} from 'date-fns';
import {ARHIPageList, ARHIPageIndex, ISO_DATE_FORMAT} from '../../../constants';
import {isBoolean} from '../../../services/utils';

export const mapGovernmentRebateAcknowledgementToSession = (values: GovernmentRebateValues): GovernmentRebateAcknowledgement => ({
  areYouCoveredByThisPolicy: SelectedToNullableBoolean(values.coveredByPolicy),
  areAllPeopleOnPolicyEntitledToMedicare: SelectedToNullableBoolean(values.medicareAllCoveredRadio),
  medicareCardIsValid: values.medicareCardType !== MedicareCardType.None,
  rebateIsRequested: values.incomeTier !== IncomeTier.NoRebate,
  declarationAllDetailsCorrect: Boolean(values.declaration),
  declarationDate: format(new Date(), ISO_DATE_FORMAT)
});

export const mapApplyGovernmentRebate = (rebateAcknowledge: Nullable<GovernmentRebateAcknowledgement>): boolean => {
  if (!rebateAcknowledge) {
    return true;
  }

  //the as boolean shouldn't be needed, but typescript is being a bit weird
  const rebateIsRequired = isBoolean(rebateAcknowledge.rebateIsRequested) ? (rebateAcknowledge.rebateIsRequested as boolean) : true;
  const youCovered = isBoolean(rebateAcknowledge.areYouCoveredByThisPolicy) ? (rebateAcknowledge.areYouCoveredByThisPolicy as boolean) : true;
  const allEntitledMedicare = isBoolean(rebateAcknowledge.areAllPeopleOnPolicyEntitledToMedicare) ? (rebateAcknowledge.areAllPeopleOnPolicyEntitledToMedicare as boolean) : true;
  const medicareCardIsValid = isBoolean(rebateAcknowledge.medicareCardIsValid) ? (rebateAcknowledge.medicareCardIsValid as boolean) : true;

  return rebateIsRequired && youCovered && allEntitledMedicare && medicareCardIsValid;
};

export const mapRebateDetailsToSession = (values: GovernmentRebateValues, session: Session, isPageComplete?: boolean): Session =>
  produce(session, (draftSession) => {
    if (draftSession.governmentDetails) {
      const isOver18YearsOld = isOver18(session.personalDetails?.policyHolder?.dateOfBirth, session.financialDetails?.coverStartDate?.date);

      draftSession.governmentDetails.incomeTier = isOver18YearsOld ? values.incomeTier : IncomeTier.NoRebate;
      draftSession.governmentDetails.medicareNumber = values.medicareNumber;
      draftSession.governmentDetails.medicareCardType = values.medicareCardType;
      draftSession.governmentDetails.governmentRebateAcknowledgement = mapGovernmentRebateAcknowledgementToSession(values);
      draftSession.governmentDetails.applyGovernmentRebate = isOver18YearsOld ? mapApplyGovernmentRebate(draftSession.governmentDetails.governmentRebateAcknowledgement) : false;

      // with the way auto save works, this might already be in iso format due to a use case where our formik pre save helper is grabbing the "initial value"
      // from formik (which in the container already maps from an iso format to work with the component)
      draftSession.governmentDetails.medicareExpiry = values.medicareExpiry && (isIsoDateStringFormat(values.medicareExpiry) ? values.medicareExpiry : formatDateToISO(values.medicareExpiry));

      if (values.medicareCardType === MedicareCardType.None) {
        draftSession.governmentDetails.medicareNumber = null;
        draftSession.governmentDetails.medicareExpiry = null;
        draftSession.governmentDetails.governmentRebateAcknowledgement = null;
      }
    }

    if (isPageComplete && draftSession.funnelProgress) {
      draftSession.funnelProgress.lastCompletedPage = ARHIPageList[ARHIPageIndex.AUSTRALIAN_GOVERNMENT_REBATE];
    }
  });
