import type {GovernmentDetails, Session} from '@nib/types-session-api';
import {produce} from 'immer';
import {FIELD_NAMES as FN} from '../../../components/Modals/AnnualIncomeModal/constants';
import {IncomeTier, Selected} from '@nib/phi-constants';
import {AnnualIncomeFormValues} from '../../../components/Modals/AnnualIncomeModal/type';

export const mapAnnualIncomeDetailsToSession = (values: AnnualIncomeFormValues, session: Session): Session => {
  return produce(session, (draftSession) => {
    draftSession.personalDetails.policyHolder.dateOfBirth = values[FN.DATE_OF_BIRTH];

    if (session.personalDetails.hasPartner === Selected.yes && draftSession.personalDetails.partner) {
      draftSession.personalDetails.partner.dateOfBirth = values[FN.PARTNER_DATE_OF_BIRTH];
    }

    if (values.incomeTierModal) {
      if (draftSession.governmentDetails) {
        draftSession.governmentDetails.incomeTier = values.incomeTierModal;
        draftSession.governmentDetails.applyGovernmentRebate = values.incomeTierModal !== IncomeTier.NoRebate;
      } else {
        draftSession.governmentDetails = {
          incomeTier: values.incomeTierModal,
          applyGovernmentRebate: values.incomeTierModal !== IncomeTier.NoRebate
        } as GovernmentDetails;
      }
    }
  });
};
