import {FailedRequestPayload} from './../types';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export interface PreviousFund {
  Code: string;
  Name: string;
}

export interface DlsPreviousFund {
  value: string; // mapping from code
  text: string; // mapping from name
}

const initialState: PreviousFundsState = {
  loadingFunds: false,
  fetchedFunds: false,
  previousFunds: [],
  error: null
};

export interface PreviousFundsState {
  previousFunds: PreviousFund[];
  loadingFunds: boolean;
  fetchedFunds: boolean;
  error: any;
}

const previousFundsSlice = createSlice({
  name: 'previousFunds',
  initialState,
  reducers: {
    fetchPreviousFundsPending: (state) => {
      state.loadingFunds = true;
      state.fetchedFunds = false;
    },
    fetchPreviousFundsSuccess: (state, action: PayloadAction<PreviousFund[]>) => {
      state.loadingFunds = false;
      state.fetchedFunds = true;
      state.previousFunds = action.payload;
    },
    fetchPreviousFundsRejected: (state, action: PayloadAction<FailedRequestPayload>) => {
      const {error} = action.payload;
      state.error = error;
      state.loadingFunds = false;
      state.fetchedFunds = false;
    }
  }
});

export const {fetchPreviousFundsPending, fetchPreviousFundsSuccess, fetchPreviousFundsRejected} = previousFundsSlice.actions;

export default previousFundsSlice.reducer;
