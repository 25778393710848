import type {GovernmentDetails, Session} from '@nib/types-session-api';
import {PayloadAction} from '@reduxjs/toolkit';
import {FamilyDetailsValues} from '../../../components/Forms/FamilyDetails/types';
import {FailedRequestPayload} from '../../types';
import {SessionState} from '../sessionSlice';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const saveFamilyDetailsFormPendingReducer = (draftState: SessionState, action: PayloadAction<FamilyDetailsValues>) => {
  draftState.updatingSession = true;
  draftState.sessionError = null;
};

export const saveFamilyDetailsFormRejectedReducer = (draftState: SessionState, action: PayloadAction<FailedRequestPayload>) => {
  const {error} = action.payload;
  draftState.sessionError = error;
  draftState.updatingSession = false;
};

export const saveFamilyDetailsFormSuccessReducer = (draftState: SessionState, action: PayloadAction<Session>) => {
  const {partner, dependants, scale} = action.payload.personalDetails;
  const {partnerPreviousFundDetails, partnerCertifiedAgeOfEntry} = action.payload.governmentDetails || ({} as GovernmentDetails);

  draftState.updatingSession = false;
  if (!draftState.session) {
    return;
  }

  if (partner) {
    draftState.session.personalDetails.partner = partner;
    // If dob is empty it be should be defaulted so that pricing observer can watch values correctly.
    draftState.session.personalDetails.partner.dateOfBirth = partner.dateOfBirth || null;
  }
  if (draftState.session && draftState.session.governmentDetails) {
    draftState.session.governmentDetails.partnerPreviousFundDetails = partnerPreviousFundDetails;
    draftState.session.governmentDetails.partnerCertifiedAgeOfEntry = partnerCertifiedAgeOfEntry;
  }

  draftState.session.personalDetails.dependants = dependants;
  draftState.session.personalDetails.scale = scale;
};
