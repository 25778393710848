import type {CoverStartDate, FinancialDetails, Nullable, PaymentMethod as TPaymentMethod} from '@nib/types-session-api';
import {PaymentMethod, WHICS} from '@nib/phi-constants';
import _get from 'lodash/get';
import {ARHIJoinState} from '../../rootReducer';
import {getPaymentMethod} from './paymentDetails';
import {isGuHealthBrand} from '../../../services/utils';

export const getFinancialDetails = (state: ARHIJoinState): FinancialDetails => _get(state, 'session.session.financialDetails') || ({} as FinancialDetails);
export const getCoverStartDateData = (state: ARHIJoinState): CoverStartDate => getFinancialDetails(state).coverStartDate || ({} as CoverStartDate);
export const getCoverStartDate = (state: ARHIJoinState): string | null => getCoverStartDateData(state).date;
export const getIsCustomRate = (state: ARHIJoinState): null | boolean => getFinancialDetails(state).isCustomRate;
export const getRate = (state: ARHIJoinState): string | undefined => getFinancialDetails(state).rate || undefined;
export const getFirstPaymentDate = (state: ARHIJoinState): string | null | undefined => getFinancialDetails(state).paymentStartDate;
export const getRateDiscountPercentage = (state: ARHIJoinState): Nullable<number> | undefined => getFinancialDetails(state).rateDiscountPercentage;

const selectRateFromPaymentMethod = (paymentMethod: TPaymentMethod): string | undefined => {
  const rates = {
    [PaymentMethod.DirectDebit]: WHICS.ARHIRetailRateCode.DirectDebit,
    [PaymentMethod.CreditCard]: WHICS.ARHIRetailRateCode.CreditCard,
    [PaymentMethod.Braintree]: WHICS.ARHIRetailRateCode.CreditCard
  };

  return paymentMethod ? rates[paymentMethod] : undefined;
};

/**
 * If there isn't a rate code set, we set some defaults based on the payment method.
 * CreditCard = 0
 * DirectDebit = 4
 * CustomRate OR GuHealth = financialDetails.rate
 **/
export const getArhiRate = (state: ARHIJoinState) => {
  if (!getIsCustomRate(state) && !isGuHealthBrand()) {
    return selectRateFromPaymentMethod(getPaymentMethod(state));
  }
  return getRate(state);
};
