export const FORM_PREFIX = 'australian-government-rebate';
export const TEALIUM_FORM_NAME = 'arhi_join_australian_government_rebate';
export const OVERSEAS_VISITOR_KAOS_LINK = 'https://www.nib-cf-test.com/overseas-working-visitors/';
export const OVERSEAS_VISITOR_PROD_LINK = 'https://www.nib.com.au/overseas-working-visitors/';
export const WHITELABEL_OVERSEAS_VISITOR_LINK = 'https://www.privatehealth.gov.au/health_insurance/overseas/overseas_visitors_health_cover.htm';

export const FIELD_IDS = {
  ANNUAL_INCOME: `${FORM_PREFIX}-annual-income`,
  MEDICARE_NUMBER: `${FORM_PREFIX}-medicare-number`,
  MEDICARE_EXPIRY_DATE: `${FORM_PREFIX}-medicare-expiry-date`,
  MEDICARE_CARD_TYPE: `${FORM_PREFIX}-medicare-card-type`,
  COVERED_BY_POLICY: `${FORM_PREFIX}-covered-by-policy`,
  MEDICARE_ALL_COVERED_RADIO: `${FORM_PREFIX}-medicare-all-covered-radio`,
  DECLARATION: `${FORM_PREFIX}-declaration`
};

export const FIELD_NAMES = {
  ANNUAL_INCOME: 'incomeTier',
  MEDICARE_NUMBER: 'medicareNumber',
  MEDICARE_EXPIRY_DATE: 'medicareExpiry',
  MEDICARE_CARD_TYPE: 'medicareCardType',
  COVERED_BY_POLICY: 'coveredByPolicy',
  MEDICARE_ALL_COVERED_RADIO: 'medicareAllCoveredRadio',
  DECLARATION: 'declaration'
} as const;

export const FIELD_LABELS = {
  DECLARATION: 'I declare that the information I have provided is correct and I understand that there are penalties for giving false or misleading information.'
};
