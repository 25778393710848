import type {Session} from '@nib/types-session-api';

import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {FailedRequestPayload} from '../types';
import {SendQuoteState} from './types';

export const initialState: SendQuoteState = {
  sendingQuote: false,
  sentQuote: false,
  sendQuoteError: null
};

const sendQuoteSlice = createSlice({
  name: 'sendQuote',
  initialState,
  reducers: {
    sendQuotePending: (state) => {
      state.sendQuoteError = null;
      state.sendingQuote = true;
      state.sentQuote = false;
    },
    sendQuoteRejected: (state, action: PayloadAction<FailedRequestPayload>) => {
      state.sendQuoteError = action.payload.error;
      state.sendingQuote = false;
      state.sentQuote = false;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    sendQuoteSuccess: (state, action: PayloadAction<Session>) => {
      state.sentQuote = true;
      state.sendingQuote = false;
      state.sendQuoteError = null;
    },
    resetSendQuote: (state) => {
      state.sentQuote = false;
      state.sendingQuote = false;
      state.sendQuoteError = null;
    }
  }
});

export const {sendQuotePending, sendQuoteRejected, sendQuoteSuccess, resetSendQuote} = sendQuoteSlice.actions;

export default sendQuoteSlice.reducer;
