import {PreviousFund, DlsPreviousFund} from './previousFundsSlice';
import _orderBy from 'lodash/orderBy';

export const mapPreviousFundsToDls = (previousFunds: PreviousFund[]): DlsPreviousFund[] => {
  if (previousFunds && previousFunds.length > 0) {
    // Sort alphabetically by name
    previousFunds = _orderBy(previousFunds, [(previousFund) => previousFund.Name.toUpperCase()], ['asc']);

    return previousFunds.map((fund: PreviousFund): DlsPreviousFund => {
      return {value: fund.Name, text: fund.Name};
    });
  } else {
    return [];
  }
};
