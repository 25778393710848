import {PriceFactorGroup, PricingState} from './../pricingSlice';
import {PayloadAction} from '@reduxjs/toolkit';
import {FailedRequestPayload} from '../../types';
import {BatchPriceResults, PriceResultWithParams, PricingParams, ProductTypes} from '../types';
import {isPriceFactorInState} from '../utils';

const spreadPriceResultsOnDraftState = (draftState: PricingState, actionProductType: ProductTypes, actionPriceResults: PriceResultWithParams[]) => {
  actionPriceResults.forEach((productPriceResult) => {
    const productId = productPriceResult.priceResult[actionProductType].id;
    // If we have the product in our pricing products slice already with existing factor groups
    if (draftState.products[productId] && draftState.products[productId].pricesByFactors) {
      if (!isPriceFactorInState(draftState.products[productId].pricesByFactors, productPriceResult.params)) {
        // add new factory group
        draftState.products[productId].pricesByFactors.push({factors: productPriceResult.params, pricingInformation: productPriceResult.priceResult[actionProductType]});
      }
    } else {
      // first time call - add product id and the current factor
      const pricesByFactors: PriceFactorGroup[] = [{factors: productPriceResult.params, pricingInformation: productPriceResult.priceResult[actionProductType]}];
      draftState.products[productId] = {
        pricesByFactors
      };
    }
  });
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const fetchProductPricesPendingReducer = (draftState: PricingState, action: PayloadAction<PricingParams>) => {
  draftState.loadingProductPrices = true;
  draftState.fetchedProductPrices = false;
  draftState.productPricingError = null;
};

export const fetchProductPricesSuccessReducer = (draftState: PricingState, action: PayloadAction<{productPriceResults: PriceResultWithParams[]; productType: ProductTypes}>) => {
  draftState.loadingProductPrices = false;
  draftState.fetchedProductPrices = true;
  draftState.productPricingError = null;
  draftState.productPricingErrors = null;
  draftState.priceError = null;

  // grab relevant data off the action
  const actionPriceResults = action.payload.productPriceResults;
  const actionProductType = action.payload.productType;

  spreadPriceResultsOnDraftState(draftState, actionProductType, actionPriceResults);
};

export const fetchProductPricesRejectedReducer = (draftState: PricingState, action: PayloadAction<FailedRequestPayload | any>) => {
  const {error, errors} = action.payload;
  draftState.loadingProductPrices = false;
  draftState.fetchedProductPrices = false;
  draftState.productPricingErrors = errors;
  draftState.priceError = error || errors[0];
};

export const fetchProductPricesPartiallyRejectedReducer = (
  draftState: PricingState,
  action: PayloadAction<{productPriceResults: PriceResultWithParams[]; productType: ProductTypes; errors: FailedRequestPayload[] | any}>
) => {
  // grab relevant data off the action for successful fetches
  const actionPriceResults = action.payload.productPriceResults;
  const actionProductType = action.payload.productType;
  spreadPriceResultsOnDraftState(draftState, actionProductType, actionPriceResults);

  draftState.productPricingErrors = action.payload.errors;

  draftState.loadingProductPrices = false;
  draftState.fetchedProductPrices = true;
  draftState.priceError = null;
  draftState.productPricingError = action.payload.errors[0];
};

export const batchFetchProductPricesSuccessReducer = (
  draftState: PricingState,
  action: PayloadAction<{productPriceResults: BatchPriceResults; productType: ProductTypes; errors: FailedRequestPayload[] | any; pricingParams: PricingParams}>
) => {
  // grab relevant data off the action for successful fetches
  const {productPriceResults, productType, pricingParams} = action.payload;
  const productsWithParams = productPriceResults.data.map((p) => {
    return {
      params: {
        ...pricingParams,
        hospitalProduct: productType === ProductTypes.hospital ? p.hospital.id : null,
        extrasProduct: productType === ProductTypes.extras ? p.extras.id : null
      },
      priceResult: p
    };
  });
  spreadPriceResultsOnDraftState(draftState, productType, productsWithParams);

  draftState.loadingProductPrices = false;
  draftState.fetchedProductPrices = true;
  draftState.productPricingErrors = action.payload.errors;
  draftState.priceError = action.payload.errors.length > 0 ? action.payload.errors[0] : null;
};
