import type {Session} from '@nib/types-session-api';

import {PayloadAction} from '@reduxjs/toolkit';
import {FailedRequestPayload} from '../../types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getSessionPending = (draftState, action: PayloadAction<string>) => {
  draftState.loadingSession = true;
  draftState.fetchedSession = false;
  draftState.sessionError = null;
};

const getSessionSuccess = (draftState, action: PayloadAction<Session>) => {
  draftState.session = action.payload;
  draftState.loadingSession = false;
  draftState.fetchedSession = true;
  draftState.sessionError = null;
};

const getSessionRejected = (draftState, action: PayloadAction<FailedRequestPayload | any>) => {
  const {error} = action.payload;
  draftState.sessionError = error;
  draftState.loadingSession = false;
  draftState.fetchedSession = false;
};

export default {
  getSessionPending,
  getSessionSuccess,
  getSessionRejected
};
