import type {Session} from '@nib/types-session-api';
import {produce} from 'immer';
import {ARHIPage} from '../../../types/pages';

export const mapActivePageToSession = (page: ARHIPage, session: Session): Session =>
  produce(session, (draftSession: Session) => {
    if (draftSession.funnelProgress) draftSession.funnelProgress.activePage = page;
  });

export const mapLastCompletedPageToSession = (page: ARHIPage, session: Session): Session =>
  produce(session, (draftSession: Session) => {
    if (draftSession.funnelProgress) draftSession.funnelProgress.lastCompletedPage = page;
  });

export const mapFunnelProgressToSession = (session: Session, activePage?: ARHIPage, lastCompletedPage?: ARHIPage): Session =>
  produce(session, (draftSession: Session) => {
    if (draftSession.funnelProgress) {
      if (activePage) draftSession.funnelProgress.activePage = activePage;
      if (lastCompletedPage) draftSession.funnelProgress.lastCompletedPage = lastCompletedPage;
    }
  });
