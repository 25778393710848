import type {HospitalProduct, Session} from '@nib/types-session-api';
import {produce} from 'immer';
import {ARHIPageIndex, ARHIPageList} from '../../../constants';

export const mapHospitalDetailsToSession = (values: HospitalProduct, session: Session, isPageComplete?: boolean): Session => {
  const mappedSession = produce(session, (draftSession) => {
    if (values.excess && draftSession.productSelection) draftSession.productSelection.hospital.excess = values.excess;

    if (values.id && draftSession.productSelection) {
      draftSession.productSelection.hospital = values;
    } else {
      draftSession.productSelection.hospital = {
        id: null,
        name: null,
        shortName: null,
        excess: values.excess
      };
    }

    if (isPageComplete && draftSession.funnelProgress) {
      draftSession.funnelProgress.lastCompletedPage = ARHIPageList[ARHIPageIndex.HOSPITAL];
    }
  });
  return mappedSession;
};
