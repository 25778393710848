import type {PersonalDetails, PolicyHolder, Person} from '@nib/types-session-api';
import _get from 'lodash/get';
import {ARHIJoinState} from '../../rootReducer';

export const selectData = (state: ARHIJoinState): PersonalDetails => _get(state, 'session.session.personalDetails') || ({} as PersonalDetails);

export const getPolicyHolder = (state: ARHIJoinState) => selectData(state).policyHolder || ({} as PolicyHolder);
export const getPolicyHolderDob = (state: ARHIJoinState) => getPolicyHolder(state).dateOfBirth;
export const getPolicyHolderGender = (state: ARHIJoinState) => getPolicyHolder(state).gender;
export const getPolicyHolderFirstName = (state: ARHIJoinState) => getPolicyHolder(state).firstName;
export const getPolicyHolderLastName = (state: ARHIJoinState) => getPolicyHolder(state).lastName;
export const getPolicyHolderEmail = (state: ARHIJoinState) => getPolicyHolder(state).email || null;
export const getPolicyHolderTitle = (state: ARHIJoinState) => getPolicyHolder(state).title;

export const getHasPartner = (state: ARHIJoinState) => selectData(state).hasPartner;
export const getPartner = (state: ARHIJoinState) => selectData(state).partner || ({} as Person);
export const getPartnerDob = (state: ARHIJoinState) => getPartner(state).dateOfBirth;
export const getPartnerGender = (state: ARHIJoinState) => getPartner(state).gender;
export const getPartnerFirstName = (state: ARHIJoinState) => getPartner(state).firstName;
export const getPartnerLastName = (state: ARHIJoinState) => getPartner(state).lastName;
export const getPartnerTitle = (state: ARHIJoinState) => getPartner(state).title;

export const getScale = (state: ARHIJoinState) => selectData(state).scale;
export const getDependants = (state: ARHIJoinState) => selectData(state).dependants || ([] as Person[]);
export const getHasDependants = (state: ARHIJoinState) => selectData(state).hasDependants;
