import axios, {AxiosRequestConfig} from 'axios';
import {AxiosResponse, AxiosError} from '../types';
import {captureMessage} from '@sentry/gatsby';
import {featureToggleApiUrl} from '../../constants';
import {ValidJoinServicesRequest, createAxiosError} from '../utils';
import {Features} from './types';
import {getMockCurrentDate} from '../../utils';

/**
 * Fetch all nib feature toggles from apps-config-lambda
 */
export const fetchFeatureToggles = () => {
  const mockedCurrentDate = getMockCurrentDate();
  const options: AxiosRequestConfig = {
    validateStatus: ValidJoinServicesRequest,
    headers: mockedCurrentDate ? {'x-mock-current-date': mockedCurrentDate} : undefined
  };

  return axios
    .get<{data: Features}>(`${featureToggleApiUrl}`, options)
    .then((res) => {
      const response: AxiosResponse<Features> = {
        data: res.data.data,
        type: 'Features'
      };

      return response;
    })
    .catch((error) => {
      console.error('[AJW2] - Error fetching feature toggles -- ', error);
      captureMessage(`[AJW2] - Error fetching feature toggles -- ${error}`, 'error');
      const response: AxiosResponse<AxiosError> = {
        data: createAxiosError(error, 'GET'),
        type: 'AxiosError'
      };
      return response;
    });
};
