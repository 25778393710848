import {PricesByFactors, PricingProduct, PricingState} from './pricingSlice';
import {ARHIJoinState} from '../rootReducer';
import {PricingWatchedValues} from '../session/selectors/pricing';
import {createSelector} from 'reselect';
import _isEmpty from 'lodash/isEmpty';
import {PricingFactors} from './types';
import {mapSessionToPricingFactor} from './mappers';
import {getPricingFactorGroupThatMatchesCurrentState} from './utils';

export const getPricingDetails = (state: ARHIJoinState): PricingState => state.pricing;

export const getTotalPrice = (state: ARHIJoinState) => getPricingDetails(state).totalPrice;
export const getExtrasPrice = (state: ARHIJoinState) => getPricingDetails(state).extrasPrice;
export const getHospitalPrice = (state: ARHIJoinState) => getPricingDetails(state).hospitalPrice;
export const getPremiumForPeriod = (state: ARHIJoinState) => getPricingDetails(state).premiumForPeriod;

export const getIsLoadingPrice = (state: ARHIJoinState) => getPricingDetails(state).loadingPrice;
export const getHasFetchedPrice = (state: ARHIJoinState) => getPricingDetails(state).fetchedPrice;
export const getHasFetchedProductPrice = (state: ARHIJoinState) => getPricingDetails(state).fetchedProductPrices;
export const getPricingError = (state: ARHIJoinState) => getPricingDetails(state).priceError;
export const getProductPricingError = (state: ARHIJoinState) => getPricingDetails(state).productPricingError;
export const getProductPricingErrors = (state: ARHIJoinState) => getPricingDetails(state).productPricingErrors || [];
export const getPricingTriggerFields = (state: ARHIJoinState) => getPricingDetails(state).pricingTriggerFields;
export const getDiscountPercentApplicable = (state: ARHIJoinState) => getPricingDetails(state).discountPercentApplicable;

export const getFuturePricingDetails = (state: ARHIJoinState) => getPricingDetails(state).futurePrice;
export const getFuturePrice = (state: ARHIJoinState) => getFuturePricingDetails(state)?.totalPrice;
export const getFuturePricingError = (state: ARHIJoinState) => getFuturePricingDetails(state)?.priceError;
export const getIsLoadingFuturePrice = (state: ARHIJoinState) => getFuturePricingDetails(state)?.loadingPrice;
export const getPricingProducts = (state: ARHIJoinState) => state.pricing.products;

// Currently, this is using the first product returned from PCAT / the first product ID found
// Eventually, we should update this to filter based on the actual recommended product / suggested product after PHISL-3290
// *Note this is only used in discount alert component and there for it doesn't matter what product is used as we only care about the discount amount.
export const getRecommendedProductPricingInformation = (state: ARHIJoinState): PricingProduct | undefined => {
  const pricingProducts = getPricingProducts(state);

  if (!pricingProducts) return;

  const firstPricingProductId = Object.keys(getPricingProducts(state))[0];
  const firstPricingProduct = pricingProducts[firstPricingProductId];

  if (firstPricingProduct && firstPricingProduct.pricesByFactors) {
    const currentSessionFactors: PricingFactors = mapSessionToPricingFactor(state);
    const pricingFactorGroup = getPricingFactorGroupThatMatchesCurrentState(firstPricingProduct.pricesByFactors, currentSessionFactors);

    if (pricingFactorGroup) {
      return pricingFactorGroup.pricingInformation;
    }

    //This should hopefully never happen but if it does the best we can do is return the last unique price fetched from pricing api lambda
    return firstPricingProduct.pricesByFactors[firstPricingProduct.pricesByFactors.length - 1].pricingInformation;
  }
  return;
};

export const getFirstProductDiscountPercentApplicable = createSelector<ARHIJoinState, PricingProduct | undefined, string>(getRecommendedProductPricingInformation, (pricingProduct) => {
  if (pricingProduct) {
    return pricingProduct.discountPercentApplicable;
  }
  return '0.0';
});

export const hasProductsWithPrices = createSelector<ARHIJoinState, {[key: number]: PricesByFactors}, boolean>(getPricingProducts, (pricingProducts) => {
  return !_isEmpty(pricingProducts);
});

export const getNewPriceForField = (state: ARHIJoinState) => (fieldName: keyof PricingWatchedValues) => {
  const triggers = getPricingTriggerFields(state);

  if (triggers.includes(fieldName)) {
    return getTotalPrice(state);
  }
  return null;
};
