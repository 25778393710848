import {
  saveFormOnBlur,
  saveFormOnDOBChange,
  saveFormOnDependantsDOBChange,
  saveFormOnChange,
  saveFormDependantsOnChange,
  saveFormOnDependantRemoval,
  saveFormOnDependantsGraduationDateChange
} from './eventHandlers';
import {Selected} from '@nib/phi-constants';
import {getBrand} from '../../../services/utils';
import {FormikProps} from 'formik';
import {FamilyDetailsValues} from './types';

export const FORM_PREFIX = 'family-details';
export const TEALIUM_FORM_NAME = 'arhi_join_family_details';
export const STUDENT_EDUCATION_INSTITUTION = 'OTH';
export const STUDENT_DEPENDANT_MIN_AGE = 21;
export const STUDENT_DEPENDANT_MAX_AGE_25 = 25;
export const DEPENDANT_MAX_AGE = 31;

export const FIELD_NAMES = {
  DATE_OF_BIRTH: 'partnerDateOfBirth',
  TRANSFERRING_FUNDS: 'partnerTransferringFunds',
  CONTINUOUS_COVER: 'partnerTransferringFunds--continuousCover'
} as const;

export const getPartnerGroupProps = (formikBag, updateSession, previousFundsList, partnerDobNewPrice, partnerTransferringFundsNewPrice, SexHelpText, enableContinuousCover) => {
  return {
    values: {
      partnerTitle: formikBag.values.partnerTitle,
      partnerFirstName: formikBag.values.partnerFirstName,
      partnerLastName: formikBag.values.partnerLastName,
      partnerSex: formikBag.values.partnerSex,
      partnerDateOfBirth: formikBag.values.partnerDateOfBirth,
      partnerDateOfBirthDay: formikBag.values.partnerDateOfBirthDay,
      partnerDateOfBirthMonth: formikBag.values.partnerDateOfBirthMonth,
      partnerDateOfBirthYear: formikBag.values.partnerDateOfBirthYear,
      'partnerTransferringFunds--currentProvider': formikBag.values['partnerTransferringFunds--currentProvider'],
      'partnerTransferringFunds--memberNumber': formikBag.values['partnerTransferringFunds--memberNumber'],
      'partnerTransferringFunds--continuousCover': formikBag.values['partnerTransferringFunds--continuousCover'],
      partnerTransferringFunds: formikBag.values.partnerTransferringFunds,
      partnerPhone: formikBag.values.partnerPhone,
      partnerEmail: formikBag.values.partnerEmail
    },

    groupOnBlurHandlers: {
      partnerFirstName: saveFormOnBlur(formikBag, updateSession),
      partnerLastName: saveFormOnBlur(formikBag, updateSession),
      partnerDateOfBirth: saveFormOnDOBChange(formikBag, updateSession),
      partnerTransferringFunds: {
        'partnerTransferringFunds--currentProvider': saveFormOnBlur(formikBag, updateSession, 'partnerTransferringFunds--currentProvider'),
        'partnerTransferringFunds--memberNumber': saveFormOnBlur(formikBag, updateSession),
        'partnerTransferringFunds--continuousCover': saveFormOnBlur(formikBag, updateSession, 'partnerTransferringFunds--continuousCover')
      },
      partnerPhone: saveFormOnBlur(formikBag, updateSession),
      partnerEmail: saveFormOnBlur(formikBag, updateSession)
    },
    groupOnChangeHandlers: {
      partnerTitle: saveFormOnChange(formikBag, updateSession),
      partnerSex: saveFormOnChange(formikBag, updateSession),
      partnerTransferringFunds: {
        partnerTransferringFunds: saveFormOnChange(formikBag, updateSession)
      }
    },
    id: 'hasPartner',
    name: 'hasPartner',
    newPriceDateOfBirth: partnerDobNewPrice,
    newPriceTransferringFunds: partnerTransferringFundsNewPrice,
    subHeading: formikBag.values.hasDependants === Selected.yes && formikBag.values.hasPartner === Selected.yes ? 'Your partner' : '',
    partnerFirstNameLabel: 'First name',
    partnerLastNameLabel: 'Last name',
    addPartnerRadioLabel: 'Add a partner to your cover?',
    showPartnerTransferringFundsGroup: true,
    partnerNameMaxLength: 24,
    excludeAddPartnerRadio: true,
    showNestedLeftBorder: false,
    partnerLastNameMaxLength: getBrand() === 'ing' ? 40 : 60,
    currentProviderOptions: previousFundsList,
    currentProviderSuggestions: previousFundsList,
    partnerSexHelpText: SexHelpText,
    enableContinuousCover
  };
};

export const getDependantsGroupProps = (
  formikBag: FormikProps<FamilyDetailsValues>,
  updateSession,
  dependantValues,
  SexHelpTextDependant,
  policyPrice,
  minAdultDependantAge = 21,
  maxAdultDependantAge = 25,
  previousFundsList,
  policyHolderHasPreviousFund
) => {
  const childTransferringFunds = {
    showDependantTransferringFunds: true,
    currentProviderOptions: previousFundsList,
    currentProviderSuggestions: previousFundsList,
    policyHolderHasPreviousFund,
    partnerHasPreviousFund: Boolean(formikBag.values['partnerTransferringFunds--currentProvider']) && Boolean(formikBag.values['partnerTransferringFunds'])
  };

  return {
    ...childTransferringFunds,
    values: {
      dependants: dependantValues
    },
    currentProviderOptions: previousFundsList,
    currentProviderSuggestions: previousFundsList,
    groupOnBlurHandlers: {
      childFirstName: saveFormOnBlur(formikBag, updateSession),
      childLastName: saveFormOnBlur(formikBag, updateSession),
      childDateOfBirth: saveFormOnDependantsDOBChange(formikBag, updateSession),
      childTransferringFunds: {
        'childTransferringFunds--memberNumber': saveFormOnBlur(formikBag, updateSession)
      },
      graduationDate: saveFormOnDependantsGraduationDateChange(formikBag, updateSession)
    },
    groupOnChangeHandlers: {
      childSexOf: saveFormDependantsOnChange(formikBag, updateSession),
      childTitle: saveFormDependantsOnChange(formikBag, updateSession),
      childTransferringFunds: {
        childTransferringFunds: saveFormDependantsOnChange(formikBag, updateSession),
        'childTransferringFunds--currentProvider': saveFormDependantsOnChange(formikBag, updateSession)
      },
      isStudentDependant: saveFormDependantsOnChange(formikBag, updateSession),
      onRemove: saveFormOnDependantRemoval(formikBag, updateSession)
    },
    id: 'hasDependants',
    name: 'hasDependants',
    nameMaxLength: 24,
    excludeDependantsGroupRadio: true,
    lastNameMaxLength: getBrand() === 'ing' ? 40 : 60,
    maxDependants: 10,
    sexHelp: SexHelpTextDependant,
    showAdultDependantsInfoBox: true,
    policyPrice,
    minAdultDependantAge,
    maxAdultDependantAge
  };
};
