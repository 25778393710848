import type {Session} from '@nib/types-session-api';

import {PayloadAction} from '@reduxjs/toolkit';
import {FailedRequestPayload} from '../../types';
import {SessionState} from '../sessionSlice';
import {GovernmentRebateValues} from '../../../components/Forms/AustralianGovernmentRebate/types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const saveRebateDetailsFormPendingReducer = (draftState: SessionState, action: PayloadAction<GovernmentRebateValues>) => {
  draftState.updatingSession = true;
  draftState.sessionError = null;
};

export const saveRebateDetailsFormRejectedReducer = (draftState: SessionState, action: PayloadAction<FailedRequestPayload | any>) => {
  const {error} = action.payload;
  draftState.sessionError = error;
  draftState.updatingSession = false;
};

export const saveRebateDetailsFormSuccessReducer = (draftState: SessionState, action: PayloadAction<Session>) => {
  const governmentDetails = action.payload.governmentDetails;

  draftState.updatingSession = false;

  if (!draftState.session) {
    return;
  }

  if (governmentDetails && draftState.session.governmentDetails) {
    draftState.session.governmentDetails.incomeTier = governmentDetails.incomeTier;
    draftState.session.governmentDetails.policyHolderCertifiedAgeOfEntry = governmentDetails.policyHolderCertifiedAgeOfEntry;
    draftState.session.governmentDetails.applyGovernmentRebate = governmentDetails.applyGovernmentRebate;
    draftState.session.governmentDetails.governmentRebateAcknowledgement = governmentDetails.governmentRebateAcknowledgement;
    draftState.session.governmentDetails.hasExistingPreExistingCondition = governmentDetails.hasExistingPreExistingCondition;
    draftState.session.governmentDetails.medicareExpiry = governmentDetails.medicareExpiry;
    draftState.session.governmentDetails.medicareNumber = governmentDetails.medicareNumber;
    draftState.session.governmentDetails.medicareCardType = governmentDetails.medicareCardType;
    draftState.session.governmentDetails.partnerCertifiedAgeOfEntry = governmentDetails.partnerCertifiedAgeOfEntry;
    draftState.session.governmentDetails.partnerPreviousFundDetails = governmentDetails.partnerPreviousFundDetails;
  }
};
