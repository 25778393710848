export const FORM_PREFIX = 'send-quote' as const;

export const FIELD_NAMES = {
  FIRST_NAME: `${FORM_PREFIX}-firstName`,
  LAST_NAME: `${FORM_PREFIX}-lastName`,
  DATE_OF_BIRTH: `${FORM_PREFIX}-dateOfBirth`,
  DATE_OF_BIRTH_DAY: `${FORM_PREFIX}-dateOfBirthDay`,
  DATE_OF_BIRTH_YEAR: `${FORM_PREFIX}-dateOfBirthYear`,
  DATE_OF_BIRTH_MONTH: `${FORM_PREFIX}-dateOfBirthMonth`,
  PARTNER_DATE_OF_BIRTH: `${FORM_PREFIX}-partnerDateOfBirth`,
  PARTNER_DATE_OF_BIRTH_DAY: `${FORM_PREFIX}-partnerDateOfBirthDay`,
  PARTNER_DATE_OF_BIRTH_YEAR: `${FORM_PREFIX}-partnerDateOfBirthYear`,
  PARTNER_DATE_OF_BIRTH_MONTH: `${FORM_PREFIX}-partnerDateOfBirthMonth`,
  EMAIL: `${FORM_PREFIX}-email`,
  PHONE_NUMBER: `${FORM_PREFIX}-phoneNumber`,
  GDPR_AGREEMENT: `${FORM_PREFIX}-gdprAgreement`
} as const;
