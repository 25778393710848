import type {GovernmentDetails, Session} from '@nib/types-session-api';
import {PayloadAction} from '@reduxjs/toolkit';
import {FailedRequestPayload} from '../../types';
import {SessionState} from '../sessionSlice';
import {RibbonDetailsValues} from '../../../types/pages';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const saveRibbonDetailsPendingReducer = (draftState: SessionState, action: PayloadAction<RibbonDetailsValues>) => {
  draftState.updatingSession = true;
};

export const saveRibbonDetailsRejectedReducer = (draftState: SessionState, action: PayloadAction<FailedRequestPayload>) => {
  const {error} = action.payload;
  draftState.sessionError = error;
  draftState.updatingSession = false;
};

export const saveRibbonDetailsSuccessReducer = (draftState: SessionState, action: PayloadAction<Session>) => {
  draftState.updatingSession = false;
  draftState.sessionError = null;
  if (!draftState.session) {
    return;
  }

  draftState.session.personalDetails.scale = action.payload.personalDetails.scale;
  draftState.session.financialDetails.paymentFrequency = action.payload.financialDetails.paymentFrequency;
  draftState.session.personalDetails.hasDependants = action.payload.personalDetails.hasDependants;
  draftState.session.personalDetails.hasPartner = action.payload.personalDetails.hasPartner;

  const addressState = action.payload.contactDetails.address?.state;
  if (draftState.session.contactDetails.address) {
    draftState.session.contactDetails.address.state = addressState;
  } else {
    draftState.session.contactDetails.address = {
      state: addressState
    };
  }

  const incomeTier = action.payload.governmentDetails?.incomeTier;
  if (incomeTier && draftState.session.governmentDetails) {
    draftState.session.governmentDetails.incomeTier = incomeTier;
  } else {
    draftState.session.governmentDetails = {
      incomeTier: incomeTier
    } as GovernmentDetails;
  }

  draftState.session.productSelection.hospital = action.payload.productSelection.hospital;

  if (draftState.session.funnelProgress && action.payload.funnelProgress) {
    draftState.session.funnelProgress.lastCompletedPage = action.payload.funnelProgress.lastCompletedPage;
  }
};
