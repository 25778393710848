import {isEqual} from 'lodash';
import {PricingApiErrorResponse} from '../types';
import {PriceFactorGroup} from './pricingSlice';
import {PricingFactors, PricingParams, ProductPrice, ProductTypes} from './types';

export const isDateStringInFuture = (date: string | undefined) => {
  if (!date) return false;

  return new Date(date) > new Date();
};

export const getPriceFactorInState = (pricesByFactors: PriceFactorGroup[], currentSessionFactorGroup: PricingFactors): PriceFactorGroup | undefined => {
  return pricesByFactors?.find((factorGroup: PriceFactorGroup) => isEqual(factorGroup.factors, currentSessionFactorGroup));
};

export const isPriceFactorInState = (pricesByFactors: PriceFactorGroup[], currentSessionFactorGroup: PricingFactors): boolean => {
  return pricesByFactors ? !!getPriceFactorInState(pricesByFactors, currentSessionFactorGroup) : false;
};

export const getProductPrice = (pricesByFactors: PriceFactorGroup[], pricingFactors: PricingFactors): ProductPrice | null => {
  const pricingGroup = getPriceFactorInState(pricesByFactors, pricingFactors);
  if (!pricingGroup) return null;
  else
    return {
      price: pricingGroup.pricingInformation.price,
      futurePrice: pricingGroup.pricingInformation.futurePrice
    };
};

export const mapPricingApiErrorMessages = (errorResponse: PricingApiErrorResponse): string[] => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return errorResponse.data.errors.filter((e) => typeof e.message !== 'undefined').map((e) => e.message!);
};

export const productParamsByProductType = (productType: ProductTypes, productId: number, productParams: PricingParams): PricingParams => {
  const params = {...productParams};
  if (productType === ProductTypes.hospital) {
    params.hospitalProduct = productId;
    params.extrasProduct = null;
  }

  if (productType === ProductTypes.extras) {
    params.hospitalProduct = null;
    params.extrasProduct = productId;
  }

  return params;
};

//Custom match function ignores product and extra values when matching, needed when user hasn't seleced product
const hasMatchingPricingFactors = (productParams: PricingParams, sessionParms: PricingParams): boolean => {
  if (typeof productParams === 'undefined' || !productParams || typeof sessionParms === 'undefined' || !sessionParms) {
    return false;
  }

  return (
    productParams.dob === sessionParms.dob &&
    productParams.excess === sessionParms.excess &&
    productParams.previousCover === sessionParms.previousCover &&
    productParams.partnerPreviousCover === sessionParms.partnerPreviousCover &&
    productParams.effectiveDate === sessionParms.effectiveDate &&
    productParams.rate === sessionParms.rate &&
    productParams.paymentFrequency === sessionParms.paymentFrequency &&
    productParams.partnerDob === sessionParms.partnerDob &&
    productParams.scale === sessionParms.scale &&
    productParams.state === sessionParms.state &&
    productParams.rebateTier === sessionParms.rebateTier &&
    productParams.certifiedAgeOfEntry === sessionParms.certifiedAgeOfEntry
  );
};

export const getPricingFactorGroupThatMatchesCurrentState = (pricesByFactorList: PriceFactorGroup[], currentSessionPricingFactors: PricingFactors): PriceFactorGroup | undefined => {
  return pricesByFactorList?.find((factorGroup: PriceFactorGroup) => hasMatchingPricingFactors(factorGroup.factors, currentSessionPricingFactors));
};

export const isDateStringGreaterThan = (firstDateString: string | null | undefined, secondDateString: string | null | undefined): boolean => {
  if (!firstDateString || !secondDateString) return false;

  const firstDate: Date = new Date(firstDateString);
  const secondDate: Date = new Date(secondDateString);

  if (isNaN(firstDate.getTime()) || isNaN(secondDate.getTime())) return false;

  return firstDate > secondDate;
};
