import type {AboutYouDetails, ContactDetails, PhoneNumber, Session} from '@nib/types-session-api';
import {produce} from 'immer';
import {SendQuoteFormValues} from '../../../components/Forms/SendQuote/types';
import {FIELD_NAMES as FN} from '../../../components/Forms/SendQuote/constants';
import {getPhoneNumberType} from '../../../utils';
import {Selected} from '@nib/phi-constants';
import {WritableDraft} from 'immer/dist/internal';

export const mapSendQuoteDetailsToSession = (values: SendQuoteFormValues, session: Session): Session => {
  return produce(session, (draftSession) => {
    draftSession.personalDetails.policyHolder.firstName = values[FN.FIRST_NAME];
    draftSession.personalDetails.policyHolder.lastName = values[FN.LAST_NAME];
    draftSession.personalDetails.policyHolder.dateOfBirth = values[FN.DATE_OF_BIRTH];
    draftSession.personalDetails.policyHolder.email = values[FN.EMAIL];

    // At this point, persist users consent to leads contact
    const allowLeadsContact = values[FN.GDPR_AGREEMENT];
    if (!draftSession.aboutYouDetails) {
      draftSession.aboutYouDetails = {
        allowLeadsContact
      } as WritableDraft<AboutYouDetails>;
    } else {
      if (allowLeadsContact) draftSession.aboutYouDetails.allowLeadsContact = allowLeadsContact;
    }

    // Declare phone number partial
    const phoneNumbers: PhoneNumber[] = [];
    if (values[FN.PHONE_NUMBER]) {
      phoneNumbers.push({
        type: getPhoneNumberType(values[FN.PHONE_NUMBER] || ''),
        number: values[FN.PHONE_NUMBER]
      });
    }

    // Connect partials to contact details object
    if (draftSession.contactDetails) {
      draftSession.contactDetails.phoneNumbers = phoneNumbers;
    } else {
      const contactDetails: ContactDetails = {
        phoneNumbers: phoneNumbers
      };
      draftSession.contactDetails = contactDetails;
    }

    if (session.personalDetails.hasPartner === Selected.yes && draftSession.personalDetails.partner) {
      draftSession.personalDetails.partner.dateOfBirth = values[FN.PARTNER_DATE_OF_BIRTH];
    }
  });
};
