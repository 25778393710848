import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {FailedRequestPayload} from '../types';
import {OfferState, Offer, OfferParams} from './types';

export const initialState: OfferState = {
  loadingOffer: false,
  fetchedOffer: false,
  submittingOffer: false,
  offer: undefined,
  offersError: null
};

const offerSlice = createSlice({
  name: 'offer',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchOfferPending: (state, action: PayloadAction<OfferParams>) => {
      state.loadingOffer = true;
      state.fetchedOffer = false;
    },
    fetchOfferSuccess: (state, action: PayloadAction<Offer>) => {
      state.fetchedOffer = true;
      state.loadingOffer = false;
      state.offer = action.payload;
      state.offersError = null;
    },
    fetchOfferRejected: (state, action: PayloadAction<FailedRequestPayload | any>) => {
      state.fetchedOffer = false;
      state.loadingOffer = false;
      state.offersError = action.payload;
    }
  }
});

export const {fetchOfferPending, fetchOfferSuccess, fetchOfferRejected} = offerSlice.actions;

export default offerSlice.reducer;
