import {Scale, Funds} from '@nib/phi-constants';
import type {Scale as TScale} from '@nib/types-session-api';
import {ProductVariation} from '../services/sourceNodes/types';
import {ProductType} from '../services/utils';

export const parseExcess = (excess: string): number | undefined => {
  const excessRegExp = /(250|500|750)/g;
  const matchedExcess = excessRegExp.exec(excess);

  if (matchedExcess) {
    return parseInt(matchedExcess[0]);
  } else {
    return undefined;
  }
};

export const scaleDefault = Scale.Single;

export const parseScale = (scale: string): TScale => {
  return Scale[scale] || scaleDefault;
};

export const isBrowser = () => typeof window !== 'undefined';

export const HOSP_EXTRAS_VARIATION_ABBREV = 'v';
export const EXTRAS_INCLUSIONS_VARIATION_ABBREV = 'vi';
export const CONTINUOUS_COVER_ENABLED_ABBREV = 'vcc';
export type ExtrasInclusionViewMode = 'productServices' | 'serviceComponents';

/**
 * Controls specific view mode for extras inclusions, any activiation rules for experiments
 * (query params etc) would be included here. Currently only one view mode is served.
 */
export const getExtrasInclusionsViewMode = (): ExtrasInclusionViewMode => {
  return 'serviceComponents';
};

export type ExtrasInclusionSortMode = 'groups' | 'inclusions';

/**
 * Controls specific sort mode for extras inclusions, any activiation rules for experiments
 * (query params etc) would be included here. Currently only one sort mode is served.
 */
export const getSortMode = (): ExtrasInclusionSortMode => {
  return 'inclusions';
};

export type ExtrasInclusionsGroupBy = 'ExtrasRoundTwoVarOne' | 'ExtrasRoundTwoVarTwo' | undefined;

/**
 * Controls specific group mode for extras inclusions, any activiation rules for experiments
 * (query params etc) would be included here. Currently only one group mode is served.
 */
export const getGroupMode = (): ExtrasInclusionsGroupBy => {
  if (isBrowser()) {
    const urlParams = new URLSearchParams(window.location.search);
    const param = urlParams.get(EXTRAS_INCLUSIONS_VARIATION_ABBREV);
    if (param === 'a') return 'ExtrasRoundTwoVarOne';
    if (param === 'b') return 'ExtrasRoundTwoVarTwo';
  }

  return;
};

/**
 * Continuous cover is enabled by default, but can be disabled via query param
 * @returns
 */
export const isContinuousCoverActive = (): boolean => {
  if (isBrowser()) {
    const urlParams = new URLSearchParams(window.location.search);
    const param = urlParams.get(CONTINUOUS_COVER_ENABLED_ABBREV);
    if (param === '1') return true;
  }
  return false;
};

/**
 * Resolve the variation of either hospital or extras products for given variant id
 * @param productCardListVariations
 * @returns
 */
export const getHospitalExtrasProductVariation = (productCardListVariations: ProductVariation[], productType: ProductType): number[] | null => {
  if (isBrowser()) {
    const urlParams = new URLSearchParams(window.location.search);
    const param = urlParams.get(HOSP_EXTRAS_VARIATION_ABBREV);
    const variation = productCardListVariations.find((variation) => variation.variationId === param);
    if (variation) return variation[productType];
  }
  return null as any; // should never reach this, the control variation will always pick up the slack
};

export const residentTypeDefault = Funds.ARHI;

export const parseResidentType = (residentType: string): string => Funds[residentType] || Funds.ARHI;

export const sortProductVariants = (sortOrder: number[], nodes: any[]) => {
  const lookup = {};
  sortOrder.forEach((id, index) => (lookup[id] = index));

  return nodes.sort((a, b) => {
    const aIndex = lookup[String(a.pcatId)];
    const bIndex = lookup[String(b.pcatId)];

    if (aIndex === undefined) return 1;
    if (bIndex === undefined) return -1;

    return aIndex - bIndex;
  });
};
