import axios from 'axios';
import {PreviousFundsUrl} from '../../constants';

/**
 * Fetch the session given an id
 * @param   {object} parameters
 * @returns {Promise}
 */
export const fetchPreviousFunds = () => {
  return axios.get(`${PreviousFundsUrl}`).then((res) => res.data);
};
