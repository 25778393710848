import {PayloadAction} from '@reduxjs/toolkit';
import {AttributionDetails, FailedRequestPayload} from '../../types';
import {SessionState} from '../sessionSlice';

export const saveAttributionDetailsPendingReducer = (draftState: SessionState) => {
  draftState.updatingSession = true;
  draftState.sessionError = null;
};

export const saveAttributionDetailsRejectedReducer = (draftState: SessionState, action: PayloadAction<FailedRequestPayload | any>) => {
  const {error} = action.payload;
  draftState.sessionError = error;
  draftState.updatingSession = false;
};

export const saveAttributionDetailsSuccessReducer = (draftState: SessionState, action: PayloadAction<AttributionDetails>) => {
  const {sourceCode, campaignChannel} = action.payload;

  draftState.updatingSession = false;
  if (draftState.session && sourceCode && campaignChannel) {
    draftState.session.sourceCode = sourceCode;
    draftState.session.campaignChannel = campaignChannel;
    draftState.session.company = undefined;
  }
};
