import {createSelector} from 'reselect';
import _get from 'lodash/get';
import {ARHIJoinState} from '../../rootReducer';
import * as aboutYouDetailsSelectors from './aboutYouDetails';
import * as personalDetailsSelectors from './personalDetails';
import * as contactDetailsSelectors from './contactDetails';
import * as healthDetailsSelectors from './healthDetails';
import type {Company, Nullable, Session} from '@nib/types-session-api';

export const getSessionState = (state: ARHIJoinState): Session => _get(state, 'session.session') || ({} as Session);

export const getIsLoadingSession = (state: ARHIJoinState) => state.session.loadingSession;
export const getFetchedSession = (state: ARHIJoinState) => state.session.fetchedSession;
export const getSessionError = (state: ARHIJoinState) => state.session.sessionError;

export const getPersonalDetails = (state: ARHIJoinState) => personalDetailsSelectors.selectData(state);
export const getAboutYouDetails = (state: ARHIJoinState) => aboutYouDetailsSelectors.selectData(state);
export const getContactDetails = (state: ARHIJoinState) => contactDetailsSelectors.selectData(state);

export const getGovernmentDetails = (state: ARHIJoinState) => healthDetailsSelectors.selectGovernmentData(state);

export const getCompany = (state: ARHIJoinState) => getSessionState(state).company;
export const getCompanyCode = (state: ARHIJoinState) => getCompany(state)?.code;
export const getCompanyName = (state: ARHIJoinState) => getCompany(state)?.name;
export const getCompanyLogoUrl = (state: ARHIJoinState) => getCompany(state)?.logoUrl;

export const getSessionId = (state: ARHIJoinState) => getSessionState(state).id;

export const getIsCorporateUser = createSelector<ARHIJoinState, Company | undefined, boolean>(getCompany, (company) => Boolean(company));
export const getFundId = (state: ARHIJoinState) => getSessionState(state).fundId || '';

export const getJoinSource = (state: ARHIJoinState) => getSessionState(state).joinSource || null;
export const getSourceCode = (state: ARHIJoinState) => getSessionState(state).sourceCode || null;
export const getCampaignChannel = (state: ARHIJoinState): Nullable<string> => getSessionState(state).campaignChannel || null;
export const getAttributionPartner = (state: ARHIJoinState): Nullable<string> => getSessionState(state).attributionPartner || null;

export const getReferAFriendMembershipNumber = (state: ARHIJoinState): Nullable<string> => getSessionState(state).referAFriend?.membershipNumber || null;
