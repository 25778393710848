import {ARHIJoinState} from '../rootReducer';
import {getCoverStartDate, getArhiRate} from '../session/selectors/financialDetails';
import {FinancialDetailsOfferParams, OfferParams, ProductSelectionOfferParams, PersonalDetailsOfferParams, CompanyOfferParams, CampaignParametersParams, SourceChannelOfferParams} from './types';
import {getHospitalId, getExtrasId, getCombinedProductId} from '../session/selectors/productSelection';
import {getScale} from '../session/selectors/personalDetails';
import {getCompany, getCampaignChannel, getJoinSource, getSourceCode} from '../session/selectors';
import {getCampaignValue, getSourceValue, getContentValue} from '../session/selectors/offerDetails';
import {getPreviousFundCode} from '../session/selectors/healthDetails';
import {isCorporateBrand} from '../../services/utils';
import {FetchOfferParamOverrides} from './thunks';

const mapFinancialDetailsOfferParams = (state: ARHIJoinState): FinancialDetailsOfferParams => ({
  coverStartDate: getCoverStartDate(state),
  rate: getArhiRate(state)
});

const mapProductSelectionOfferParams = (state: ARHIJoinState): ProductSelectionOfferParams => ({
  hasHospitalProduct: getHospitalId(state) != undefined,
  hasExtrasProduct: getExtrasId(state) != undefined,
  hasCombinedProduct: getCombinedProductId(state) != undefined
});

const mapPersonalDetailsOfferParams = (state: ARHIJoinState): PersonalDetailsOfferParams => ({scale: getScale(state), previousFundCode: getPreviousFundCode(state)});

const mapCompanyDetailsOfferParams = (state: ARHIJoinState): CompanyOfferParams => ({
  companyCode: getCompany(state)?.code || null
});

const mapCampaignParametersParams = (state: ARHIJoinState): CampaignParametersParams => ({
  utm_campaign: getCampaignValue(state),
  utm_source: getSourceValue(state),
  utm_content: getContentValue(state)
});

const mapSourceChannelOfferParams = (state: ARHIJoinState): SourceChannelOfferParams => {
  const sourceChannel = isCorporateBrand() ? getCampaignChannel(state) : getCampaignChannel(state) || process.env.GATSBY_SOURCE_CHANNEL || 'NibWeb';
  return {
    sourceChannel,
    joinSource: getJoinSource(state),
    sourceCode: getSourceCode(state)
  };
};

export const mapStoreToOfferRequest = (state: ARHIJoinState, overrides?: FetchOfferParamOverrides): OfferParams => {
  const offerParams: OfferParams = {
    ...mapFinancialDetailsOfferParams(state),
    ...mapProductSelectionOfferParams(state),
    ...mapPersonalDetailsOfferParams(state),
    ...mapCompanyDetailsOfferParams(state),
    ...mapCampaignParametersParams(state),
    ...mapSourceChannelOfferParams(state)
  };

  return {...offerParams, ...overrides};
};

export const mapStoreToOfferParams = (state: ARHIJoinState): OfferParams => {
  return mapStoreToOfferRequest(state);
};
