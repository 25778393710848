import type {ContactDetails} from '@nib/types-session-api';
import {ARHIJoinState} from '../../rootReducer';
import _get from 'lodash/get';

export const selectData = (state: ARHIJoinState): ContactDetails => _get(state, 'session.session.contactDetails') || ({} as ContactDetails); //eslint-disable-line

export const getPhoneNumbers = (state: ARHIJoinState) => selectData(state).phoneNumbers || [];
export const getPhoneNumber = (state: ARHIJoinState) => {
  const phoneNumbersArray = getPhoneNumbers(state);
  if (phoneNumbersArray.length) {
    return phoneNumbersArray[0].number;
  }
  return '';
};

export const getAddress = (state: ARHIJoinState) => selectData(state).address || {};
export const getPostcode = (state: ARHIJoinState) => getAddress(state).postcode;
export const getSuburb = (state: ARHIJoinState) => getAddress(state).suburb;
export const getState = (state: ARHIJoinState) => getAddress(state).state;
export const getAddressLine = (state: ARHIJoinState) => getAddress(state).addressLine;
export const getIsManualEntry = (state: ARHIJoinState) => getAddress(state).isManualEntry;
