import {FormikProps} from 'formik';
import {PersonalDetailsValues, UpdateFunc} from './types';
import {FIELD_NAMES} from './constants';
import {getItemsFromDate} from '../../../services/utils';

export const saveFormOnBlur = (formikBag: FormikProps<PersonalDetailsValues>, updateFunc: UpdateFunc, optionalFieldName?) => (event) => {
  // 'optionalFieldName' accommodates for optional field name or when onBlur does not pass in event with a target name.
  if (optionalFieldName || (event && event.target && event.target.name)) {
    const fieldName = optionalFieldName || (event && event.target && event.target.name);
    updateFunc(formikBag.values, formikBag.touched, fieldName);
  }
};

/* Radio buttons / text input fields / selects
 * Any field / component that passes up a event object when calling the onChange function */
export const saveFormOnChange = (formikBag: FormikProps<PersonalDetailsValues>, updateFunc: UpdateFunc) => (event) => {
  if (event && event.target && event.target.name) {
    const value = event.target.value;
    const updatedValue = {
      [event.target.name]: value
    };
    updateFunc({...formikBag.values, ...updatedValue}, formikBag.touched, event.target.name);
  }
};

export const saveFormOnDOBChange = (formikBag: FormikProps<PersonalDetailsValues>, updateFunc: UpdateFunc) => (e, newValue) => {
  // DOB event handlers send latest Date but not the last change from input, e.g. '1999' for year is sent '199'. Unlikely, dependants' DOB doesn't have this issue.
  const newDateOfBirth = {
    [FIELD_NAMES.DATE_OF_BIRTH]: newValue,
    [`${FIELD_NAMES.DATE_OF_BIRTH}Day`]: getItemsFromDate(newValue, 'day'),
    [`${FIELD_NAMES.DATE_OF_BIRTH}Month`]: getItemsFromDate(newValue, 'month'),
    [`${FIELD_NAMES.DATE_OF_BIRTH}Year`]: getItemsFromDate(newValue, 'year')
  };
  updateFunc({...formikBag.values, ...newDateOfBirth}, formikBag.touched, FIELD_NAMES.DATE_OF_BIRTH);
};
