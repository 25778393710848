import type {GovernmentDetails, PreviousFundDetails, Session} from '@nib/types-session-api';
import {PayloadAction} from '@reduxjs/toolkit';
import {FailedRequestPayload} from '../../types';
import {SessionState} from '../sessionSlice';

export const savePricingFactorsModalPendingReducer = (draftState: SessionState) => {
  draftState.updatingSession = true;
  draftState.sessionError = null;
};

export const savePricingFactorsModalRejectedReducer = (draftState: SessionState, action: PayloadAction<FailedRequestPayload>) => {
  const {error} = action.payload;
  draftState.sessionError = error;
  draftState.updatingSession = false;
};

export const savePricingFactorsModalSuccessReducer = (draftState: SessionState, action: PayloadAction<Session>) => {
  draftState.updatingSession = false;
  draftState.sessionError = null;
  const {personalDetails, governmentDetails, contactDetails, financialDetails} = action.payload;

  if (!draftState.session) {
    return;
  }

  draftState.session.personalDetails.scale = personalDetails.scale;
  draftState.session.personalDetails.hasDependants = personalDetails.hasDependants;
  draftState.session.personalDetails.hasPartner = personalDetails.hasPartner;
  draftState.session.personalDetails.policyHolder.dateOfBirth = personalDetails.policyHolder.dateOfBirth;

  if (personalDetails.partner) {
    draftState.session.personalDetails.partner = personalDetails.partner;
    draftState.session.personalDetails.partner.dateOfBirth = personalDetails.partner.dateOfBirth || null;
  }

  const addressState = contactDetails.address?.state;
  if (draftState.session.contactDetails.address) {
    draftState.session.contactDetails.address.state = addressState;
  } else {
    draftState.session.contactDetails.address = {
      state: addressState
    };
  }

  const incomeTier = governmentDetails?.incomeTier;
  if (incomeTier && draftState.session.governmentDetails) {
    draftState.session.governmentDetails.incomeTier = incomeTier;
  } else {
    draftState.session.governmentDetails = {
      incomeTier: incomeTier
    } as GovernmentDetails;
  }

  const previousFundDetails = (governmentDetails && governmentDetails.policyHolderPreviousFundDetails) || ({} as PreviousFundDetails);
  if (previousFundDetails && draftState.session.governmentDetails) {
    draftState.session.governmentDetails.policyHolderPreviousFundDetails = previousFundDetails;
  }

  const partnerPreviousFundDetails = (governmentDetails && governmentDetails.partnerPreviousFundDetails) || ({} as PreviousFundDetails);
  if (partnerPreviousFundDetails && draftState.session.governmentDetails) {
    draftState.session.governmentDetails.partnerPreviousFundDetails = partnerPreviousFundDetails;
  }

  draftState.session.financialDetails.paymentMethod = financialDetails.paymentMethod;
};
