import {calculateLhcAge, getLoadingFor, startOfFinancialYear, ageForLhcPurposesAt, PersonDetails} from '@nib/lhc-calculator';
import {CONTINUOUS_COVER_OPTIONS} from '@nib/form-fields';
import {subYears, addYears, format, differenceInYears} from 'date-fns';
import {stringToDate} from './utils';
import type {Nullable, ContinuousCoverType, Selected} from '@nib/types-session-api';
import {DefaultInitialParameters, ISO_DATE_FORMAT} from '../constants';
import {SelectedToBoolean} from '../components/Forms/utils';

const defaultDob = format(subYears(new Date(), DefaultInitialParameters.YearsOfAge), ISO_DATE_FORMAT);

export const getDateObject = (dob: string | Date): Date => {
  return typeof dob === 'string' ? stringToDate(dob) : dob;
};

const overrideHeldPolicyBefore = (continuousCover: Nullable<string>, heldPolicyBefore: Nullable<boolean>) => {
  if (continuousCover) {
    return continuousCover === '10';
  } else {
    return heldPolicyBefore ?? true;
  }
};

export interface PreviousInsuranceHolder {
  /**
   * LHC Date may either be the date of birth or a date representing the LHC age of the user (date of birth considering CAE)
   */
  lhcDate: Nullable<string> | Nullable<Date>;
  heldPolicyBefore: Nullable<boolean>;
  continuousCover: Nullable<string>;
}

export interface ComputeLHCDateInfo {
  lhcDate: Date | null;
  lhcAge: number;
  dobAge: number;
}

export const getCertifiedAgeOfEntry = (dateOfBirth: Date, coverStartDate: Date, hasPreviouslyHadHealthInsurance: boolean, continuousCover?: ContinuousCoverType) => {
  const continuousCoverExperimentActive = !(continuousCover === null || continuousCover === undefined);
  let certifiedAgeOfEntry;

  // get beginning of financial year for the dob
  const startOfFinancialYearDate = startOfFinancialYear(coverStartDate);
  const ageForLhcPurposes = ageForLhcPurposesAt(dateOfBirth, startOfFinancialYearDate);

  if (hasPreviouslyHadHealthInsurance && continuousCoverExperimentActive) {
    const coverNumber = Number(continuousCover);
    certifiedAgeOfEntry = ageForLhcPurposes - coverNumber;
  } else if (!hasPreviouslyHadHealthInsurance) {
    certifiedAgeOfEntry = ageForLhcPurposes;
  } else {
    certifiedAgeOfEntry = 30;
  }

  const lhcAge = calculateLhcAge({
    dateOfBirth,
    coverStartDate,
    certifiedAgeOfEntry,
    hasPreviouslyHadHealthInsurance
  });

  return lhcAge;
};

/**
 * Calculates the date corresponding to the certified age of entry. This is >= the dateOfBirth. Output of this function will be treated as the actual DoB for loading calculation.
 *
 * @param continuousCover
 * @returns
 */
export const computeLHCDate = (dateOfBirth: Date, coverStartDate: Date, hasPreviouslyHadHealthInsurance: boolean, continuousCover: ContinuousCoverType): ComputeLHCDateInfo | null => {
  const userAge = differenceInYears(coverStartDate, dateOfBirth);

  // existing behaviour with no continuous cover
  if (continuousCover == null) {
    if (hasPreviouslyHadHealthInsurance) {
      return {lhcDate: dateOfBirth, lhcAge: 30, dobAge: userAge};
    } else {
      return {lhcDate: dateOfBirth, lhcAge: userAge, dobAge: userAge};
    }
  }

  // behaviour modifier if continuous cover is present and over 10, interpret as 0% loading
  if (continuousCover == '10') {
    // don't use CAE in loading calculation
    return {lhcDate: dateOfBirth, lhcAge: userAge, dobAge: userAge};
  }

  // is continuous cover is between 1 - 9, calculate partial loading based on age of entry
  if (Object.keys(CONTINUOUS_COVER_OPTIONS).includes(continuousCover || '0')) {
    const lhcAge = getCertifiedAgeOfEntry(dateOfBirth, coverStartDate, hasPreviouslyHadHealthInsurance, continuousCover);

    // cover start date is with respect to the start of the current financial year
    const coverStartDateFinancialYear = startOfFinancialYear(coverStartDate);

    // this delta calc is just so we can actually use the lhc-calculator with "certifiedAgeOfEntry" override in the calculation
    const dateOfBirthCoverStartDate = differenceInYears(coverStartDateFinancialYear, dateOfBirth);
    const delta = dateOfBirthCoverStartDate - lhcAge;
    const newDateLhcCalc = addYears(dateOfBirth, delta);

    return {lhcDate: newDateLhcCalc, lhcAge, dobAge: userAge};
  }

  return null;
};

export const getLoading = (policyHolderInfo: PreviousInsuranceHolder, partnerInfo: Nullable<PreviousInsuranceHolder>, coverStartDate: Nullable<string>) => {
  let policyHolder: Nullable<PersonDetails> = null;
  if (policyHolderInfo) {
    policyHolder = {
      dateOfBirth: policyHolderInfo.lhcDate ? getDateObject(policyHolderInfo.lhcDate) : stringToDate(defaultDob),
      // if continuous cover is 10, then we maintain existing behaviour of user having held policy before and will have 0 loading
      previouslyHadHealthInsurance: overrideHeldPolicyBefore(policyHolderInfo.continuousCover, policyHolderInfo.heldPolicyBefore)
    };
  }

  let partner: Nullable<PersonDetails> = null;
  if (partnerInfo) {
    partner = {
      dateOfBirth: partnerInfo.lhcDate ? getDateObject(partnerInfo.lhcDate) : stringToDate(defaultDob),
      previouslyHadHealthInsurance: overrideHeldPolicyBefore(partnerInfo.continuousCover, partnerInfo.heldPolicyBefore)
    };
  }

  return getLoadingFor(policyHolder, partner, getCoverStartDateOrDefault(coverStartDate)).toString();
};

export const getCoverStartDateOrDefault = (coverStartDate: Nullable<string>) => {
  return coverStartDate ? stringToDate(coverStartDate) : new Date();
};

export interface PreviousFundLoading {
  previouslyHadHealthInsurance: Nullable<boolean>;
  continuousCover?: Nullable<ContinuousCoverType>;
}

export interface CombinedLoadingResult {
  loading: string;
  policyHolderAge: number;
  policyHolderLhc: number;
  partnerAge: string;
  partnerLhc: number;
}

/**
 * Gently control the defaults for these values as they impact pricing. We like to show a lower initial price during partial quote
 * completion and only apply the loading charge once users explicitly state they do not have previous health cover.
 * @param previouslyHadHealthInsurnace
 * @returns
 */
export const defaultPreviousCover = (previouslyHadHealthInsurnace) => {
  return previouslyHadHealthInsurnace === null || previouslyHadHealthInsurnace === undefined ? true : previouslyHadHealthInsurnace;
};

export const getCombinedLoading = (
  // strange type issues here only when running unit tests in buildkite
  policyHolderPreviousFundDetails: Nullable<PreviousFundLoading>,
  partnerPreviousFundDetails: Nullable<PreviousFundLoading>,
  policyHolderDob: Nullable<string>,
  partnerDob: Nullable<string>,
  coverStartDate: Nullable<string>,
  hasPartner: Selected
): CombinedLoadingResult => {
  let policyHolderLoadingData, partnerLoadingData;
  let policyHolderLhc, partnerLhc;

  if (policyHolderDob && coverStartDate) {
    policyHolderLhc = computeLHCDate(
      stringToDate(policyHolderDob),
      stringToDate(coverStartDate),
      defaultPreviousCover(policyHolderPreviousFundDetails?.previouslyHadHealthInsurance),
      policyHolderPreviousFundDetails?.continuousCover || null
    );

    const lhcDate = format(policyHolderLhc?.lhcDate ?? new Date(), ISO_DATE_FORMAT);
    policyHolderLoadingData = {
      lhcDate,
      heldPolicyBefore: defaultPreviousCover(policyHolderPreviousFundDetails?.previouslyHadHealthInsurance),
      continuousCover: policyHolderPreviousFundDetails?.continuousCover || null
    };
  }

  if (SelectedToBoolean(hasPartner) && partnerDob && coverStartDate) {
    partnerLhc = computeLHCDate(
      stringToDate(partnerDob),
      stringToDate(coverStartDate),
      defaultPreviousCover(partnerPreviousFundDetails?.previouslyHadHealthInsurance),
      partnerPreviousFundDetails?.continuousCover || null
    );

    const lhcDate = format(partnerLhc?.lhcDate ?? new Date(), ISO_DATE_FORMAT);
    partnerLoadingData = {
      lhcDate,
      heldPolicyBefore: defaultPreviousCover(partnerPreviousFundDetails?.previouslyHadHealthInsurance),
      continuousCover: partnerPreviousFundDetails?.continuousCover || null
    };
  }

  const loading = getLoading(policyHolderLoadingData, partnerLoadingData, coverStartDate);
  let returnData = {loading} as CombinedLoadingResult;

  if (policyHolderLhc) {
    returnData = {...returnData, policyHolderAge: policyHolderLhc.dobAge, policyHolderLhc: policyHolderLhc?.lhcAge};
  }

  if (partnerLhc) {
    returnData = {...returnData, partnerAge: partnerLhc.dobAge, partnerLhc: partnerLhc?.lhcAge};
  }

  return returnData;
};
