import {metrics as reactMetrics, createMetrics} from '@nib/react-metrics';
import compose from 'lodash/fp/compose';
import trackPage from '@nib/track-page';
import nibMetricsConfig from './nibMetricsConfig';
import nibMetricsOptions from './nibMetricsOptions';
import mapMetricsToProps from './mapMetricsToProps';
import {TealiumOptions} from './types';

/**
 * HOC to wrap each page for tealium/analytics tracking
 *
 * @param   {object}  options                 The tealium data layer attributes
 * @param   {string}  options.channel         The channel
 * @param   {string}  options.siteName        The site name
 * @param   {string}  options.siteSection     The site section
 * @param   {string}  options.pageType        The page type
 * @param   {string}  options.pageName        The page name
 * @return  {function}
 */
const enhance = (options: TealiumOptions = {}) => compose(reactMetrics(nibMetricsConfig, nibMetricsOptions), trackPage(mapMetricsToProps(options)));

export const metrics = createMetrics(nibMetricsConfig);

export default enhance;
