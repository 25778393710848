import type {ExtrasProduct, Session} from '@nib/types-session-api';
import {produce} from 'immer';
import {ARHIPageIndex, ARHIPageList} from '../../../constants';

export const mapExtrasDetailsToSession = (values: ExtrasProduct, session: Session, isPageComplete?: boolean): Session => {
  const mappedSession = produce(session, (draftSession) => {
    if (values.id && draftSession.productSelection) {
      draftSession.productSelection.extras = values;
    } else {
      draftSession.productSelection.extras = {
        id: null,
        name: null,
        shortName: null
      };
    }

    if (isPageComplete && draftSession.funnelProgress) {
      draftSession.funnelProgress.lastCompletedPage = ARHIPageList[ARHIPageIndex.EXTRAS];
    }
  });
  return mappedSession;
};
