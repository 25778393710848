import type {AboutYouDetails, Address, ContactDetails, ContinuousCoverType, GovernmentDetails, Nullable, PhoneNumber, PreviousFundDetails, Session} from '@nib/types-session-api';
import {IncomeTier, Selected} from '@nib/phi-constants';
import {format} from 'date-fns';
import {produce} from 'immer';
import {PersonalDetailsValues} from '../../../components/Forms/PersonalDetails/types';
import {SelectedToNullableBoolean} from '../../../components/Forms/utils';
import {Dictionary} from '../../../types/common';
import {ARHIPageIndex, ARHIPageList, ISO_DATE_FORMAT} from '../../../constants';
import {getAdjustedIncomeTier, getPhoneNumberType} from '../../../utils';
import {WritableDraft} from 'immer/dist/internal';

export const mapFormFieldToLowerCase = (value) => (value ? value.toLowerCase() : '');
export const mapFormFieldToUpperCase = (value) => (value ? value.toUpperCase() : '');

export const mapPersonalDetailsToSession = (values: PersonalDetailsValues, session: Session, funds: Dictionary<string>, isPageComplete?: boolean): Session => {
  const mappedSession = produce(session, (draftSession) => {
    draftSession.personalDetails.policyHolder.title = values.title;
    draftSession.personalDetails.policyHolder.gender = values.gender;
    draftSession.personalDetails.policyHolder.firstName = values.firstName;
    draftSession.personalDetails.policyHolder.lastName = values.lastName;
    // If dob is empty it be should be defaulted so that pricing observer can watch values correctly.
    draftSession.personalDetails.policyHolder.dateOfBirth = values.dateOfBirth || null;
    draftSession.personalDetails.policyHolder.email = values.email;

    // Declare phone number partial
    const phoneNumbers: PhoneNumber[] = [];
    if (values.phoneNumber) {
      phoneNumbers.push({
        type: getPhoneNumberType(values.phoneNumber),
        number: values.phoneNumber
      });
    }

    // Declare address partial
    const addressValues: Address | undefined = {
      hasAddress: Selected.unselected,
      isManualEntry: false,
      addressLine: values['homeAddress--streetAddress'],
      postcode: values['homeAddress--postcode'],
      state: values['homeAddress--states'] ? mapFormFieldToUpperCase(values['homeAddress--states']) : session.contactDetails.address?.state,
      suburb: values['homeAddress--suburb']
    };

    // Connect partials to contact details object
    if (draftSession.contactDetails) {
      draftSession.contactDetails.phoneNumbers = phoneNumbers;
      draftSession.contactDetails.address = addressValues;
    } else {
      const contactDetails: ContactDetails = {
        address: addressValues,
        phoneNumbers: phoneNumbers
      };
      draftSession.contactDetails = contactDetails;
    }

    // Transferring funds mapping
    if (draftSession.governmentDetails) {
      let policyHolderTransferringFunds: Nullable<PreviousFundDetails> = {} as PreviousFundDetails;

      if (values.transferringFunds === Selected.yes) {
        policyHolderTransferringFunds = {
          policyNumber: values['transferringFunds--memberNumber'],
          code: funds[values['transferringFunds--currentProvider'] || ''] || '',
          ageBasedDiscountAssessmentDate: format(new Date(), ISO_DATE_FORMAT),
          description: values['transferringFunds--currentProvider'] || '',
          previouslyHadHealthInsurance: SelectedToNullableBoolean(values.transferringFunds || undefined),
          continuousCover: (values['transferringFunds--continuousCover'] as ContinuousCoverType) || null
        };
      } else if (values.transferringFunds === Selected.no) {
        policyHolderTransferringFunds = {
          previouslyHadHealthInsurance: false,
          code: '',
          description: '',
          policyNumber: '',
          continuousCover: null,
          ageBasedDiscountAssessmentDate: format(new Date(), ISO_DATE_FORMAT)
        };
      }
      // finally set the value on the session
      draftSession.governmentDetails.policyHolderPreviousFundDetails = policyHolderTransferringFunds;
    }

    // persist users consent to leads contact
    const {privacyPolicyAgreement} = values;
    if (privacyPolicyAgreement) {
      if (!draftSession.aboutYouDetails) {
        draftSession.aboutYouDetails = {
          allowLeadsContact: privacyPolicyAgreement
        } as WritableDraft<AboutYouDetails>;
      } else {
        draftSession.aboutYouDetails.allowLeadsContact = privacyPolicyAgreement;
      }
    }

    if (isPageComplete && draftSession.funnelProgress) {
      draftSession.funnelProgress.lastCompletedPage = ARHIPageList[ARHIPageIndex.PERSONAL_DETAILS];
    }

    if (draftSession.joinProgress) {
      draftSession.joinProgress.hasAcceptedPrivacyPolicyAgreement = privacyPolicyAgreement;
    }

    const incomeTier = getAdjustedIncomeTier(values.dateOfBirth, session.financialDetails.coverStartDate.date, session.governmentDetails?.incomeTier);

    if (draftSession.governmentDetails) {
      draftSession.governmentDetails.incomeTier = incomeTier;
      draftSession.governmentDetails.applyGovernmentRebate = incomeTier !== IncomeTier.NoRebate;
    } else {
      draftSession.governmentDetails = {
        incomeTier: incomeTier,
        applyGovernmentRebate: incomeTier !== IncomeTier.NoRebate
      } as GovernmentDetails;
    }
  });

  return mappedSession;
};
