import type {GovernmentDetails, Session} from '@nib/types-session-api';
import {PayloadAction} from '@reduxjs/toolkit';
import {FailedRequestPayload} from '../../types';
import {SessionState} from '../sessionSlice';
import {AnnualIncomeFormValues} from '../../../components/Modals/AnnualIncomeModal/type';

/* eslint-disable @typescript-eslint/no-unused-vars */
export const saveAnnualIncomeFormPendingReducer = (draftState: SessionState, action: PayloadAction<AnnualIncomeFormValues>) => {
  draftState.updatingSession = true;
  draftState.sessionError = null;
};
/* eslint-enable @typescript-eslint/no-unused-vars */

export const saveAnnualIncomeFormRejectedReducer = (draftState: SessionState, action: PayloadAction<FailedRequestPayload>) => {
  const {error} = action.payload;
  draftState.sessionError = error;
  draftState.updatingSession = false;
};

export const saveAnnualIncomeDetailsSuccessReducer = (draftState: SessionState, action: PayloadAction<Session>) => {
  draftState.updatingSession = false;
  draftState.sessionError = null;
  const {personalDetails, governmentDetails} = action.payload;

  if (!draftState.session) {
    return;
  }

  draftState.session.personalDetails.policyHolder = personalDetails.policyHolder;
  draftState.session.personalDetails.policyHolder.dateOfBirth = personalDetails.policyHolder.dateOfBirth;
  draftState.session.personalDetails.partner = personalDetails.partner;

  const incomeTier = governmentDetails?.incomeTier;
  if (incomeTier && draftState.session.governmentDetails) {
    draftState.session.governmentDetails.incomeTier = incomeTier;
  } else {
    draftState.session.governmentDetails = {
      incomeTier: incomeTier
    } as GovernmentDetails;
  }
};
