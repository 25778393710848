/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import {useState, useContext} from 'react';
import {FormikTouched, FormikErrors} from 'formik';
import {FormValuesType} from '../Forms/types';

type RegisterTouchedFunction = (touchedFunc) => void;
type RegisterValidationFunction = (validationFunc) => void;
type setErrors = (currentErrors) => void;

export type FormikTouchedFunction = (touched: FormikTouched<FormValuesType>) => void;
export type FormikValidationFunction = (values?: any) => Promise<FormikErrors<FormValuesType>>;

export type ValidationContextState = {
  touchedFunction?: FormikTouchedFunction;
  registerTouchedFunction: RegisterTouchedFunction;
  validationFunction?: FormikValidationFunction;
  registerValidationFunction: RegisterValidationFunction;
  setErrors: setErrors;
  errors?: any;
};

const validationContextDefaults: ValidationContextState = {
  touchedFunction: undefined,
  registerTouchedFunction: (touchedFunc) => {},
  validationFunction: undefined,
  registerValidationFunction: (validationFunc) => {},
  setErrors: (currentErrors) => {},
  errors: {}
};

const ValidationContext = React.createContext<ValidationContextState>(validationContextDefaults);

/* Uses a custom hook to implement the provider */
const ValidationContextProvider = (props) => {
  // Our three functions we want to store in our provider
  const [validationFunction, setValidationFunction] = useState(undefined);
  const [touchedFunction, setTouchedFunction] = useState(undefined);
  const [errors, setErrors] = useState(undefined);

  const registerValidationFunction = (currentValidationFunc) => {
    setValidationFunction(() => currentValidationFunc);
  };

  const registerTouchedFunction = (currentTouchedFunc) => {
    setTouchedFunction(() => currentTouchedFunc);
  };

  const validationContextValues: ValidationContextState = {
    validationFunction,
    touchedFunction,
    registerValidationFunction,
    registerTouchedFunction,
    setErrors,
    errors
  };

  return <ValidationContext.Provider value={validationContextValues}>{props.children}</ValidationContext.Provider>;
};

const useValidationContext = (): ValidationContextState => {
  const validationContext = useContext(ValidationContext);

  if (validationContext === undefined) {
    throw new Error('useValidationContext must be used within a ValidationContextProvider');
  }

  return validationContext;
};

// Only expose the way we want to provider the context value and the way we want it to be consumed
export {ValidationContextProvider, useValidationContext};
