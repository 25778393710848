import type {HospitalProduct, ExtrasProduct, PreviousFundDetails, Nullable, CoverStartDate, FinancialDetails, Person, Scale, Address} from '@nib/types-session-api';
import {PriceResult} from './pricingSlice';

export interface ProductSelectionPricingParams {
  hospitalProduct: HospitalProduct['id'];
  excess: number;
  extrasProduct: ExtrasProduct['id'];
}

export type IncomeTierNumber = Nullable<number>;
export interface GovernmentDetailsPricingParams {
  previousCover: PreviousFundDetails['previouslyHadHealthInsurance'];
  partnerPreviousCover: PreviousFundDetails['previouslyHadHealthInsurance'];
  certifiedAgeOfEntry?: string;
  rebateTier: IncomeTierNumber;
  applyRebate: boolean;
}

export type Rate = Nullable<string>;
export interface FinancialDetailsPricingParams {
  effectiveDate: CoverStartDate['date'];
  rate: Rate;
  paymentFrequency: FinancialDetails['paymentFrequency'];
  firstPaymentDate?: FinancialDetails['paymentStartDate'];
}

export interface PersonalDetailsPricingParams {
  dob?: Person['dateOfBirth'];
  partnerDob?: Person['dateOfBirth'];
  scale: Scale;
  state?: Address['state'];
  policyHolderCertifiedAgeOfEntry?: number;
  partnerCertifiedAgeOfEntry?: number;
}

export interface EffectiveDateOverride {
  effectiveDate?: Nullable<string>;
}

export interface CorporatePricingParams {
  corporateRateCode?: Rate;
}

export type PricingParams = CorporatePricingParams &
  ProductSelectionPricingParams &
  GovernmentDetailsPricingParams &
  FinancialDetailsPricingParams &
  PersonalDetailsPricingParams &
  EffectiveDateOverride;

// Type alias
export type PricingFactors = PricingParams;

export type PriceResultWithParams = {
  priceResult: PriceResult;
  params: PricingParams;
};

export enum ProductTypes {
  hospital = 'hospital',
  extras = 'extras'
}

export interface ProductPrice {
  price: number;
  futurePrice?: number;
}

export interface PriceError {
  productId?: number;
  message: string;
  errors?: any[];
  status: number;
}

export interface BatchPriceResults {
  data: PriceResult[];
  errors: PriceError[];
}
