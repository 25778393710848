import {IncomeTierNumber, Selected} from '@nib/phi-constants';
import {isValid, parseISO} from 'date-fns';
import {defaultPreviousCover, getCertifiedAgeOfEntry, getCoverStartDateOrDefault, getDateObject} from '../../services/loadingCalc';
import {isBoolean, stringDateIsoDateString, ensureDateNotInPast, isStringDateBefore, isCorporateBrand} from '../../services/utils';
import {ARHIJoinState} from '../rootReducer';
import {getState} from '../session/selectors/contactDetails';
import {getArhiRate, getCoverStartDate, getFirstPaymentDate} from '../session/selectors/financialDetails';
import {getApplyGovernmentRebate, getIncomeTier, getPartnerPreviouslyHadHealthInsurance, getPolicyHolderPreviouslyHadHealthInsurance} from '../session/selectors/governmentDetails';
import {getPaymentFrequency} from '../session/selectors/paymentDetails';
import {getPartnerDob, getPolicyHolderDob, getScale, getHasPartner} from '../session/selectors/personalDetails';
import {getPolicyHolderContinuousCover, getPartnerContinuousCover} from '../session/selectors/governmentDetails';
import {getExcess, getExtrasId, getHospitalId} from '../session/selectors/productSelection';
import {defaultPricingParams} from './constants';
import {
  FinancialDetailsPricingParams,
  GovernmentDetailsPricingParams,
  PersonalDetailsPricingParams,
  PricingParams,
  ProductSelectionPricingParams,
  PricingFactors,
  CorporatePricingParams
} from './types';

const mapProductSelectionPricingParams = (state: ARHIJoinState): ProductSelectionPricingParams => ({
  hospitalProduct: getHospitalId(state),
  extrasProduct: getExtrasId(state),
  excess: getExcess(state) ?? defaultPricingParams.excess
});

const mapGovernmentDetailsPricingParams = (state: ARHIJoinState): GovernmentDetailsPricingParams => {
  const previousCover = getPolicyHolderPreviouslyHadHealthInsurance(state);
  const partnerPreviousCover = getPartnerPreviouslyHadHealthInsurance(state);
  const incomeTier = getIncomeTier(state);
  const applyRebate = getApplyGovernmentRebate(state);

  return {
    previousCover: isBoolean(previousCover) ? previousCover : defaultPricingParams.previousCover,
    partnerPreviousCover: isBoolean(partnerPreviousCover) ? partnerPreviousCover : defaultPricingParams.partnerPreviousCover,
    rebateTier: IncomeTierNumber[incomeTier || ''],
    applyRebate
  };
};

const mapFinancialDetailsPricingParams = (state: ARHIJoinState, ppcDate?: string): FinancialDetailsPricingParams => {
  const coverStartDate = getCoverStartDate(state);
  const firstPayment = getFirstPaymentDate(state);
  const dateString = stringDateIsoDateString(coverStartDate || defaultPricingParams.effectiveDate);

  let effectiveDate = ensureDateNotInPast(dateString);
  if (ppcDate && isValid(parseISO(ppcDate)) && ppcDate > (effectiveDate || '')) {
    effectiveDate = ppcDate;
  }

  //don't add firstPaymentDate into the api call if it's null or before startDate
  let firstPaymentDate;
  const isStartDateBeforePayment = isStringDateBefore(effectiveDate, firstPayment);
  if (effectiveDate === firstPayment || isStartDateBeforePayment) {
    firstPaymentDate = {
      firstPaymentDate: ensureDateNotInPast(stringDateIsoDateString(firstPayment || defaultPricingParams.firstPaymentDate))
    };
  }

  return {
    effectiveDate,
    rate: getArhiRate(state),
    paymentFrequency: getPaymentFrequency(state) || defaultPricingParams.paymentFrequency,
    ...firstPaymentDate
  };
};

const mapPersonalDetailsPricingParams = (state: ARHIJoinState): PersonalDetailsPricingParams => {
  const policyHolderDob = getPolicyHolderDob(state) || defaultPricingParams.dob;
  const hasPartner = getHasPartner(state) === Selected.yes;
  const partnerDob = getPartnerDob(state) || defaultPricingParams.dob;
  const continuousCoverExperimentActive = getPolicyHolderContinuousCover(state) !== undefined && getPolicyHolderContinuousCover(state) !== null;

  const parameters: any = {
    dob: stringDateIsoDateString(policyHolderDob),
    partnerDob: getHasPartner(state) === Selected.yes ? stringDateIsoDateString(partnerDob) : undefined,
    state: getState(state) || defaultPricingParams.state,
    scale: getScale(state) || defaultPricingParams.scale
  };

  // CAE age values are computed using continuous cover information only available during the experiment
  if (continuousCoverExperimentActive) {
    parameters.policyHolderCertifiedAgeOfEntry = getCertifiedAgeOfEntry(
      getDateObject(policyHolderDob),
      getCoverStartDateOrDefault(getCoverStartDate(state)),
      defaultPreviousCover(getPolicyHolderPreviouslyHadHealthInsurance(state)),
      getPolicyHolderContinuousCover(state)
    );

    if (hasPartner) {
      parameters.partnerCertifiedAgeOfEntry = getCertifiedAgeOfEntry(
        getDateObject(partnerDob),
        getCoverStartDateOrDefault(getCoverStartDate(state)),
        defaultPreviousCover(getPartnerPreviouslyHadHealthInsurance(state)),
        getPartnerContinuousCover(state)
      );
    }
  }

  return parameters;
};

const mapCorporateDetailsPricingParams = (state: ARHIJoinState): CorporatePricingParams => ({
  corporateRateCode: state.session.session?.company?.rateCode || null
});

export const mapSessionToPriceRequest = (state: ARHIJoinState, ppcDate?: string): PricingParams => {
  const productSelection = mapProductSelectionPricingParams(state);
  const governmentDetails = mapGovernmentDetailsPricingParams(state);
  const financialDetails = mapFinancialDetailsPricingParams(state, ppcDate);
  const personalDetails = mapPersonalDetailsPricingParams(state);
  const corporateDetails = mapCorporateDetailsPricingParams(state);

  return {
    ...productSelection,
    ...governmentDetails,
    ...financialDetails,
    ...personalDetails,
    ...corporateDetails
  };
};

export const mapSessionToPricingFactor = (state: ARHIJoinState): PricingFactors => {
  return mapSessionToPriceRequest(state);
};

/* We need to override the rate param with the corporate rate code if it exists
 *  but we want to return a new object here to avoid overriding the parameters that come straight out of the
 *  users session
 *  This is mainly called right before the api call
 */
export const mapCorporateRates = (parameters: PricingParams): PricingParams => {
  // check for corporate rate code
  const isCorporate = Boolean(parameters.corporateRateCode);
  // strip out corporateRateCode param and rely on standard rate param set later
  const {corporateRateCode, ...rest} = parameters;

  const params: PricingParams = {
    ...rest
  };

  // if we're in the legacy corp funnel, override the rate. New corps set the proper rate as standard
  if (isCorporate && corporateRateCode && !isCorporateBrand()) {
    params.rate = corporateRateCode;
  }
  return params;
};
