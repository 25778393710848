import React, {useEffect} from 'react';
import {Provider, useDispatch} from 'react-redux';
import {enableES5} from 'immer';
import store from './src/redux/store';
import {fetchPreviousFunds} from './src/redux/previousFunds/thunks';
import {ValidationContextProvider} from './src/components/ValidationContext';
import {fetchFeatureToggles} from './src/redux/featureToggles/thunks';
import {initSession} from './src/redux/session/thunks';
import {hasCookie} from './src/services/cookies';
import {parse} from 'qs';

enableES5();

export const applySentryContext = () => {
  if (typeof globalThis !== 'undefined' && globalThis?.__SENTRY__) {
    globalThis.Sentry = globalThis.__SENTRY__;
    console.log(`Sentry assigned`);
  }
};

const ApplicationInit: React.FC<any> = props => {
  const dispatch = useDispatch();
  useEffect(() => {
    applySentryContext();
    const initialFetch = async () => {
      dispatch(fetchPreviousFunds());
      dispatch(fetchFeatureToggles());
      if (hasCookie()) {
        // we need the user's session before we can do anything campaign related
        // This is not actually 'init', but a 'fetch' instead. It uses the same endpoint as init, but passes session id from a cookie to fetch session and initialise redux store.
        let params = {};
        params = parse(location.search.slice(1));
        dispatch(initSession(params));
      }
    };

    initialFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // run once

  return props.children;
};

const WrapWithProvider = ({element}) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  return (
    <Provider store={store}>
      <ValidationContextProvider>
        <ApplicationInit>{element}</ApplicationInit>
      </ValidationContextProvider>
    </Provider>
  );
};

export default WrapWithProvider;
