import type {Session} from '@nib/types-session-api';

import {PayloadAction} from '@reduxjs/toolkit';
import {FailedRequestPayload} from '../../types';
import {SessionState} from '../sessionSlice';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const savePaymentDetailsFormPendingReducer = (draftState: SessionState) => {
  draftState.updatingSession = true;
  draftState.sessionError = null;
};

export const savePaymentDetailsFormRejectedReducer = (draftState: SessionState, action: PayloadAction<FailedRequestPayload>) => {
  const {error} = action.payload;
  draftState.sessionError = error;
  draftState.updatingSession = false;
};

export const savePaymentDetailsFormSuccessReducer = (draftState: SessionState, action: PayloadAction<Session>) => {
  const {financialDetails, offerDetails, joinProgress} = action.payload;

  draftState.updatingSession = false;
  if (draftState.session) {
    draftState.session.financialDetails.coverStartDate.date = financialDetails.coverStartDate.date;
    draftState.session.financialDetails.paymentFrequency = financialDetails.paymentFrequency;
    draftState.session.financialDetails.paymentStartDate = financialDetails.paymentStartDate;
    draftState.session.financialDetails.paymentMethod = financialDetails.paymentMethod;

    if (draftState.session.offerDetails && offerDetails) {
      draftState.session.offerDetails.campaignParameters = offerDetails.campaignParameters;
    }

    if (draftState.session.joinProgress && joinProgress) {
      draftState.session.joinProgress.hasAgreedToNibFundRules = joinProgress.hasAgreedToNibFundRules;
    }
  }
};
