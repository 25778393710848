import {captureMessage, setTag, setUser} from '@sentry/gatsby';
import type {Session, OfferDetails, ExtrasProduct, HospitalProduct, State} from '@nib/types-session-api';
import {FamilyDetailsValues} from '../../components/Forms/FamilyDetails/types';
import {PersonalDetailsValues} from '../../components/Forms/PersonalDetails/types';
import {createQuoteCookie, getCookieSessionId, getSessionCookie, QuoteCookie, removeCorporateFromQuoteCookie, removeSessionCookie} from '../../services/cookies';
import {AttributionDetails, AxiosError, AxiosResponse, isAxiosError} from '../types';
import * as api from './api';
import * as sessionMappers from './mappers';
import {
  SessionQueryParams,
  initSessionPending,
  initSessionSuccess,
  initSessionRejected,
  fetchSessionPending,
  fetchSessionRejected,
  fetchSessionSuccess,
  saveRibbonDetailsPending,
  saveRibbonDetailsRejected,
  saveRibbonDetailsSuccess,
  saveHospitalDetailsPending,
  saveHospitalDetailsRejected,
  saveHospitalDetailsSuccess,
  saveExtrasDetailsPending,
  saveExtrasDetailsRejected,
  saveExtrasDetailsSuccess,
  savePersonalDetailsFormPending,
  savePersonalDetailsFormRejected,
  savePersonalDetailsFormSuccess,
  saveFamilyDetailsFormPending,
  saveFamilyDetailsFormRejected,
  saveFamilyDetailsFormSuccess,
  saveRebateDetailsFormPending,
  saveRebateDetailsFormRejected,
  saveRebateDetailsFormSuccess,
  savePaymentDetailsFormPending,
  savePaymentDetailsFormRejected,
  savePaymentDetailsFormSuccess,
  saveOfferDetailsPending,
  saveOfferDetailsRejected,
  saveOfferDetailsSuccess,
  saveFunnelProgressPending,
  saveFunnelProgressRejected,
  saveFunnelProgressSuccess,
  saveWelcomeFormPending,
  saveWelcomeFormRejected,
  saveWelcomeFormSuccess,
  saveSendQuoteFormRejected,
  saveSendQuoteFormPending,
  saveSendQuoteFormSuccess,
  saveAttributionDetailsPending,
  saveAttributionDetailsRejected,
  saveAttributionDetailsSuccess,
  saveAnnualIncomeFormRejected,
  saveAnnualIncomeFormPending,
  updateAnnualIncomeFormSuccess,
  savePricingFactorsModalPending,
  savePricingFactorsModalRejected,
  savePricingFactorsModalSuccess
} from './sessionSlice';
import {getPreviousFundsMap} from '../previousFunds/selectors';
import {GovernmentRebateValues} from '../../components/Forms/AustralianGovernmentRebate/types';
import {PaymentDetailsValues} from '../../components/Forms/PaymentDetails/types';
import {ARHIPage, RibbonDetailsValues} from '../../types/pages';
import {WelcomeDetailsValues} from '../../components/Forms/Welcome/types';
import {getSessionState} from './selectors';
import {ARHIJoinState} from '../rootReducer';
import {get} from 'lodash';
import {fetchAndSaveOffer} from '../offer/thunks';
import {SendQuoteFormValues} from '../../components/Forms/SendQuote/types';
import {navigate} from 'gatsby';
import {setCorrelationIdHeader} from '../../services/axiosConfig';
import {getBrand, getSessionFailurePath} from '../../services/utils';
import {AnnualIncomeFormValues} from '../../components/Modals/AnnualIncomeModal/type';
import {DEPENDANT_MAX_AGE} from '../../components/Forms/FamilyDetails/constants';
import {ParsedQs} from 'qs';
import {isDateStringGreaterThan, isDateStringInFuture} from '../pricing/utils';
import {getMockCurrentDate} from '../../utils';
import {PricingFactorsModalValues} from '../../components/Forms/PricingFactors/types';

export const validSourceCode = (sourceCode: string | string[] | ParsedQs | ParsedQs[] | undefined): boolean => {
  if (typeof sourceCode !== 'string') return false;

  return (sourceCode && (sourceCode.startsWith('A') || sourceCode.startsWith('W')) && sourceCode.length == 6) as boolean;
};

const filterInvalidParams = (params: SessionQueryParams) => {
  //TODO: add more validation for all the params
  if (!validSourceCode(params.sourceCode)) {
    params.sourceCode = undefined;
    params.campaignChannel = undefined;
  }
};

const saveCorrelationId = (sessionId: string) => {
  // 'user.id' is a searchable property in Sentry making it possible bind Sentry errors to possible Sumologic logs.
  setUser({id: sessionId});
  setTag('correlationId', sessionId);
  setTag('brand', getBrand());
  setCorrelationIdHeader(sessionId);
};

export const saveAttributionDetails = (attributionData: AttributionDetails) => async (dispatch, getState) => {
  const arhiJoinState: ARHIJoinState = getState();
  const reduxSession = getSessionState(arhiJoinState);

  try {
    const sessionId = getCookieSessionId();
    if (sessionId && reduxSession) {
      dispatch(saveAttributionDetailsPending());
      const session = sessionMappers.mapAttributionDetailsToSession(attributionData, reduxSession);
      const sessionResponse: AxiosResponse<any> | AxiosResponse<Session> = await api.put({sessionId, session});
      if (isAxiosError(sessionResponse)) {
        captureMessage('Error updating attribution data');
        dispatch(saveAttributionDetailsRejected({error: sessionResponse.data}));
      } else {
        dispatch(saveAttributionDetailsSuccess(sessionResponse.data));
      }
      return sessionResponse;
    }
  } catch (exception) {
    if (exception instanceof Error) {
      dispatch(saveAttributionDetailsRejected({error: exception.message}));
    } else {
      captureMessage('An unknown exception occurred while handling saving attribution details');
    }
    return exception;
  }
};

export const initSession = (params: SessionQueryParams, returnOnError?: boolean) => async (dispatch) => {
  try {
    if (params) {
      dispatch(initSessionPending(JSON.stringify(params)));
      filterInvalidParams(params);

      const sessionId = getCookieSessionId();
      const cookie = getSessionCookie() as QuoteCookie;

      if (sessionId) {
        saveCorrelationId(sessionId);
      }

      // Remove corporate info from cookie for attribution joins
      if (sessionId && params.sourceCode && cookie.CompanyCode && cookie.CorporateRateCode) {
        removeCorporateFromQuoteCookie();
      }

      const initialCompanyValues = sessionMappers.mapSessionInitialValues(params, cookie);
      const sessionResponse = await api.init(initialCompanyValues, {...params, id: sessionId});

      if (sessionResponse && !isAxiosError(sessionResponse)) {
        const mockedCurrentDate = getMockCurrentDate();
        if (mockedCurrentDate && isDateStringInFuture(mockedCurrentDate)) {
          if (isDateStringGreaterThan(mockedCurrentDate, sessionResponse.data.financialDetails.coverStartDate.date)) {
            sessionResponse.data.financialDetails.coverStartDate.date = mockedCurrentDate;
          }
        }

        dispatch(initSessionSuccess(sessionResponse.data));

        if (params.campaignChannel && params.sourceCode) {
          const attributionParams: AttributionDetails = {
            campaignChannel: params.campaignChannel,
            sourceCode: params.sourceCode
          };
          dispatch(saveAttributionDetails(attributionParams));
        }
        createQuoteCookie(sessionResponse.data);
        saveCorrelationId(sessionResponse.data.id || '');
      } else {
        dispatch(initSessionRejected({error: sessionResponse.data}));
      }
      return sessionResponse;
    } else {
      navigate(getSessionFailurePath());
    }
  } catch (exception) {
    dispatch(initSessionRejected({error: exception}));
    // if cookie can't be decoded, eg is corrupted it would break arhi sales too so lets remove it.
    removeSessionCookie();
    //eg, let's just display an error on welcome rather than redirecting to itself.
    captureMessage(`Error initSession - ${exception}`, 'error');
    if (returnOnError) {
      return exception;
    }

    navigate(getSessionFailurePath());
  }
  return;
};

export const fetchSession = () => async (dispatch) => {
  try {
    const sessionId = getCookieSessionId();
    if (sessionId) {
      dispatch(fetchSessionPending(sessionId));
      const sessionResponse = await api.fetch(sessionId);
      if (sessionResponse && !isAxiosError(sessionResponse)) {
        dispatch(fetchSessionSuccess(sessionResponse.data));
      } else {
        dispatch(fetchSessionRejected({error: sessionResponse.data}));
      }
      return sessionResponse;
    } else {
      const error = 'Error fetching session - invalid store or session cookie.';
      console.error(error);
      captureMessage(error);
      dispatch(fetchSessionRejected({error: error}));
    }
  } catch (exception) {
    const errorMessage = 'Error fetching session';
    dispatch(fetchSessionRejected({error: exception}));
    captureMessage(`${errorMessage} - ${exception}`, 'error');
  }
  return;
};

export const savePersonalDetailsForm = (values: PersonalDetailsValues, isPageComplete?: boolean) => async (dispatch, getState) => {
  const arhiJoinState: ARHIJoinState = getState();
  const reduxSession = getSessionState(arhiJoinState);

  const fundsMap = getPreviousFundsMap(getState());

  try {
    const sessionId = getCookieSessionId();
    if (sessionId && reduxSession) {
      dispatch(savePersonalDetailsFormPending(values));
      const session = sessionMappers.mapPersonalDetailsToSession(values, reduxSession, fundsMap, isPageComplete);
      const sessionResponse: AxiosResponse<any> | AxiosResponse<Session> = await api.put({sessionId, session});
      if (isAxiosError(sessionResponse)) {
        dispatch(savePersonalDetailsFormRejected({error: sessionResponse.data}));
      } else {
        dispatch(savePersonalDetailsFormSuccess(sessionResponse.data));
        if (isPageComplete) {
          dispatch(saveFunnelProgressSuccess(sessionResponse.data));
        }
      }
      return sessionResponse;
    }
  } catch (exception) {
    dispatch(savePersonalDetailsFormRejected({error: exception.message}));
    return exception;
  }
};

export const saveRebateDetailsForm = (values: GovernmentRebateValues, isPageComplete?: boolean) => async (dispatch, getState) => {
  const arhiJoinState: ARHIJoinState = getState();
  const reduxSession = getSessionState(arhiJoinState);

  try {
    const sessionId = getCookieSessionId();
    if (sessionId && reduxSession) {
      dispatch(saveRebateDetailsFormPending(values));
      const session = sessionMappers.mapRebateDetailsToSession(values, reduxSession, isPageComplete);
      const sessionResponse: AxiosResponse<any> | AxiosResponse<Session> = await api.put({sessionId, session});
      if (isAxiosError(sessionResponse)) {
        dispatch(saveRebateDetailsFormRejected({error: sessionResponse.data}));
      } else {
        dispatch(saveRebateDetailsFormSuccess(sessionResponse.data));
        if (isPageComplete) {
          dispatch(saveFunnelProgressSuccess(sessionResponse.data));
        }
      }
      return sessionResponse;
    }
  } catch (exception) {
    dispatch(saveRebateDetailsFormRejected({error: exception.message}));
    return exception;
  }
};

export const saveFamilyDetailsForm = (values: FamilyDetailsValues, isPageComplete?: boolean) => async (dispatch, getState) => {
  const arhiJoinState: ARHIJoinState = getState();
  const reduxSession = getSessionState(arhiJoinState);

  const fundsMap = getPreviousFundsMap(getState());

  try {
    const sessionId = getCookieSessionId();
    if (sessionId && reduxSession) {
      dispatch(saveFamilyDetailsFormPending(values));
      const session = sessionMappers.mapFamilyDetailsToSession(values, reduxSession, fundsMap, isPageComplete);
      const sessionResponse: AxiosResponse<any> | AxiosResponse<Session> = await api.put({sessionId, session});
      if (isAxiosError(sessionResponse)) {
        dispatch(saveFamilyDetailsFormRejected({error: sessionResponse.data}));
      } else {
        dispatch(saveFamilyDetailsFormSuccess(sessionResponse.data));
        if (isPageComplete) {
          dispatch(saveFunnelProgressSuccess(sessionResponse.data));
        }
      }
      return sessionResponse;
    }
  } catch (exception) {
    dispatch(saveFamilyDetailsFormRejected({error: exception.message}));
    return exception;
  }
};

export const savePaymentDetailsForm = (values: PaymentDetailsValues, isPageComplete?: boolean) => async (dispatch, getState) => {
  const arhiJoinState: ARHIJoinState = getState();
  const reduxSession = getSessionState(arhiJoinState);

  try {
    const sessionId = getCookieSessionId();
    if (sessionId && reduxSession) {
      dispatch(savePaymentDetailsFormPending());
      const session = sessionMappers.mapPaymentDetailsToSession(values, reduxSession, DEPENDANT_MAX_AGE, isPageComplete);
      const sessionResponse: AxiosResponse<any> | AxiosResponse<Session> = await api.put({sessionId, session});
      if (isAxiosError(sessionResponse)) {
        dispatch(savePaymentDetailsFormRejected({error: sessionResponse.data}));
      } else {
        dispatch(savePaymentDetailsFormSuccess(sessionResponse.data));
        if (isPageComplete) {
          dispatch(saveFunnelProgressSuccess(sessionResponse.data));
        }
      }
      return sessionResponse;
    }
  } catch (exception) {
    dispatch(savePaymentDetailsFormRejected({error: exception.message}));
    return exception;
  }
};

export const saveOfferDetails = (offerData: OfferDetails) => async (dispatch, getState) => {
  const arhiJoinState: ARHIJoinState = getState();
  const reduxSession = getSessionState(arhiJoinState);
  try {
    const sessionId = getCookieSessionId();
    if (sessionId && reduxSession) {
      dispatch(saveOfferDetailsPending(offerData));
      const session = sessionMappers.mapOfferDetailsToSession(offerData, reduxSession);
      const sessionResponse: AxiosResponse<any> | AxiosResponse<Session> = await api.put({sessionId, session});
      if (isAxiosError(sessionResponse)) {
        dispatch(saveOfferDetailsRejected({error: sessionResponse.data}));
      } else {
        dispatch(saveOfferDetailsSuccess(sessionResponse.data));
      }
      return sessionResponse;
    }
  } catch (exception) {
    dispatch(saveOfferDetailsRejected({error: exception.message}));
    return exception;
  }
};

export const saveFunnelProgress = (activePage?: ARHIPage, lastCompletedPage?: ARHIPage) => async (dispatch, getState) => {
  const arhiJoinState: ARHIJoinState = getState();
  const reduxSession = getSessionState(arhiJoinState);

  try {
    const sessionId = getCookieSessionId();
    if (sessionId && reduxSession) {
      dispatch(saveFunnelProgressPending({activePage, lastCompletedPage}));
      const session = sessionMappers.mapFunnelProgressToSession(reduxSession, activePage, lastCompletedPage);
      const sessionResponse: AxiosResponse<any> | AxiosResponse<Session> = await api.put({sessionId, session});
      if (isAxiosError(sessionResponse)) {
        dispatch(saveFunnelProgressRejected({error: sessionResponse.data}));
      } else {
        dispatch(saveFunnelProgressSuccess(sessionResponse.data));
      }
      return sessionResponse;
    }
  } catch (exception) {
    dispatch(saveFunnelProgressRejected({error: exception.message}));
    return exception;
  }
};

export const setActiveFunnelPage = (activePage: ARHIPage) => async (dispatch, getState) => {
  const arhiJoinState: ARHIJoinState = getState();
  const reduxSession = getSessionState(arhiJoinState);

  try {
    const sessionId = getCookieSessionId();
    if (sessionId && reduxSession) {
      dispatch(saveFunnelProgressPending({activePage}));
      const session = sessionMappers.mapActivePageToSession(activePage, reduxSession);
      const sessionResponse: AxiosResponse<any> | AxiosResponse<Session> = await api.put({sessionId, session});
      if (isAxiosError(sessionResponse)) {
        dispatch(saveFunnelProgressRejected({error: sessionResponse.data}));
      } else {
        dispatch(saveFunnelProgressSuccess(sessionResponse.data));
      }
      return sessionResponse;
    }
  } catch (exception) {
    dispatch(saveFunnelProgressRejected({error: exception.message}));
    return exception;
  }
};

export const setLastCompletedFunnelPage = (lastCompletedPage: ARHIPage) => async (dispatch, getState) => {
  const arhiJoinState: ARHIJoinState = getState();
  const reduxSession = getSessionState(arhiJoinState);

  try {
    const sessionId = getCookieSessionId();
    if (sessionId && reduxSession) {
      dispatch(saveFunnelProgressPending({lastCompletedPage}));
      const session = sessionMappers.mapLastCompletedPageToSession(lastCompletedPage, reduxSession);
      const sessionResponse: AxiosResponse<any> | AxiosResponse<Session> = await api.put({sessionId, session});
      if (isAxiosError(sessionResponse)) {
        dispatch(saveFunnelProgressRejected({error: sessionResponse.data}));
      } else {
        dispatch(saveFunnelProgressSuccess(sessionResponse.data));
      }
      return sessionResponse;
    }
  } catch (exception) {
    dispatch(saveFunnelProgressRejected({error: exception.message}));
    return exception;
  }
};

export const saveWelcomeDetailsForm = (values: WelcomeDetailsValues, isPageComplete?: boolean, state?: State, excess?: number | undefined) => async (dispatch, getState) => {
  const arhiJoinState: ARHIJoinState = getState();
  const reduxSession = getSessionState(arhiJoinState);

  try {
    const sessionId = getCookieSessionId();
    if (sessionId && reduxSession) {
      dispatch(saveWelcomeFormPending(values));
      const session = sessionMappers.mapWelcomeDetailsToSession(values, reduxSession, isPageComplete, state, excess);
      const sessionResponse: AxiosResponse<any> | AxiosResponse<Session> = await api.put({sessionId, session});
      if (isAxiosError(sessionResponse)) {
        dispatch(saveWelcomeFormRejected({error: sessionResponse.data}));
      } else {
        dispatch(saveWelcomeFormSuccess(sessionResponse.data));
        if (isPageComplete) {
          dispatch(saveFunnelProgressSuccess(sessionResponse.data));
        }
      }
      return sessionResponse;
    }
  } catch (exception) {
    dispatch(saveWelcomeFormRejected({error: exception.message}));
    return exception;
  }
  return;
};

export const saveRibbonDetails = (quoteData: Partial<RibbonDetailsValues>) => async (dispatch, getState) => {
  const arhiJoinState: ARHIJoinState = getState();
  const reduxSession = getSessionState(arhiJoinState);
  const isScale = get(quoteData, 'scale');
  try {
    const sessionId = getCookieSessionId();
    if (sessionId && reduxSession) {
      dispatch(saveRibbonDetailsPending(quoteData));
      const session = sessionMappers.mapRibbonDetailsToSession(quoteData, reduxSession);

      const sessionResponse: AxiosResponse<any> | AxiosResponse<Session> = await api.put({sessionId, session});
      if (isAxiosError(sessionResponse)) {
        dispatch(saveRibbonDetailsRejected({error: sessionResponse.data}));
      } else {
        dispatch(saveRibbonDetailsSuccess(sessionResponse.data));
        if (isScale) {
          await dispatch(fetchAndSaveOffer());
        }
      }
      return sessionResponse;
    }
  } catch (exception) {
    dispatch(saveRibbonDetailsRejected({error: exception.message}));
    return exception;
  }
};

export const saveHospitalDetails = (quoteData: HospitalProduct, isPageComplete?: boolean) => async (dispatch, getState) => {
  const arhiJoinState: ARHIJoinState = getState();
  const reduxSession = getSessionState(arhiJoinState);

  try {
    const sessionId = getCookieSessionId();
    if (sessionId && reduxSession) {
      dispatch(saveHospitalDetailsPending(quoteData));
      const session = sessionMappers.mapHospitalDetailsToSession(quoteData, reduxSession, isPageComplete);
      const sessionResponse: AxiosResponse<any> | AxiosResponse<Session> = await api.put({sessionId, session});
      if (isAxiosError(sessionResponse)) {
        dispatch(saveHospitalDetailsRejected({error: sessionResponse.data}));
      } else {
        dispatch(saveHospitalDetailsSuccess({session: sessionResponse.data, isPageComplete}));
      }
      return sessionResponse;
    }
  } catch (exception) {
    dispatch(saveHospitalDetailsRejected({error: exception.message}));
    return exception;
  }
};

export const saveExtrasDetails = (quoteData: ExtrasProduct, isPageComplete?: boolean) => async (dispatch, getState) => {
  const arhiJoinState: ARHIJoinState = getState();
  const reduxSession = getSessionState(arhiJoinState);

  try {
    const sessionId = getCookieSessionId();
    if (sessionId && reduxSession) {
      dispatch(saveExtrasDetailsPending(quoteData));
      const session = sessionMappers.mapExtrasDetailsToSession(quoteData, reduxSession, isPageComplete);
      const sessionResponse: AxiosResponse<any> | AxiosResponse<Session> = await api.put({sessionId, session});
      if (isAxiosError(sessionResponse)) {
        dispatch(saveExtrasDetailsRejected({error: sessionResponse.data}));
      } else {
        dispatch(saveExtrasDetailsSuccess({session: sessionResponse.data, isPageComplete}));
      }
      return sessionResponse;
    }
  } catch (exception) {
    dispatch(saveExtrasDetailsRejected({error: exception.message}));
    return exception;
  }
};

export const saveSendQuoteFormDetails = (quoteData: SendQuoteFormValues) => async (dispatch, getState) => {
  const arhiJoinState: ARHIJoinState = getState();
  const reduxSession = getSessionState(arhiJoinState);
  try {
    const sessionId = getCookieSessionId();
    if (sessionId && reduxSession) {
      dispatch(saveSendQuoteFormPending());
      const session = sessionMappers.mapSendQuoteDetailsToSession(quoteData, reduxSession);
      const sessionResponse: AxiosResponse<any> | AxiosResponse<Session> = await api.put({sessionId, session});
      if (isAxiosError(sessionResponse)) {
        dispatch(saveSendQuoteFormRejected({error: sessionResponse.data}));
      } else {
        dispatch(saveSendQuoteFormSuccess(sessionResponse.data));
      }
      return sessionResponse;
    }
  } catch (exception) {
    dispatch(saveSendQuoteFormRejected({error: exception.message}));
    return exception;
  }
};

export const saveAnnualIncomeFormDetails = (quoteData: AnnualIncomeFormValues) => async (dispatch, getState) => {
  const arhiJoinState: ARHIJoinState = getState();
  const reduxSession = getSessionState(arhiJoinState);
  const isScale = get(quoteData, 'scale');
  try {
    const sessionId = getCookieSessionId();
    if (sessionId && reduxSession) {
      dispatch(saveAnnualIncomeFormPending(quoteData));
      const session = sessionMappers.mapIncomeDetailsToSession(quoteData, reduxSession);
      const sessionResponse: AxiosResponse<any> | AxiosResponse<Session> = await api.put({sessionId, session});
      if (isAxiosError(sessionResponse)) {
        dispatch(saveAnnualIncomeFormRejected({error: sessionResponse.data}));
      } else {
        dispatch(updateAnnualIncomeFormSuccess(sessionResponse.data));
        if (isScale) {
          await dispatch(fetchAndSaveOffer());
        }
      }
      return sessionResponse;
    }
  } catch (exception) {
    dispatch(saveAnnualIncomeFormRejected({error: exception.message}));
    return exception;
  }
};

export const savePricingFactorsFormDetails = (pricingFactorsModalData: PricingFactorsModalValues) => async (dispatch, getState) => {
  const arhiJoinState: ARHIJoinState = getState();
  const reduxSession = getSessionState(arhiJoinState);
  try {
    const sessionId = getCookieSessionId();
    if (sessionId && reduxSession) {
      dispatch(savePricingFactorsModalPending());
      const session = sessionMappers.mapPricingFactorsModalDetailsToSession(pricingFactorsModalData, reduxSession);
      const sessionResponse: AxiosResponse<any> | AxiosResponse<Session> = await api.put({sessionId, session});
      if (isAxiosError(sessionResponse)) {
        dispatch(savePricingFactorsModalRejected({error: sessionResponse.data}));
      } else {
        dispatch(savePricingFactorsModalSuccess(sessionResponse.data));
      }
      return sessionResponse;
    }
  } catch (exception) {
    dispatch(saveSendQuoteFormRejected({error: exception.message}));
    return exception;
  }
};

type SaveFormThunk<ValuesType> = (values: ValuesType, isPageComplete?: boolean) => Promise<AxiosResponse<Session> | AxiosResponse<AxiosError> | undefined>;
export type SaveRibbonDetailsFormThunk = SaveFormThunk<RibbonDetailsValues>;
export type SavePersonalDetailsFormThunk = SaveFormThunk<PersonalDetailsValues>;
export type SaveSendQuoteFormThunk = SaveFormThunk<SendQuoteFormValues>;
export type SaveRebateDetailsFormThunk = SaveFormThunk<GovernmentRebateValues>;
export type SaveFamilyDetailsFormThunk = SaveFormThunk<FamilyDetailsValues>;
export type SavePaymentDetailsFormThunk = SaveFormThunk<PaymentDetailsValues>;
export type SaveAnnualIncomeFormThunk = SaveFormThunk<AnnualIncomeFormValues>;
export type SavePricingFactorsFormThunk = SaveFormThunk<PricingFactorsModalValues>;

export type SaveWelcomeDetailsFormThunk = (
  values: WelcomeDetailsValues,
  isPageComplete?: boolean,
  state?: State,
  excess?: number | undefined
) => Promise<AxiosResponse<Session> | AxiosResponse<AxiosError> | undefined>;
export type SaveOfferDetailsThunk = (offerData: OfferDetails) => Promise<AxiosResponse<Session> | AxiosResponse<AxiosError> | undefined>;

export type SaveFunnelProgressThunk = (page: ARHIPage) => Promise<AxiosResponse<Session> | AxiosResponse<AxiosError>>;
export type initSessionThunk = (params: SessionQueryParams, returnOnError?: boolean) => Promise<AxiosResponse<Session> | AxiosResponse<AxiosError>>;
export type SubmitSendQuoteFormThunk = (params: SendQuoteFormValues) => Promise<AxiosResponse<Session> | AxiosResponse<AxiosError>>;
export type SubmitAnnualIncomeFormThunk = (params: AnnualIncomeFormValues) => Promise<AxiosResponse<Session> | AxiosResponse<AxiosError>>;
